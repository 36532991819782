import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import find from 'lodash/find';

import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconButton from '@mui/material/IconButton';

import { openSubscribedUsersDialog } from '../../../store/dialogs';
import ROLES from '../../../constants/roles';

export const SubscribedUsersTypeProvider = (props) => {
  const dispatch = useDispatch();
  const { memberships } = useSelector((state) => state.user);

  const handleClick = (triggerDevice) => {
    dispatch(openSubscribedUsersDialog(triggerDevice));
  };

  return (
    <DataTypeProvider
      formatterComponent={({ row }) => {
        const relation = find(memberships, { org_id: row?.org_id });
        const isOrgEditor = relation?.role <= ROLES.EDITOR.value;

        if (!isOrgEditor) return null;

        return (
          <IconButton
            onClick={() => handleClick(row)}
            disabled={!row.trigger_device_id}
            sx={{
              display: 'flex',
              justifyContent: 'left',
              alignItems: 'center',
            }}>
            <FontAwesomeIcon
              icon={['fal', 'users']}
              style={{ height: 25, width: 25 }}
            />
          </IconButton>
        );
      }}
      {...props}
    />
  );
};
