import React from 'react';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { useDispatch, useSelector } from 'react-redux';
import find from 'lodash/find';
import startCase from 'lodash/startCase';

import Link from '@mui/material/Link';

import { openDialog } from '../../../store/dialogs';

export const ReportTypeProvider = (props) => {
  const { data: reports } = useSelector((state) => state.reports);

  const ReportLink = (props) => {
    const { id } = props;
    const dispatch = useDispatch();
    const report = find(reports, { report_id: id });

    const handleClick = () => {
      dispatch(
        openDialog({
          type: 'report',
          id: report.report_id,
          mode: 'view',
        })
      );
    };

    return (
      <Link underline='hover' onClick={handleClick}>
        {startCase(report?.name)}
      </Link>
    );
  };

  return (
    <DataTypeProvider
      formatterComponent={({ value }) => <ReportLink id={value} />}
      {...props}
    />
  );
};
