import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import concat from 'lodash/concat';
import get from 'lodash/get';
import map from 'lodash/map';
import reduce from 'lodash/reduce';

import styled from '@mui/material/styles/styled';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import WidgetLoader from '../../../../components/widgets/Loader';
import ProductionSumItem from './ProductionSumItem';
import { useSelector } from 'react-redux';
import {
  allTimeSum,
  lastMonthSum,
  thisMonthActual,
} from '../../../../helpers/performance-data';

const BottomContent = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: '14px',
  marginBottom: '4px',
  marginLeft: '10px',
}));

const BottomPanel = styled('div')(({ theme }) => ({
  height: 10,
  width: '100%',
  bottom: 0,
  backgroundColor: theme.palette.veregy_colors.orange,
}));

function LoggerProduction(props) {
  const { meters } = props;
  const { loading } = useSelector((state) => state.meters);
  const [productionSums, setProductionSums] = useState({
    today: 0,
    thisMonth: 0,
    lastMonth: 0,
    allTime: 0,
  });

  useEffect(() => {
    const thisMonth = thisMonthActual(meters);
    const sums = {
      today: reduce(
        meters,
        (acc, meter) => acc + get(meter, 'energy_stats.today', 0),
        0
      ),
      thisMonth,
      lastMonth: lastMonthSum(
        concat(
          map(meters, (meter) => meter.energy_stats.production),
          map(meters, (meter) => meter.energy_stats.adjusted_production)
        )
      ),
      allTime:
        allTimeSum(
          concat(
            map(meters, (meter) => meter.energy_stats.production),
            map(meters, (meter) => meter.energy_stats.adjusted_production)
          )
        ) + thisMonth,
    };

    setProductionSums(sums);
  }, [meters]);

  return (
    <Card raised>
      <CardContent sx={{ pt: 2, pl: 2, pb: 1 }}>
        <Grid container spacing={1} pb={0}>
          <ProductionSumItem label='Today' value={productionSums.today} />
          <ProductionSumItem
            label='This Month'
            value={productionSums.thisMonth}
          />
          <ProductionSumItem
            label='Last Month'
            value={productionSums.lastMonth}
          />
          <ProductionSumItem label='All Time' value={productionSums.allTime} />
        </Grid>
      </CardContent>
      <BottomContent>
        <Typography align='left' variant='h5'>
          Production Sums
        </Typography>
        {loading ? <WidgetLoader /> : null}
      </BottomContent>
      <BottomPanel />
    </Card>
  );
}

LoggerProduction.propTypes = {
  meters: PropTypes.array.isRequired,
};

export default LoggerProduction;
