import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import filter from 'lodash/filter';

const filterDevices = (devices, orgId) => {
  return filter(devices, { org_id: orgId });
};

const useOrganizationDevices = (organizationId) => {
  const { data: allAlarms, loading: alarmLoading } = useSelector(
    (state) => state.alarms
  );
  const { data: allSites, loading: siteLoading } = useSelector(
    (state) => state.sites
  );
  const { data: allLoggers, loading: loggerLoading } = useSelector(
    (state) => state.loggers
  );
  const { data: allMeters, loading: meterLoading } = useSelector(
    (state) => state.meters
  );
  const { data: allInverters, loading: inverterLoading } = useSelector(
    (state) => state.inverters
  );
  const { data: allSensors, loading: sensorLoading } = useSelector(
    (state) => state.sensors
  );

  const [alarms, setAlarms] = useState([]);
  const [sites, setSites] = useState([]);
  const [loggers, setLoggers] = useState([]);
  const [meters, setMeters] = useState([]);
  const [inverters, setInverters] = useState([]);
  const [sensors, setSensors] = useState([]);

  useEffect(() => {
    setAlarms(filterDevices(allAlarms, organizationId));
  }, [allAlarms, organizationId]);

  useEffect(() => {
    setSites(filterDevices(allSites, organizationId));
  }, [allSites, organizationId]);

  useEffect(() => {
    setLoggers(filterDevices(allLoggers, organizationId));
  }, [allLoggers, organizationId]);

  useEffect(() => {
    setMeters(filterDevices(allMeters, organizationId));
  }, [allMeters, organizationId]);

  useEffect(() => {
    setInverters(filterDevices(allInverters, organizationId));
  }, [allInverters, organizationId]);

  useEffect(() => {
    setSensors(filterDevices(allSensors, organizationId));
  }, [allSensors, organizationId]);

  return {
    sites,
    loggers,
    meters,
    inverters,
    sensors,
    alarms,
    loading:
      alarmLoading ||
      siteLoading ||
      loggerLoading ||
      meterLoading ||
      inverterLoading ||
      sensorLoading,
  };
};

export default useOrganizationDevices;
