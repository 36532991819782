const ROUTES = {
  UNAUTH: {
    LANDING: '/',
    LOGIN: '/login',
    KIOSK: '/kiosk',
  },
  AUTH: {
    INDEX: '/app',
    PORTFOLIO: '/app/portfolio',
    ORGANIZATION: '/app/organization',
    SITE: '/app/site',
    LOGGER: '/app/logger',
    METER: '/app/meter',
    INVERTER: '/app/inverter',
    SENSOR: '/app/sensor',
    PROFILE: '/app/profile',
    TERMS: '/app/terms',
    PRIVACY: '/app/privacy',
  },
};

export default ROUTES;
