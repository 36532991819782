import React from 'react';
import PropTypes from 'prop-types';

import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function LegendToggle(props) {
  const { show, setShow } = props;

  const handleClick = () => {
    setShow(!show);
  };

  return (
    <Tooltip title={(show ? 'Hide' : 'Show') + ' Legend'}>
      <IconButton onClick={handleClick}>
        <FontAwesomeIcon icon={['fal', 'eye']} size='xs' />
      </IconButton>
    </Tooltip>
  );
}

LegendToggle.propTypes = {
  show: PropTypes.bool,
  setShow: PropTypes.func,
};

export default LegendToggle;
