import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import filter from 'lodash/filter';

const useMemberships = (organizationId) => {
  const allMemberships = useSelector((state) => state.memberships.data);

  const [memberships, setMemberships] = useState([]);

  useEffect(() => {
    setMemberships(
      filter(allMemberships, {
        org_id: organizationId,
      })
    );
  }, [organizationId, allMemberships]);

  return memberships;
};

export default useMemberships;
