import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import find from 'lodash/find';
import isUndefined from 'lodash/isUndefined';

import Grid from '@mui/material/Grid';

import { roundNumber } from '../../../../helpers';
import useMeter from '../../../../store/hooks/useMeter';
import RegisterDataGrid from '../../../../components/RegisterDataGrid';
import ValuesList from '../../../../components/RegisterDataGrid/ValuesList';

export const REGISTER_OBJECTS = {
  analogValue: {
    502: { objectName: 'kW_Demand_System_Now' },
    505: { objectName: 'kW_System_Net' },
    515: { objectName: 'kW_L1' },
    516: { objectName: 'kW_L2' },
    517: { objectName: 'kW_L3' },
    531: { objectName: 'Volts_Line_To_Line_Average' },
    532: { objectName: 'Volts_Line_To_Neutral_Average' },
    533: { objectName: 'Volts_L1_to_L2' },
    534: { objectName: 'Volts_L2_to_L3' },
    535: { objectName: 'Volts_L1_to_L3' },
    536: { objectName: 'Volts_L1_to_Neutral' },
    537: { objectName: 'Volts_L2_to_Neutral' },
    538: { objectName: 'Volts_L3_to_Neutral' },
    539: { objectName: 'Amps_System_Average' },
    540: { objectName: 'Amps_L1' },
    541: { objectName: 'Amps_L2' },
    542: { objectName: 'Amps_L3' },
  },
  binaryValue: {
    501: { objectName: 'EM_Status' },
  },
  multiStateValue: {
    501: {
      objectName: 'EM_NetworkStatus',
      stateText: [
        'Not received',
        'Not sent',
        'No error',
        'Service request denied',
        'Missing required parameter',
        'Other error',
        'Unknown property',
        'Property is not an array',
        'Write access denied',
        'Invalid array index',
        'Invalid data type',
        'Value out of range',
        'Response timed out',
        'Request timed out',
        'Request queue full',
        'Response queue full',
        'Update failed',
        'Unknown object',
        'Unsupported object type',
        'Read access denied',
        'Unknown message type',
        'Transmission buffer overflow',
        'Transmission disable',
        'Address binding list is full',
        'Unknown error',
      ],
    },
  },
};

function LatestData() {
  const { id } = useSelector((state) => state.pages.meter);
  const meter = useMeter(id);

  const [data, setData] = useState([]);
  const [analogValues, setAnalogValues] = useState([]);
  const [binaryValues, setBinaryValues] = useState([]);
  const [multiStateValues, setMultiStateValues] = useState([]);

  useEffect(() => {
    let values = Object.keys(REGISTER_OBJECTS.analogValue).map((key) => {
      const valueObj = REGISTER_OBJECTS.analogValue[key];
      const datapoint = find(data, (item) =>
        item.measure_name.startsWith(valueObj.objectName)
      );
      return {
        measure_name: valueObj.objectName,
        value: datapoint?.value ? roundNumber.format(datapoint?.value) : '-',
      };
    });
    setAnalogValues(values);
  }, [data]);

  useEffect(() => {
    let values = Object.keys(REGISTER_OBJECTS.binaryValue).map((key) => {
      const valueObj = REGISTER_OBJECTS.binaryValue[key];
      const datapoint = find(data, (item) =>
        item.measure_name.startsWith(valueObj.objectName)
      );
      let value = '-';
      if (datapoint?.value === 0) {
        value = 'Inactive';
      }
      if (datapoint?.value === 1) {
        value = 'Active';
      }
      return {
        measure_name: valueObj.objectName,
        value,
      };
    });
    setBinaryValues(values);
  }, [data]);

  useEffect(() => {
    let values = Object.keys(REGISTER_OBJECTS.multiStateValue).map((key) => {
      const valueObj = REGISTER_OBJECTS.multiStateValue[key];
      const datapoint = find(data, (item) =>
        item.measure_name.startsWith(valueObj.objectName)
      );
      let value = '-';
      if (!isUndefined(datapoint?.value)) {
        value =
          REGISTER_OBJECTS.multiStateValue[501].stateText[datapoint.value];
      }
      return {
        measure_name: valueObj.objectName,
        value,
      };
    });
    setMultiStateValues(values);
  }, [data]);

  return (
    <RegisterDataGrid
      registerObjects={REGISTER_OBJECTS}
      orgId={meter.org_id}
      deviceId={meter.meter_id}
      setData={setData}>
      <Grid item xs={12} md={6}>
        <ValuesList title='Analog Values' values={analogValues} />
      </Grid>
      <Grid item xs={12} md={6}>
        <ValuesList title='Binary Values' values={binaryValues} />
        <ValuesList
          title='Multi State Values'
          values={multiStateValues}
          mt={1}
        />
      </Grid>
    </RegisterDataGrid>
  );
}

export default LatestData;
