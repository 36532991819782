import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import useTheme from '@mui/material/styles/useTheme';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

function SelectChartType(props) {
  const { selectedType, setSelectedType, types } = props;
  const theme = useTheme();

  const [type, setType] = useState('');

  useEffect(() => {
    setType(selectedType);
  }, [selectedType]);

  const handleSelect = (e) => {
    e.preventDefault();
    setSelectedType(e.target.value);
  };

  return (
    <Select
      disableUnderline
      id='type-select'
      value={type}
      variant='standard'
      onChange={handleSelect}
      sx={{ minWidth: '4rem' }}
      IconComponent={() => null}
      inputProps={{
        sx: {
          padding: '0 !important',
          color: theme.palette.primary.main,
          ...theme.typography.body2,
        },
      }}
      MenuProps={{ MenuListProps: { disablePadding: true } }}>
      {types.map((type) => {
        return (
          <MenuItem
            key={type}
            value={type}
            sx={{
              color: theme.palette.primary.main,
              ...theme.typography.body2,
            }}>
            {type}
          </MenuItem>
        );
      })}
    </Select>
  );
}

SelectChartType.propTypes = {
  selectedType: PropTypes.string,
  setSelectedType: PropTypes.func,
};

export default SelectChartType;
