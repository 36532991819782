import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import find from 'lodash/find';

import Link from '@mui/material/Link';
import { DataTypeProvider } from '@devexpress/dx-react-grid';

import { navigate } from '../../../store/pages';

export const OrgIdNameLinkTypeProvider = (props) => {
  const dispatch = useDispatch();
  const { data: organizations } = useSelector((state) => state.organizations);
  const { item: user } = useSelector((state) => state.user);
  const defaultOrganization = find(organizations, {
    org_id: user?.default_organization,
  });

  const handleClick = (orgId, page) => {
    dispatch(
      navigate({
        page,
        id: orgId,
      })
    );
  };

  return (
    <DataTypeProvider
      formatterComponent={({ value: orgId }) => {
        const organization = find(organizations, { org_id: orgId });
        if (!organization?.is_portfolio) {
          return (
            <Link
              underline='hover'
              onClick={() => handleClick(orgId, 'organization')}>
              {organization?.name}
            </Link>
          );
        } else if (
          organization?.is_portfolio &&
          organization?.org_id === defaultOrganization?.org_id
        ) {
          return (
            <Link
              underline='hover'
              onClick={() => handleClick(orgId, 'portfolio')}>
              {organization?.name}
            </Link>
          );
        } else {
          return <Link underline='none'>{organization?.name}</Link>;
        }
      }}
      {...props}
    />
  );
};
