import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import dayjs from 'dayjs';
import cloneDeep from 'lodash/cloneDeep';
import find from 'lodash/find';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import keys from 'lodash/keys';
import lastIndexOf from 'lodash/lastIndexOf';
import map from 'lodash/map';
import merge from 'lodash/merge';
import reduce from 'lodash/reduce';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { generateCsvString } from '../../../../helpers/csv-data';
import { displaykW } from '../../../../helpers/display-energy';
import SelectUnit, { UNITS } from '../../selectors/SelectUnits';
import GenerateCSVButton from '../../buttons/GenerateCSVButton';
import SelectSites from '../../selectors/SelectSites';
import PerformanceChart, { TYPES as CHART_TYPES } from '.';
import SelectChartType from '../../selectors/SelectChartType';
import SelectMonthRange from '../../selectors/SelectMonthRange';
import ExternalTooltip from './ExternalTooltip';
import LegendToggle from '../../buttons/LegendToggle';

const EXPECTATION_TYPES = ['modeled', 'weather corrected'];
const H_UNITS = UNITS.slice(3);

const sumAllData = (data, type) => {
  return reduce(
    data,
    (acc, monthData) => {
      return (
        acc +
        reduce(
          monthData,
          (acc, val, key) => {
            if (key.endsWith(type)) {
              return acc + val;
            }
            return acc;
          },
          0
        )
      );
    },
    0
  );
};

const prepareCsvData = (data, sites, unit) => {
  let headers = ['month'];
  let rawData = cloneDeep(data);

  let csvData = map(rawData, (monthData) => {
    let values = reduce(
      Object.entries(monthData),
      (acc, entry) => {
        const id = entry[0];
        const value = entry[1];
        if (id !== 'timestamp') {
          let siteId = id.substring(0, lastIndexOf(id, '-'));
          let performanceType = id.substring(lastIndexOf(id, '-') + 1);
          let site = find(sites, { site_id: siteId });
          let column = `${site.name}-${performanceType}-${unit}`;
          if (!headers.includes(column)) {
            headers.push(column);
          }
          return { ...acc, [column]: value };
        }

        return acc;
      },
      {}
    );
    return {
      ...values,
      month: dayjs(monthData.timestamp).format('YYYY MMM'),
    };
  });

  return generateCsvString(headers, csvData);
};

const adjustPerformanceDataUnits = (data, units) => {
  const multiplier = {
    Wh: 1000,
    kWh: 1,
    MWh: 0.001,
  }[units];

  return map(data, (point) => {
    return merge(
      ...map(point, (value, key) => {
        if (key !== 'timestamp') {
          value = value * multiplier;
        }
        return {
          [key]: value,
        };
      })
    );
  });
};

function PerformanceChartContainer(props) {
  const { sites, meters } = props;

  const [showLegend, setShowLegend] = useState(false);
  const [selectedMonthData, setSelectedMonthData] = useState([]);
  const [selectedSites, setSelectedSites] = useState([]);
  const [range, setRange] = useState({
    start: dayjs().subtract(12, 'month'),
    end: dayjs(),
  });

  const [rawData, setRawData] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [chartType, setChartType] = useState(CHART_TYPES[0]);
  const [dataType, setDataType] = useState(EXPECTATION_TYPES[0]);
  const [unit, setUnit] = useState(H_UNITS[2]);
  const [totalPerformance, setTotalPerformance] = useState({
    actual: 0,
    expected: 0,
  });

  useEffect(() => {
    if (sites?.length <= 5) {
      setSelectedSites(sites);
    } else {
      setSelectedSites(sites.slice(0, 5));
    }
  }, [sites]);

  useEffect(() => {
    if (!isEmpty(rawData)) {
      let _chartData = rawData;
      if (chartType === 'sum') {
        _chartData = map(rawData, (monthData) => {
          let timestamp = get(monthData, 'timestamp');
          let actual = reduce(
            keys(monthData),
            (acc, val) => {
              if (val.endsWith('actual')) {
                return acc + monthData[val];
              }
              return acc;
            },
            0
          );
          let expected = reduce(
            keys(monthData),
            (acc, val) => {
              if (val.endsWith('expected')) {
                return acc + monthData[val];
              }
              return acc;
            },
            0
          );

          return {
            timestamp,
            actual,
            expected,
          };
        });
      }

      setChartData(adjustPerformanceDataUnits(_chartData, unit));
      setTotalPerformance({
        actual: sumAllData(rawData, 'actual'),
        expected: sumAllData(rawData, 'expected'),
      });
    }
  }, [rawData, chartType, unit]);

  return (
    <>
      <Grid item xs={12}>
        <Card raised>
          <CardHeader
            sx={{ '&.MuiCardHeader-root': { padding: 0 } }}
            title={
              <Stack
                direction='row'
                justifyContent='space-evenly'
                alignItems='center'
                divider={
                  <Divider
                    orientation='vertical'
                    flexItem
                    sx={{ mt: 1, mb: 1 }}
                  />
                }>
                <SelectSites
                  allSites={sites}
                  selectedSites={selectedSites}
                  setSelectedSites={setSelectedSites}
                />

                <SelectMonthRange range={range} setRange={setRange} />

                <SelectChartType
                  selectedType={chartType}
                  setSelectedType={setChartType}
                  types={CHART_TYPES}
                />

                <SelectChartType
                  selectedType={dataType}
                  setSelectedType={setDataType}
                  types={EXPECTATION_TYPES}
                />

                <SelectUnit
                  selectedUnit={unit}
                  setSelectedUnit={setUnit}
                  units={H_UNITS}
                />

                <LegendToggle show={showLegend} setShow={setShowLegend} />

                <GenerateCSVButton
                  generateCsvString={() =>
                    prepareCsvData(chartData, sites, unit)
                  }
                  filename={`Site Performance (${dayjs().format(
                    'ddd MMM DD YYYY'
                  )})`}
                />
              </Stack>
            }
          />
        </Card>
      </Grid>
      {isEmpty(selectedSites) ? null : (
        <>
          <Grid item xs={12}>
            <Card raised sx={{ p: 1 }}>
              <PerformanceChart
                sites={selectedSites}
                meters={meters}
                range={range}
                chartData={chartData}
                setRawData={setRawData}
                chartType={chartType}
                dataType={dataType}
                unit={unit}
                showLegend={showLegend}
                setSelectedMonthData={setSelectedMonthData}
              />
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card raised sx={{ p: 1 }}>
              <Stack direction='row'>
                <Typography sx={{ mr: 2 }} color='text.secondary'>
                  Actual Production:
                </Typography>
                <Typography
                  sx={{ mr: 15 }}
                  color={(theme) => theme.palette.veregy_colors.blue}>
                  {displaykW(totalPerformance.actual, true)}
                </Typography>
                <Typography sx={{ mr: 2 }} color='text.secondary'>
                  Expected Production:
                </Typography>
                <Typography
                  sx={{ mr: 15 }}
                  color={(theme) => theme.palette.veregy_colors.orange}>
                  {displaykW(totalPerformance.expected, true)}
                </Typography>
                <Typography sx={{ mr: 2 }} color='text.secondary'>
                  Production Index:
                </Typography>
                <Typography>
                  {numeral(
                    totalPerformance.actual / totalPerformance.expected
                  ).format('0.00')}
                </Typography>
              </Stack>
            </Card>
          </Grid>
          <ExternalTooltip
            sites={sites}
            payload={selectedMonthData}
            unit={unit}
          />
        </>
      )}
    </>
  );
}

PerformanceChartContainer.propTypes = {
  sites: PropTypes.array,
  meters: PropTypes.array,
};

export default PerformanceChartContainer;
