import { useState } from 'react';
import { useOutletContext } from 'react-router';
import { useResizeObserver, useDebouncedCallback } from '@react-hookz/web';
import { useEffect } from 'react';

function useTableWidth(minWidth, maxWidth) {
  const { toolbarRef } = useOutletContext();
  const [contentWidth, setContentWidth] = useState(0);
  const [tableWidth, setTableWidth] = useState(minWidth);

  const resizeObserverCallback = useDebouncedCallback(
    (e) => setContentWidth(e?.contentRect?.width ?? 0),
    [setContentWidth],
    100
  );
  useResizeObserver(toolbarRef, resizeObserverCallback);

  useEffect(() => {
    if (minWidth <= contentWidth && contentWidth < maxWidth) {
      setTableWidth(contentWidth);
    } else if (contentWidth < minWidth) {
      setTableWidth(minWidth);
    } else {
      setTableWidth(maxWidth);
    }
  }, [contentWidth, minWidth, maxWidth]);

  return tableWidth;
}

export default useTableWidth;
