import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import compact from 'lodash/compact';
import get from 'lodash/get';
import includes from 'lodash/includes';

import Link from '@mui/material/Link';
import { navigate } from '../../../../store/pages';
import useDevice from '../../../../store/hooks/useDevice';
import useSite from '../../../../store/hooks/useSite';
import useMeter from '../../../../store/hooks/useMeter';

const separator = '/';

export const DeviceTypeProviderFormatter = (props) => {
  const { value } = props;
  const dispatch = useDispatch();

  const device = useDevice(value);
  const site = useSite(device?.site_id);
  const meter = useMeter(device?.meter_id);
  const [elements, setElements] = useState([]);

  const handleLinkClick = (page, id) => {
    dispatch(
      navigate({
        page,
        id,
      })
    );
  };

  useEffect(() => {
    const devices = compact([
      site?.site_id ? site : null,
      meter?.meter_id ? meter : null,
      device?.type_ && !includes(['meter', 'site'], device?.type_)
        ? device
        : null,
    ]);

    // insert separator in between devices
    const _elements = devices.reduce((acc, curr, idx) => {
      acc.push(curr);
      if (idx < devices.length - 1) {
        acc.push(separator);
      }
      return acc;
    }, []);
    setElements(_elements);
  }, [site, meter, device]);

  if (elements.length === 0) {
    return null;
  }
  return (
    <>
      {elements.map((element, idx) => {
        if (element === separator) {
          return <span key={idx}> {separator} </span>;
        }

        const id = get(element, `${element.type_}_id`);
        return (
          <Link
            key={id}
            underline='hover'
            onClick={() => handleLinkClick(element.type_, id)}>
            {element.name}
          </Link>
        );
      })}
    </>
  );
};
