import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import concat from 'lodash/concat';
import find from 'lodash/find';
import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';

import useTheme from '@mui/material/styles/useTheme';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

export const TIMEZONES = [
  { name: 'UTC', zone: 'UTC', offset: 0 },
  { name: 'MST', offset: -7, zone: 'America/Phoenix' },
];

function SelectTimezone(props) {
  const { selectedTimezone, setSelectedTimezone } = props;
  const theme = useTheme();

  const [timezone, setTimezone] = useState(TIMEZONES[0]);
  const [timezones, setTimezones] = useState(TIMEZONES);

  useEffect(() => {
    setTimezone(selectedTimezone);
    if (!find(timezones, { name: selectedTimezone.name })) {
      setTimezones(
        reverse(sortBy(concat(timezones, selectedTimezone), 'offset'))
      );
    }

    /* eslint-disable-next-line */
  }, [selectedTimezone]);

  const handleSelect = (e) => {
    e.preventDefault();
    let _timezone = find(timezones, { name: e.target.value });
    setSelectedTimezone(_timezone);
  };

  return (
    <FormControl variant='standard'>
      <InputLabel
        id='timezone-label'
        sx={{ textAlign: 'center', width: '5rem' }}>
        Timezone
      </InputLabel>
      <Select
        disableUnderline
        labelId='timezone-label'
        value={timezone.name}
        renderValue={() => (
          <ListItemText
            sx={{ color: theme.palette.primary.main, my: 0 }}
            primary={timezone.name}
            primaryTypographyProps={{ align: 'center' }}
          />
        )}
        onChange={handleSelect}
        IconComponent={() => null}
        inputProps={{
          sx: {
            padding: '0 !important',
            color: theme.palette.primary.main,
            ...theme.typography.body2,
          },
        }}
        MenuProps={{
          MenuListProps: { disablePadding: true },
          slotProps: {
            paper: { sx: { minWidth: 'unset !important' } },
          },
        }}
        sx={{
          minWidth: '4rem',
          backgroundColor: 'unset',
          '& .MuiSelect-select': {
            display: 'flex',
            alignItems: 'center',
          },
        }}>
        {timezones.map((timezone) => {
          return (
            <MenuItem
              key={timezone.zone}
              value={timezone.name}
              sx={{
                color: theme.palette.primary.main,
                ...theme.typography.body2,
              }}>
              {timezone.name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}

SelectTimezone.propTypes = {
  selectedTimezone: PropTypes.object,
  setSelectedTimezone: PropTypes.func,
};

export default SelectTimezone;
