import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';

import useSite from '../../../../store/hooks/useSite';
import useMeter from '../../../../store/hooks/useMeter';
import useSensor from '../../../../store/hooks/useSensor';
import useDeviceAlarms from '../../../../store/hooks/useDeviceAlarms';

import SensorInfo from './SensorInfo';
import LatestData from './LatestData';
import ViewStack from '../../../../components/ViewStack';
import AlarmsTable from '../../../../components/table/tables/AlarmsTable';
import SensorChart from '../../../../components/charts/dashboard/SensorChart';

const TREND = 'trend';
const ALARMS = 'alarms';
const LATEST = 'latest';
const VIEWS = [TREND, ALARMS, LATEST];

function SensorDashboard() {
  const { id } = useSelector((state) => state.pages.sensor);
  const sensor = useSensor(id);
  const meter = useMeter(sensor.meter_id);
  const site = useSite(meter.site_id);
  const alarms = useDeviceAlarms(id);

  const [selectedView, setSelectedView] = useState(TREND);

  return (
    <Grid
      container
      direction='row'
      justifyContent='center'
      alignItems='stretch'
      spacing={1}
      mt='0 !important'
      px={1}
      mb={6}
      sx={{ maxWidth: '100%' }}>
      <Grid item xs={12} display='flex' justifyContent='center'>
        <SensorInfo sensor={sensor} alarms={alarms} />
      </Grid>
      <Grid item xs={12}>
        <ViewStack
          selectedView={selectedView}
          setSelectedView={setSelectedView}
          views={VIEWS}
        />
      </Grid>
      {selectedView === TREND && (
        <Grid item xs={12}>
          <Card raised sx={{ p: 1 }}>
            <SensorChart site={site} sensor={sensor} hideAxisLabels={false} />
          </Card>
        </Grid>
      )}
      {selectedView === ALARMS && (
        <Grid
          item
          xs={12}
          sx={{ display: 'flex', justifyContent: 'center', maxWidth: '100vw' }}>
          <AlarmsTable resource={sensor} alarms={alarms} />
        </Grid>
      )}
      {selectedView === LATEST && <LatestData />}
    </Grid>
  );
}

export default SensorDashboard;
