import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import map from 'lodash/map';

const filterDevices = (devices, childOrgIds) => {
  return filter(devices, (device) => includes(childOrgIds, device.org_id));
};

const usePortfolioDevices = (portfolioId) => {
  const { data: allAlarms, loading: alarmLoading } = useSelector(
    (state) => state.alarms
  );
  const { data: allSites, loading: siteLoading } = useSelector(
    (state) => state.sites
  );
  const { data: allLoggers, loading: loggerLoading } = useSelector(
    (state) => state.loggers
  );
  const { data: allMeters, loading: meterLoading } = useSelector(
    (state) => state.meters
  );
  const { data: allInverters, loading: inverterLoading } = useSelector(
    (state) => state.inverters
  );
  const { data: allSensors, loading: sensorLoading } = useSelector(
    (state) => state.sensors
  );
  const portfolioMemberships = useSelector(
    (state) => state.organizations.portfolioMemberships
  );

  const [childOrgIds, setChildOrgIds] = useState([]);
  const [alarms, setAlarms] = useState([]);
  const [sites, setSites] = useState([]);
  const [loggers, setLoggers] = useState([]);
  const [meters, setMeters] = useState([]);
  const [inverters, setInverters] = useState([]);
  const [sensors, setSensors] = useState([]);

  useEffect(() => {
    const childRelations = filter(portfolioMemberships, {
      portfolio_id: portfolioId,
    });
    setChildOrgIds(map(childRelations, (relation) => relation.member_id));
  }, [portfolioMemberships, portfolioId]);

  useEffect(() => {
    setAlarms(filterDevices(allAlarms, childOrgIds));
  }, [allAlarms, childOrgIds]);

  useEffect(() => {
    setSites(filterDevices(allSites, childOrgIds));
  }, [allSites, childOrgIds]);

  useEffect(() => {
    setLoggers(filterDevices(allLoggers, childOrgIds));
  }, [allLoggers, childOrgIds]);

  useEffect(() => {
    setMeters(filterDevices(allMeters, childOrgIds));
  }, [allMeters, childOrgIds]);

  useEffect(() => {
    setInverters(filterDevices(allInverters, childOrgIds));
  }, [allInverters, childOrgIds]);

  useEffect(() => {
    setSensors(filterDevices(allSensors, childOrgIds));
  }, [allSensors, childOrgIds]);

  return {
    alarms,
    sites,
    loggers,
    meters,
    inverters,
    sensors,
    loading:
      alarmLoading ||
      siteLoading ||
      loggerLoading ||
      meterLoading ||
      inverterLoading ||
      sensorLoading,
  };
};

export default usePortfolioDevices;
