import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import concat from 'lodash/concat';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import map from 'lodash/map';

const useSiteDevices = (siteId) => {
  const { data: allAlarms } = useSelector((state) => state.alarms);
  const { data: allLoggers, loading: loggerLoading } = useSelector(
    (state) => state.loggers
  );
  const { data: allMeters, loading: meterLoading } = useSelector(
    (state) => state.meters
  );
  const { data: allInverters, loading: inverterLoading } = useSelector(
    (state) => state.inverters
  );
  const { data: allSensors, loading: sensorLoading } = useSelector(
    (state) => state.sensors
  );

  const [alarms, setAlarms] = useState([]);
  const [loggers, setLoggers] = useState([]);
  const [meters, setMeters] = useState([]);
  const [meterIds, setMeterIds] = useState([]);
  const [inverters, setInverters] = useState([]);
  const [sensors, setSensors] = useState([]);

  useEffect(() => {
    let deviceIds = concat(
      siteId,
      map(loggers, (logger) => logger.logger_id),
      meterIds,
      map(inverters, (inverter) => inverter.inverter_id),
      map(sensors, (sensor) => sensor.sensor_id)
    );
    setAlarms(
      filter(allAlarms, (alarm) => includes(deviceIds, alarm.device_id))
    );
  }, [siteId, loggers, meterIds, inverters, sensors, allAlarms]);

  useEffect(() => {
    setLoggers(filter(allLoggers, { site_id: siteId }));
  }, [allLoggers, siteId]);

  useEffect(() => {
    const _meters = filter(allMeters, { site_id: siteId });
    setMeters(_meters);
    setMeterIds(map(_meters, (meter) => meter.meter_id));
  }, [allMeters, siteId]);

  useEffect(() => {
    setInverters(
      filter(allInverters, (inverter) => includes(meterIds, inverter.meter_id))
    );
  }, [allInverters, meterIds]);

  useEffect(() => {
    setSensors(
      filter(allSensors, (sensor) => includes(meterIds, sensor.meter_id))
    );
  }, [allSensors, meterIds]);

  return {
    loggers,
    meters,
    inverters,
    sensors,
    alarms,
    loading: loggerLoading || meterLoading || inverterLoading || sensorLoading,
  };
};

export default useSiteDevices;
