import LoadingBar from 'react-redux-loading-bar';
import styled from '@mui/material/styles/styled';

export const ReduxLoader = styled(LoadingBar)(({ theme }) => ({
  backgroundColor: theme.palette.veregy_colors.lightblue,
  height: '3px',
  position: 'absolute',
  zIndex: 99999,
  top: 0,
}));
