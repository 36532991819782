import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { APP_ID } from '../../helpers/auth';
import { setPage } from '../pages';

const usePageId = (id, page) => {
  const dispatch = useDispatch();
  useEffect(() => {
    const storedId = localStorage.getItem(`${APP_ID}.${page}`);
    if (storedId && !id) {
      dispatch(setPage({ page, id: storedId }));
    }
  }, [dispatch, page, id]);

  useEffect(() => {
    if (id) {
      localStorage.setItem(`${APP_ID}.${page}`, id);
    }
  }, [id, page]);
};

export default usePageId;
