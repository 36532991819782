import passwordValidator from 'password-validator';

var passwordSchema = new passwordValidator();
passwordSchema
  .is()
  .min(8)
  .is()
  .max(30)
  .has()
  .uppercase()
  .has()
  .lowercase()
  .has()
  .digits()
  .has()
  .symbols()
  .has()
  .not()
  .spaces()
  .is()
  .not()
  .oneOf(['12345678', 'asdfghjkl', 'password', 'Passw0rd', 'Password123']);

export default function validatePassword(password) {
  var valid = passwordSchema.validate(password, { list: true });
  return {
    valid: valid.length > 0,
    errors: valid.reduce((acc, error) => {
      switch (error) {
        case 'min':
          acc.push('8 characters');
          break;
        case 'max':
          acc.push('30 characters or less');
          break;
        case 'uppercase':
          acc.push('1 uppercase letter');
          break;
        case 'lowercase':
          acc.push('1 lowercase letter');
          break;
        case 'digits':
          acc.push('1 digit');
          break;
        default:
      }
      return acc;
    }, []),
  };
}
