import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import findIndex from 'lodash/findIndex';
import map from 'lodash/map';

import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import {
  SortingState,
  IntegratedSorting,
  IntegratedFiltering,
  FilteringState,
  PagingState,
  SelectionState,
  IntegratedSelection,
  IntegratedPaging,
} from '@devexpress/dx-react-grid';

import Tooltip from './Tooltip';
import DialogTitleOptions from '../../Dialogs/DialogTitleOptions';
import BaseTable from '../../table/tables/BaseTable';
function SelectSites(props) {
  const { allSites, selectedSites, setSelectedSites } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const [columns] = useState([{ title: 'Site', name: 'name', width: 0.8 }]);

  const [open, setOpen] = useState(false);
  const [selection, setSelection] = useState([]);
  const [sorting, setSorting] = useState([{ columnName: 'name' }]);

  useEffect(() => {
    setSelection(
      map(selectedSites, (site) => {
        return findIndex(allSites, { site_id: site?.site_id });
      })
    );
    /* eslint-disable-next-line */
  }, [open]);

  const handleSelect = () => {
    setSelectedSites(
      map(selection, (idx) => {
        return allSites[idx];
      })
    );
    setSelection([]);
    handleClose();
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip
        title={
          <List disablePadding>
            {map(selectedSites, (site) => (
              <ListItem key={`${site?.site_id}-idx`}>
                <ListItemText
                  primaryTypographyProps={{
                    color: theme.palette.primary.main,
                  }}>
                  {site?.name}
                </ListItemText>
              </ListItem>
            ))}
          </List>
        }>
        <Button color='primary' onClick={() => setOpen(true)}>
          Sites
        </Button>
      </Tooltip>
      <Dialog open={open} onClose={handleClose} fullScreen={isMobile}>
        <DialogTitle>
          Select Sites
          <DialogTitleOptions handleClose={handleClose} />
        </DialogTitle>
        <DialogContent sx={{ maxWidth: 500 }}>
          <BaseTable rows={allSites} columns={columns} selection maxWidth={500}>
            <SelectionState
              selection={selection}
              onSelectionChange={setSelection}
            />
            <FilteringState />
            <SortingState sorting={sorting} onSortingChange={setSorting} />
            <PagingState defaultCurrentPage={0} pageSize={10} />
            <IntegratedFiltering />
            <IntegratedSorting />
            <IntegratedSelection />
            <IntegratedPaging />
          </BaseTable>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button variant='contained' onClick={handleSelect}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

SelectSites.propTypes = {
  allSites: PropTypes.array,
  selectedSites: PropTypes.array,
  setSelectedSites: PropTypes.func,
};

export default SelectSites;
