import React from 'react';
import get from 'lodash/get';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

function ToolbarPreview(props) {
  const {
    resource,
    logoFilename,
    url,
    imageBlobUrl,
    imageFilename,
    height,
    padding,
  } = props;

  const bgcolor = get(resource, 'theme.primary') || '#195e88';
  const textColor = get(resource, 'theme.text');
  const src = imageBlobUrl || url || '/images/LightLevel_by_Veregy.svg';
  const alt = imageFilename || logoFilename || 'LightLevel';

  return (
    <AppBar position='relative' sx={{ bgcolor }}>
      <Toolbar sx={{ width: '100%', justifyContent: 'space-between' }}>
        <Box
          component='img'
          src={src}
          alt={alt}
          height={Number(height)}
          sx={{ padding: `${padding}px` }}
        />
        <Typography
          variant='h5'
          align='right'
          color={textColor || 'white'}
          sx={{ mr: 1 }}>
          {resource?.name}
        </Typography>
      </Toolbar>
    </AppBar>
  );
}

export default ToolbarPreview;
