import toLower from 'lodash/toLower';
import startCase from 'lodash/startCase';

const useTriggerNamePredicate = () => {
  return (value, filter) => {
    return toLower(startCase(value)).includes(toLower(filter.value));
  };
};

export default useTriggerNamePredicate;
