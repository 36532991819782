export const SLIDES = [
  {
    title: 'current',
    hidden: false,
    interval_seconds: 60,
  },
  {
    title: 'last_month',
    hidden: false,
    interval_seconds: 60,
  },
  {
    title: 'lifetime',
    hidden: false,
    interval_seconds: 60,
  },
  {
    title: 'case_study',
    hidden: true,
    interval_seconds: 60,
  },
  {
    title: 'images',
    hidden: true,
    interval_seconds: 60,
  },
];
