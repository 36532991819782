import find from 'lodash/find';
import { useSelector } from 'react-redux';

const useCompareOrgSiteName = () => {
  const organizations = useSelector((state) => state.organizations.data);
  const sites = useSelector((state) => state.sites.data);

  const findResource = (resourceId) => {
    if (resourceId.startsWith('org')) {
      return find(organizations, { org_id: resourceId });
    }
    return find(sites, { site_id: resourceId });
  };

  return (resourceId1, resourceId2) => {
    const resource1 = findResource(resourceId1);
    const resource2 = findResource(resourceId2);

    if (resourceId1.startsWith('org') && resourceId2.startsWith('site')) {
      return 1;
    }
    if (resourceId1.startsWith('site') && resourceId2.startsWith('org')) {
      return -1;
    }

    return resource1?.name < resource2?.name ? -1 : 1;
  };
};

export default useCompareOrgSiteName;
