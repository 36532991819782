import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import filter from 'lodash/filter';
import find from 'lodash/find';
import map from 'lodash/map';

const usePortfolioMembers = (portfolioId) => {
  const {
    data: organizations,
    portfolioMemberships,
    loading: orgLoading,
  } = useSelector((state) => state.organizations);

  const [loading, setLoading] = useState(true);
  const [portfolioMembers, setPortfolioMembers] = useState([]);

  useEffect(() => {
    const childRelations = filter(portfolioMemberships, {
      portfolio_id: portfolioId,
    });
    const childOrgIds = map(childRelations, (relation) => relation.member_id);

    setLoading(false);
    setPortfolioMembers(
      map(childOrgIds, (orgId) => find(organizations, { org_id: orgId }))
    );
  }, [portfolioMemberships, organizations, portfolioId]);

  return { portfolioMembers, loading: loading || orgLoading };
};

export default usePortfolioMembers;
