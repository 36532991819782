import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';

import { unregister } from './serviceWorker';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);

unregister();
