import { createAsyncThunk } from '@reduxjs/toolkit';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import dayjs from 'dayjs';
import cloneDeep from 'lodash/cloneDeep';
import each from 'lodash/each';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import forEach from 'lodash/forEach';
import get from 'lodash/get';
import map from 'lodash/map';

import WebAPIClient, { errorResponseToastr } from '../../api';
import { getLatestInterval } from '../../helpers/date';
import { setWebsocketLastUpdate } from '../pages';

const refreshDeviceTimeseries = createAsyncThunk(
  'timeseries/refreshDeviceTimeseries',
  async (devices, { getState, dispatch, requestId }) => {
    const {
      data: _allTimeseries,
      loading,
      currentRequestId,
    } = getState().timeseries;
    let allTimeseries = cloneDeep(_allTimeseries);

    if (!loading || requestId !== currentRequestId) {
      return;
    }

    try {
      let today = dayjs();
      // let latestInterval = getLatestInterval().add(5, 'minute');
      let latestInterval = getLatestInterval();
      let _timeseries = [];
      forEach(devices, (device) => {
        if (!device.type_) return;
        for (const dataType of ['estimated', 'recent']) {
          if (device.type_ !== 'meter' && dataType === 'estimated') continue;
          const deviceId = device[`${device.type_}_id`];
          const timeseries = find(allTimeseries, {
            device_id: deviceId,
            data_type: 'recent',
          });

          let refresh = true;
          const lastRefresh = get(timeseries, 'lastRefresh');
          if (lastRefresh) {
            if (dataType === 'estimated') {
              refresh = lastRefresh?.isBefore(today, 'day');
            } else {
              refresh = lastRefresh?.isBefore(latestInterval);
            }
          }

          if (refresh) {
            _timeseries.push({
              orgId: device.org_id,
              deviceId,
              dataType,
            });
          }
        }
      });

      if (_timeseries.length > 0) {
        dispatch(showLoading());
        console.info(
          `REFRESH TIMESERIES :: ${_timeseries.length} ::`,
          dayjs().format('MM-DD HH:mm:ss')
        );
        let resolvedTimeseries = await Promise.all(
          map(_timeseries, async ({ orgId, deviceId, dataType }) => {
            const timeseries = await new WebAPIClient().GET(
              `/resource/timeseries/${orgId}/${deviceId}`,
              { dataType }
            );
            return { ...timeseries, lastRefresh: dayjs() };
          })
        );

        each(resolvedTimeseries, (timeseries) => {
          const index = findIndex(allTimeseries, {
            device_id: timeseries.meter_id,
            data_type: timeseries.data_type,
          });
          if (index > -1) {
            allTimeseries[index] = timeseries;
          } else {
            allTimeseries.push(timeseries);
          }
        });

        dispatch(setWebsocketLastUpdate(dayjs()));
        return { data: allTimeseries };
      }
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

export { refreshDeviceTimeseries };
