import React from 'react';

import Typography from '@mui/material/Typography';
import { TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';

const Header = ({ column, children, classes, ...restProps }) => {
  return (
    <TableHeaderRow.Content column={column} {...restProps}>
      <Typography
        variant='subtitle2'
        style={{ fontWeight: 'bold' }}
        align='center'>
        {children}
      </Typography>
    </TableHeaderRow.Content>
  );
};

export default Header;
