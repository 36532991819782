import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { toastr } from 'react-redux-toastr';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import TextField from '@mui/material/TextField';

import { errorResponseToastr } from '../../../api';
import { changePassword } from '../../../helpers/auth/cognito';
import validatePassword from '../../../helpers/password-validator';
import { openConfirmDialog } from '../../../store/dialogs';
import ToastrMessages from '../../../components/ToastrMessages';

const PasswordField = (props) => {
  return (
    <ListItem>
      <TextField
        sx={{ width: '100%' }}
        required
        type='password'
        variant='standard'
        inputProps={{
          autoComplete: 'off',
        }}
        {...props}
      />
    </ListItem>
  );
};

const ChangePasswordForm = () => {
  const dispatch = useDispatch();
  const [currentPassword, setCurrentPassword] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validatePassword(password);
    if (validationErrors.length > 0) {
      toastr.error('Password validation', {
        component: <ToastrMessages messages={validationErrors} />,
      });
    } else if (confirmPassword !== password) {
      toastr.error('Passwords do not match');
    } else {
      try {
        dispatch(
          openConfirmDialog({
            title: 'Change Password',
            message: (
              <p>
                Are you sure you want to change your Orchestrate SSO password?
              </p>
            ),
            onConfirm: async () => {
              dispatch(showLoading());
              await changePassword(currentPassword, password);
              toastr.success('Password updated');
            },
          })
        );
        setCurrentPassword('');
        setPassword('');
        setConfirmPassword('');
      } catch (error) {
        errorResponseToastr(error);
      } finally {
        dispatch(hideLoading());
      }
    }
  };

  const handleClear = async () => {
    setCurrentPassword('');
    setPassword('');
    setConfirmPassword('');
  };

  return (
    <Grid item xs={12} display='flex' justifyContent='center'>
      <Card raised sx={{ maxWidth: 650, width: '100%', pt: 1 }}>
        <form>
          <List disablePadding>
            <PasswordField
              id='current-password'
              label='Current Password'
              value={currentPassword}
              autoComplete='current-password'
              onChange={(e) => setCurrentPassword(e.target.value)}
            />
            <PasswordField
              id='new-password'
              label='New Password'
              value={password}
              autoComplete='new-password'
              onChange={(e) => setPassword(e.target.value)}
            />
            <PasswordField
              id='confirm-password'
              label='Confirm Password'
              value={confirmPassword}
              autoComplete='confirm-password'
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </List>
        </form>
        <CardActions sx={{ justifyContent: 'flex-end' }}>
          <Button onClick={handleClear} variant='outlined'>
            Clear
          </Button>
          <Button color='primary' variant='contained' onClick={handleSubmit}>
            Submit
          </Button>
        </CardActions>
      </Card>
    </Grid>
  );
};

export default ChangePasswordForm;
