import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TableEditColumn } from '@devexpress/dx-react-grid-material-ui';
import ListItem from '@mui/material/ListItem';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

const EditCell = ({
  disabled = true,
  row,
  tooltipText,
  handleClick,
  icon,
  ...props
}) => {
  return (
    <TableEditColumn.Cell {...props} style={{ padding: 1 }}>
      <ListItem alignItems='flex-start' sx={{ padding: 0 }}>
        <Tooltip
          title={disabled ? 'Insufficient Permissions' : tooltipText}
          placement='left'>
          <span>
            <IconButton size='small' onClick={handleClick} disabled={disabled}>
              <FontAwesomeIcon
                size='xs'
                style={{ width: 20, height: 20 }}
                icon={icon ? icon : ['fal', 'edit']}
              />
            </IconButton>
          </span>
        </Tooltip>
      </ListItem>
    </TableEditColumn.Cell>
  );
};

export default EditCell;
