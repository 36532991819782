import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import get from 'lodash/get';
import remove from 'lodash/remove';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';

import ROLES from '../../../constants/roles';
import {
  deleteImage,
  getImages,
  getPresignedUrl,
  postImage,
  renameImage,
} from '../../../store/images/_images';
import useVerifyOrgRole from '../../../store/hooks/useVerifyOrgRole';

function Images(props) {
  const { resource, resourceId } = props;
  const fileInputRef = useRef(null);
  const dispatch = useDispatch();
  const isOrgEditor = useVerifyOrgRole(resource?.org_id, ROLES.EDITOR.value);

  const allImages = useSelector((state) => state.images);
  const storeUrls = useSelector((state) =>
    get(state.images.presignedUrls, resourceId, [])
  );

  const [storeImages, setStoreImages] = useState([]);
  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState({
    suffix: '',
    ext: '',
    oldName: '',
  });

  useEffect(() => {
    setStoreImages(get(allImages, resourceId, []));
  }, [allImages, resourceId]);

  useEffect(() => {
    let filteredImages = [...storeImages];
    remove(
      filteredImages,
      (image) => image.startsWith('case-study.') || image.startsWith('logo.')
    );
    setImages(filteredImages);
  }, [storeImages]);

  const handleDelete = async (e, image) => {
    e.preventDefault();
    await dispatch(deleteImage({ resourceId, filename: image }));
    dispatch(getImages(resourceId));
  };

  const handleFileUpload = async (e) => {
    e.preventDefault();
    let imageFile = null;
    if (e.dataTransfer) {
      imageFile = e.dataTransfer.files[0];
    } else if (e.target.files) {
      imageFile = e.target.files[0];
    }

    if (
      imageFile.name.startsWith('logo.') ||
      imageFile.name.startsWith('case-study.')
    ) {
      toastr.error('Invalid Filename', imageFile.name);
      return;
    }

    await dispatch(
      postImage({
        resource_id: resourceId,
        image: imageFile,
        filename: imageFile.name,
      })
    );
    dispatch(getImages(resourceId));
  };

  const handleView = async (e, image) => {
    e.preventDefault();
    let url = storeUrls[image];

    if (!url) {
      const response = await dispatch(
        getPresignedUrl({ resourceId, key: image })
      );
      url = get(response, `payload.presignedUrls.${resourceId}`)[image];
    }

    if (url) window.open(url, '_blank');
  };

  const selectImage = async (e, image) => {
    e.preventDefault();
    const lastDotIdx = image.lastIndexOf('.');

    setSelectedImage({
      suffix: image.slice(0, lastDotIdx),
      ext: image.slice(lastDotIdx),
      oldName: image,
    });
  };

  const handleImageNameChange = (e) => {
    setSelectedImage((v) => ({ ...v, suffix: e.target.value }));
  };

  const saveImage = async (e) => {
    e.preventDefault();
    await dispatch(
      renameImage({
        resourceId,
        oldName: selectedImage.oldName,
        newName: selectedImage.suffix + selectedImage.ext,
      })
    );
    dispatch(getImages(resourceId));
    setSelectedImage({ suffix: '', ext: '', oldName: '' });
  };

  return (
    <Card raised sx={{ width: '100%', pb: 0.5, px: 1 }}>
      <CardHeader
        sx={{ py: 1, px: 1 }}
        title='Images'
        titleTypographyProps={{ variant: 'h6' }}
        action={
          <Tooltip placement='top' title='upload'>
            <IconButton
              component='label'
              disabled={!isOrgEditor}
              sx={{ mr: 1 }}>
              <FontAwesomeIcon
                icon={['fal', 'upload']}
                size='sm'
                style={{ height: 23, width: 23 }}
              />
              <input
                hidden
                accept='image/*'
                type='file'
                ref={fileInputRef}
                onChange={handleFileUpload}
              />
            </IconButton>
          </Tooltip>
        }
      />
      <List disablePadding>
        {images.map((image, idx) => (
          <div key={image}>
            {image === selectedImage.oldName ? (
              <ClickAwayListener
                onClickAway={() =>
                  setSelectedImage({ suffix: '', ext: '', oldName: '' })
                }>
                <ListItem dense disableGutters>
                  <TextField
                    id='primary'
                    value={selectedImage.suffix}
                    variant='standard'
                    sx={{ width: '100%' }}
                    onChange={handleImageNameChange}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        saveImage(e);
                      }
                    }}
                    InputProps={{
                      disableUnderline: true,
                      endAdornment: (
                        <InputAdornment position='end'>
                          {selectedImage.ext}
                        </InputAdornment>
                      ),
                    }}
                  />
                </ListItem>
              </ClickAwayListener>
            ) : (
              <ListItem
                disableGutters
                dense
                secondaryAction={
                  <IconButton
                    disabled={!isOrgEditor}
                    onClick={(e) => selectImage(e, image)}>
                    <FontAwesomeIcon
                      icon={['fal', 'edit']}
                      style={{ height: 20, width: 20 }}
                    />
                  </IconButton>
                }>
                <ListItemIcon>
                  <IconButton
                    disabled={!isOrgEditor}
                    onClick={(e) => handleDelete(e, image)}>
                    <FontAwesomeIcon
                      icon={['fal', 'trash']}
                      style={{ height: 20, width: 20 }}
                    />
                  </IconButton>
                </ListItemIcon>
                <Link underline='hover' onClick={(e) => handleView(e, image)}>
                  {image}
                </Link>
              </ListItem>
            )}
            {idx !== images.length - 1 && <Divider />}
          </div>
        ))}
      </List>
    </Card>
  );
}

export default Images;
