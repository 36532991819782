import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import map from 'lodash/map';

import Grid from '@mui/material/Grid';

import { navigate } from '../../../../store/pages';
import LoggerCard from './LoggerCard';
import CustomLink from '../../../../components/CustomLink';

function LoggersGrid(props) {
  const { site, loggerIds, loading } = props;
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(
      navigate({
        page: 'site',
        id: site.site_id,
        tab: 'config',
        view: 'devices',
      })
    );
  };

  if (!loading && loggerIds.length === 0) {
    return (
      <Grid key='no-devices-card' item xs={12}>
        <CustomLink handleClick={handleClick}>Configure Devices</CustomLink>
      </Grid>
    );
  }

  return map(loggerIds, (loggerId) => {
    return (
      <Grid key={loggerId} item xs={12}>
        <LoggerCard loggerId={loggerId} timezone={site.timezone} />
      </Grid>
    );
  });
}

LoggersGrid.propTypes = {
  site: PropTypes.object,
  loggers: PropTypes.array,
  meters: PropTypes.array,
  loading: PropTypes.bool,
};

export default LoggersGrid;
