import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import find from 'lodash/find';

import Link from '@mui/material/Link';
import { DataTypeProvider } from '@devexpress/dx-react-grid';

import { navigate } from '../../../store/pages';

export const OrgSiteIdNameLinkTypeProvider = (props) => {
  const dispatch = useDispatch();
  const { data: organizations } = useSelector((state) => state.organizations);
  const { data: sites } = useSelector((state) => state.sites);

  const findResource = (id) => {
    let _resource = {};
    let _type = '';

    if (id.startsWith('org')) {
      _resource = find(organizations, { org_id: id });
      _type = 'organization';
    } else if (id.startsWith('site')) {
      _resource = find(sites, { site_id: id });
      _type = 'site';
    }

    return [_resource, _type];
  };

  const handleClick = (id, page) => {
    dispatch(
      navigate({
        page,
        id: id,
      })
    );
  };

  return (
    <DataTypeProvider
      formatterComponent={({ value: id }) => {
        const [resource, type] = findResource(id);
        return (
          <Link underline='hover' onClick={() => handleClick(id, type)}>
            {resource?.name}
          </Link>
        );
      }}
      {...props}
    />
  );
};
