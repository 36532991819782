import React from 'react';
import { DataTypeProvider } from '@devexpress/dx-react-grid';

export const DateTypeProvider = (props) => {
  return (
    <DataTypeProvider
      formatterComponent={({ value }) => {
        if (!value) return null;
        return value.slice(0, 10);
      }}
      {...props}
    />
  );
};
