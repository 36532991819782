import { createSlice } from '@reduxjs/toolkit';
import { buildAsyncReducers } from '../thunkTemplate';
import { memberships as initialState } from '../initialState';

import {
  getMemberships,
  postMembership,
  putMembership,
  deleteMembership,
} from './_memberships';

// NOTE: "Mutating" state is safe in redux toolkit because it uses Immer
const { reducer } = createSlice({
  name: 'memberships',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    buildAsyncReducers(builder, [
      getMemberships,
      postMembership,
      putMembership,
      deleteMembership,
    ]);
  },
});

// Export the reducer, either as a default or named export
export { getMemberships, postMembership, putMembership, deleteMembership };
export default reducer;
