import { createAsyncThunk } from '@reduxjs/toolkit';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { toastr } from 'react-redux-toastr';

import cloneDeep from 'lodash/cloneDeep';
import concat from 'lodash/concat';
import get from 'lodash/get';
import filter from 'lodash/filter';
import find from 'lodash/find';
import head from 'lodash/head';
import map from 'lodash/map';
import remove from 'lodash/remove';

import WebAPIClient, { errorResponseToastr } from '../../api';
import { updateNodes } from '../nodes';
import { updateTimeseriesData } from '../timeseries';

const getLoggers = createAsyncThunk(
  'loggers/getLoggers',
  async (_, { getState, requestId }) => {
    const { currentRequestId, loading } = getState().loggers;
    if (loading !== true || requestId !== currentRequestId) {
      return;
    }

    const loggers = await new WebAPIClient().GET('/resource/loggers');
    return { data: loggers };
  }
);

const putLogger = createAsyncThunk(
  'loggers/putLogger',
  async (logger, { dispatch, getState, requestId }) => {
    try {
      const { currentRequestId, loading, data: loggers } = getState().loggers;

      if (loading !== true || requestId !== currentRequestId) {
        return;
      }

      dispatch(showLoading());
      let updatedLogger = await new WebAPIClient().PUT(
        `/resource/loggers/${logger.org_id}/${logger.logger_id}`,
        logger
      );

      let _loggers = cloneDeep(loggers);
      remove(_loggers, { logger_id: get(updatedLogger, 'logger_id') });

      toastr.success('Logger updated');
      _loggers = concat(_loggers, updatedLogger);
      dispatch(updateNodes({ loggers: _loggers }));
      return { data: _loggers };
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

const handleSuccess = createAsyncThunk(
  'loggers/handleSuccess',
  async (payload, { dispatch, getState }) => {
    try {
      const { data: allLoggers } = getState().loggers;
      const { data: meters } = getState().meters;
      const { data: inverters } = getState().inverters;
      const { data: sensors } = getState().sensors;
      let loggers = cloneDeep(allLoggers);

      dispatch(showLoading());
      const orgId = payload.org_id;
      const loggerId = payload.logger_id;
      const lastOkTime = payload.last_ok_time;
      const timestamp = payload.timestamp;

      // update logger
      let logger = find(loggers, { logger_id: loggerId, org_id: orgId });
      logger.stats.last_ok_time = lastOkTime;
      logger.stats.successes += 1;

      // find devices
      let loggerMeters = filter(meters, { logger_id: loggerId, org_id: orgId });
      let loggerMeterIds = map(loggerMeters, 'meter_id');
      let loggerInverters = filter(inverters, (inverter) => {
        return (
          inverter.org_id === orgId &&
          loggerMeterIds.includes(inverter.meter_id)
        );
      });
      let loggerSensors = filter(sensors, (sensor) => {
        return (
          sensor.org_id === orgId && loggerMeterIds.includes(sensor.meter_id)
        );
      });

      // update timeseries
      let meterValues = map(loggerMeterIds, (meterId) => {
        const meter = find(loggerMeters, { meter_id: meterId });
        const value = payload[`kW_Demand_System_Now_M${meter.parent_index}`];
        return { meterId, value };
      });

      let inverterValues = map(loggerInverters, (inverter) => {
        const loggerIndex = inverter.parent_index;
        const value = payload[`ActivePower_I${loggerIndex}`];
        return { inverterId: inverter.inverter_id, value };
      });

      let sensorValues = map(loggerSensors, (sensor) => {
        const loggerIndex = sensor.parent_index;
        const radiationKey = `Radiation_S${loggerIndex}`;
        const temperatureKey = `PanelTemp_S${loggerIndex}`;
        return {
          sensorId: sensor.sensor_id,
          [radiationKey]: payload[radiationKey],
          [temperatureKey]: payload[temperatureKey],
        };
      });

      dispatch(
        updateTimeseriesData({
          meterValues,
          inverterValues,
          sensorValues,
          timestamp,
        })
      );

      remove(loggers, { logger_id: loggerId, org_id: orgId });
      return { data: concat(loggers, logger) };
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

const handleFailure = createAsyncThunk(
  'loggers/handleFailure',
  async (payload, { dispatch, getState, requestId }) => {
    try {
      const { data: loggers } = getState().loggers;
      const allLoggers = cloneDeep(loggers);
      let logger = head(
        remove(allLoggers, {
          logger_id: payload.logger_id,
          org_id: payload.org_id,
        })
      );

      if (logger) {
        logger.stats.last_fail_time = payload.last_fail_time;
        logger.stats.failures += 1;
        logger.stats.last_fail_message = payload.message;
        logger.online = false;
        return { data: concat(allLoggers, logger) };
      }
      return {};
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

export { getLoggers, putLogger, handleSuccess, handleFailure };
