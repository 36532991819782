import React from 'react';
import get from 'lodash/get';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import PerformanceIndexChart from '../../../components/charts/PerformanceIndexChart';

export const PIChartTypeProvider = (props) => {
  return (
    <DataTypeProvider
      formatterComponent={(innerProps) => {
        const { value } = innerProps;
        return (
          <PerformanceIndexChart
            width={props.width / 2}
            actual={get(value, 'actual')}
            expected={get(value, 'expected')}
            loading={false}
          />
        );
      }}
      {...props}
    />
  );
};
