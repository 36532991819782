import React, { useState } from 'react';
import PropTypes from 'prop-types';
import lowerCase from 'lodash/lowerCase';

import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Chip from '@mui/material/Chip';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Popover from '@mui/material/Popover';

import ROLES, { findRole } from '../../../constants/roles';

const CustomPopover = (props) => {
  const { value, row: membership, handleClick, disabled } = props;
  const [anchorEl, setAnchorEl] = useState(null);

  const handleChipClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open
    ? `popover-${membership?.org_id}-${membership?.user_id}`
    : undefined;
  const currentRoleName = findRole(value);
  const currentRole = ROLES[currentRoleName];
  return (
    <>
      <Chip
        clickable={!disabled}
        onClick={disabled ? null : handleChipClick}
        icon={<FontAwesomeIcon icon={['fal', currentRole.icon]} />}
        label={lowerCase(currentRoleName)}
        sx={{ minWidth: '6rem' }}
      />
      {handleClick && (
        <Popover
          key={`popover-${membership?.org_id}-${membership?.user_id}`}
          id={id}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}>
          <List dense disablePadding>
            {Object.keys(ROLES).map((roleName) => {
              const role = ROLES[roleName];
              return (
                <ListItemButton
                  key={`list-item-${roleName}`}
                  disabled={value === Number(role.value)}
                  selected={value === Number(role.value)}
                  onClick={() => {
                    handleClick(membership, role.value);
                    handleClose();
                  }}>
                  <ListItemIcon sx={{ minWidth: 36 }}>
                    <FontAwesomeIcon icon={['fal', role.icon]} />
                  </ListItemIcon>
                  <ListItemText primary={lowerCase(roleName)} />
                </ListItemButton>
              );
            })}
          </List>
        </Popover>
      )}
    </>
  );
};

CustomPopover.propTypes = {
  value: PropTypes.number,
  row: PropTypes.object,
  handleClick: PropTypes.func,
  disabled: PropTypes.bool,
};

const MembershipRoleTypeProvider = (props) => {
  const { handleClick, disabled } = props;

  return (
    <DataTypeProvider
      formatterComponent={(innerProps) => {
        return (
          <CustomPopover
            {...innerProps}
            handleClick={handleClick}
            disabled={disabled}
          />
        );
      }}
      {...props}
    />
  );
};

MembershipRoleTypeProvider.propTypes = {
  handleClick: PropTypes.func,
  disabled: PropTypes.bool,
};

export { MembershipRoleTypeProvider };
