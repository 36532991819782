import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTheme } from '@emotion/react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import VeregyIcon from './VeregyIcon';

export default function HeadlineContent() {
  const theme = useTheme();
  return (
    <Card
      raised
      sx={(theme) => ({
        height: '100%',
        backgroundColor: theme.palette.auth.background,
      })}>
      <CardContent
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-around',
        }}>
        <Typography variant='h5' align='left' color={theme.palette.auth.text}>
          Illuminate your solar investment with real-time tracking, analytics,
          and fault detection.
        </Typography>
        <Box
          component='div'
          sx={{
            width: '100%',
            maxWidth: '400px',
            display: 'flex',
            justifyContent: 'space-around',
            alignSelf: 'center',
            alignItems: 'center',
          }}>
          <VeregyIcon height={100} name='Solar Panel' />
          <FontAwesomeIcon
            icon={['fal', 'long-arrow-right']}
            size='4x'
            color={theme.palette.grayAccent}
          />
          <VeregyIcon height={100} name='Dashboard' />
        </Box>
      </CardContent>
    </Card>
  );
}
