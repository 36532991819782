import { createSlice } from '@reduxjs/toolkit';
import { buildAsyncReducers } from '../thunkTemplate';
import { loggers as initialState } from '../initialState';

import {
  getLoggers,
  putLogger,
  handleSuccess,
  handleFailure,
} from './_loggers';

// NOTE: "Mutating" state is safe in redux toolkit because it uses Immer
const { reducer, actions } = createSlice({
  name: 'loggers',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    buildAsyncReducers(builder, [
      getLoggers,
      putLogger,
      handleSuccess,
      handleFailure,
    ]);
  },
});

// Extract each action creator by name
const { setLoggers } = actions;

// Export the reducer, either as a default or named export
export { getLoggers, putLogger, setLoggers, handleSuccess, handleFailure };
export default reducer;
