import { createSlice } from '@reduxjs/toolkit';

import { buildAsyncReducers } from '../thunkTemplate';
import { reports as initialState } from '../initialState';
import {
  getReportsData,
  postReport,
  putReport,
  deactivateReport,
  postUserReport,
  deleteUserReport,
} from './_reports';

const { reducer } = createSlice({
  name: 'reports',
  initialState,
  extraReducers: (builder) => {
    buildAsyncReducers(builder, [
      getReportsData,
      postReport,
      putReport,
      deactivateReport,
      postUserReport,
      deleteUserReport,
    ]);
  },
});

export {
  getReportsData,
  postReport,
  putReport,
  deactivateReport,
  postUserReport,
  deleteUserReport,
};
export default reducer;
