import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import find from 'lodash/find';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InputAdornment from '@mui/material/InputAdornment';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

import { ORGANIZATION, PORTFOLIO } from '../../../constants/resoureceTypes';
import { closeDialog } from '../../../store/dialogs';
import { postMembership } from '../../../store/memberships/_memberships';
import { defaultState as defaultOrgState } from '../../../store/hooks/useOrganization';
import SelectRole from '../../selectors/SelectRole';
import SelectUser from '../../selectors/SelectUser';
import BaseDialog from '../BaseDialog';
import { TextFieldListItem } from '../../List/TextFieldListItem';

const defaultState = {
  name: '',
  email: '',
  org_id: '',
  user_id: '',
  role: 2,
};
function MembershipDialog() {
  const dispatch = useDispatch();
  const organizations = useSelector((state) => state.organizations.data);
  const { id, orgId } = useSelector((state) => state.dialogs.membership);
  const [organization, setOrganization] = useState(defaultOrgState);
  const [membership, setMembership] = useState(defaultState);

  useEffect(() => {
    if (orgId) {
      const org = find(organizations, { org_id: orgId });
      setOrganization(org);
      setMembership((prevMembership) => ({ ...prevMembership, org_id: orgId }));
    }
    return () => {
      setMembership(defaultState);
    };
  }, [orgId, organizations]);

  const handleSelectUser = (user) => {
    setMembership((prevMembership) => ({
      ...prevMembership,
      user_id: user?.user_id || '',
      email: user?.email || '',
      name: user?.name || '',
    }));
  };

  const handleClose = () => {
    dispatch(closeDialog('membership'));
    setMembership(defaultState);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    handleClose();
    dispatch(postMembership(membership));
  };

  const icon = (() => {
    if (organization.is_portfolio) return PORTFOLIO.icon;
    return ORGANIZATION.icon;
  })();
  return (
    <BaseDialog
      title='Create Membership'
      id={id}
      disabled={false}
      handleClose={handleClose}
      handleSubmit={handleSubmit}>
      <List disablePadding>
        <TextFieldListItem
          label={organization.is_portfolio ? 'Portfolio' : 'Organization'}
          value={organization.name}
          InputProps={{
            readOnly: true,
            startAdornment: (
              <InputAdornment position='start' sx={{ mr: 4 }}>
                <FontAwesomeIcon icon={['fal', icon]} />
              </InputAdornment>
            ),
          }}
        />
        <ListItem disableGutters>
          <SelectUser
            orgId={membership.org_id}
            userId={membership.user_id}
            setUser={handleSelectUser}
          />
        </ListItem>
        <ListItem disableGutters>
          <SelectRole
            email={membership.email}
            role={membership.role}
            setRole={(role) => setMembership({ ...membership, role: role })}
          />
        </ListItem>
      </List>
    </BaseDialog>
  );
}

export default MembershipDialog;
