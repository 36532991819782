import React from 'react';

import { useTheme } from '@emotion/react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';

export default function BenefitItem(props) {
  const { title, icon, children } = props;
  const theme = useTheme();

  return (
    <Card
      raised
      sx={(theme) => ({
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        backgroundColor: theme.palette.auth.background,
      })}>
      <CardHeader
        title={title}
        titleTypographyProps={{
          variant: 'h6',
          align: 'left',
          color: theme.palette.auth.text,
          fontWeight: 'bold',
        }}
        sx={{ p: 1, pl: 2 }}
      />
      <CardContent sx={{ pt: 0, pl: 2, pr: 2, pb: '0 !important' }}>
        <Typography
          variant='body2'
          align='left'
          color={(theme) => theme.palette.auth.text}>
          {children}
        </Typography>
      </CardContent>
      <CardActions
        sx={{
          '&.MuiCardActions-root': {
            justifyContent: 'end',
            flexDirection: 'column',
            width: '100%',
            alignItems: 'center',
          },
        }}>
        {icon}
      </CardActions>
    </Card>
  );
}
