import React from 'react';
import PropTypes from 'prop-types';

import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

function ChartHeader({ children }) {
  return (
    <Grid item xs={12}>
      <Card raised sx={{ px: 1, py: 0.5 }}>
        <Stack
          direction='row'
          justifyContent='space-evenly'
          alignItems='center'
          divider={
            <Divider orientation='vertical' flexItem sx={{ mt: 1, mb: 1 }} />
          }>
          {children}
        </Stack>
      </Card>
    </Grid>
  );
}

ChartHeader.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ChartHeader;
