import React from 'react';
import { useSelector } from 'react-redux';
import find from 'lodash/find';
import isString from 'lodash/isString';

import { DataTypeProvider } from '@devexpress/dx-react-grid';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';

import ROLES from '../../../constants/roles';

export const AlarmConfigCheckBoxTypeProvider = (props) => {
  const { handleClick } = props;
  const { memberships } = useSelector((state) => state.user);

  return (
    <DataTypeProvider
      formatterComponent={(attrs) => {
        const { value, row, column } = attrs;
        const membership = find(memberships, { org_id: row?.org_id });
        const isOrgEditor = membership?.role <= ROLES.EDITOR.value;

        if (isOrgEditor || column.name === 'subscription_id') {
          return (
            <Checkbox
              onClick={() => handleClick(attrs)}
              checked={isString(value)}
            />
          );
        } else {
          return (
            <Tooltip title='Insufficient Permissions' placement='left'>
              <div>
                <Checkbox
                  onClick={() => handleClick(attrs)}
                  checked={isString(value)}
                  disabled
                />
              </div>
            </Tooltip>
          );
        }
      }}
      {...props}
    />
  );
};
