import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { push } from 'redux-first-history';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { logoutUser } from '../../store/user';

const AUTH_URL = process.env.REACT_APP_AUTH_URL;
const CLIENT_ID = process.env.REACT_APP_USER_POOL_WEBCLIENT_ID;

const Div = styled('div')(() => {
  return {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '6px',
  };
});

export default function AuthenticationLinks() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.item);

  const handleLogInClick = (e) => {
    e.preventDefault();
    const url = `${AUTH_URL}login?client_id=${CLIENT_ID}`;
    window.location.href = url;
  };

  const handleSignUpClick = (e) => {
    e.preventDefault();
    const url = `${AUTH_URL}signup?client_id=${CLIENT_ID}`;
    window.location.href = url;
  };

  return (
    <Card
      raised
      sx={(theme) => ({ backgroundColor: theme.palette.auth.background })}>
      <CardHeader
        disableTypography
        title={
          <Box
            component='img'
            sx={{ height: 80, display: 'flex', alignSelf: 'flex-start', p: 1 }}
            alt='LightLevel by Veregy'
            src='/images/LightLevel_by_Veregy.svg'
            title='LightLevel'
          />
        }
      />
      <CardContent sx={{ pb: '8px !important' }}>
        <Typography
          variant='h5'
          align='left'
          color={(theme) => theme.palette.auth.text}>
          Real-time insights to power a sustainable future.
        </Typography>
      </CardContent>
      {user ? (
        <CardActions
          sx={{
            '&.MuiCardActions-root': {
              flexDirection: 'column',
              width: '100%',
              alignItems: 'end',
              pr: 2,
              pb: 2,
              pt: 0,
            },
          }}>
          <Button
            type='submit'
            color='secondary'
            variant='contained'
            onClick={(e) => {
              e.preventDefault();
              dispatch(push('/app/portfolio'));
            }}
            sx={{ width: '40%' }}>
            Dashboard
          </Button>
          <Div>
            <Typography
              variant='body2'
              sx={{ mr: '4px' }}
              color={(theme) => theme.palette.auth.text}>
              Logged in as {user.email}
            </Typography>
            <Link
              underline='hover'
              onClick={(e) => {
                e.preventDefault();
                dispatch(logoutUser());
              }}
              color={(theme) => theme.palette.auth.text}
              fontWeight='bold'
              variant='body2'>
              Log out
            </Link>
          </Div>
        </CardActions>
      ) : (
        <CardActions
          sx={{
            '&.MuiCardActions-root': {
              flexDirection: 'column',
              width: '100%',
              alignItems: 'end',
              pr: 2,
              pb: 2,
              pt: 0,
            },
          }}>
          <Button
            type='submit'
            color='secondary'
            variant='contained'
            onClick={handleLogInClick}
            sx={{ width: '40%' }}>
            Log in
          </Button>
          <Div>
            <Typography
              variant='body2'
              sx={{ mr: '4px' }}
              color={(theme) => theme.palette.auth.text}>
              Need an account?
            </Typography>
            <Link
              underline='hover'
              onClick={handleSignUpClick}
              color={(theme) => theme.palette.auth.text}
              fontWeight='bold'
              variant='body2'>
              Sign Up
            </Link>
          </Div>
        </CardActions>
      )}
    </Card>
  );
}
