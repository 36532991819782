import React from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';

import MembershipsTable from '../../../../components/table/tables/MembershipsTable';

export default function Memberships() {
  const { id: portfolioId } = useSelector((state) => state.pages.portfolio);

  return (
    <Box
      sx={{
        mt: 1,
        px: 1,
        mb: 6,
        display: 'flex',
        justifyContent: 'flex-start',
        maxWidth: '96vw',
      }}>
      <MembershipsTable orgId={portfolioId} />
    </Box>
  );
}
