import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconButton from '@mui/material/IconButton';

function SettingsIcon(props) {
  const { isOrgEditor, link, handleClick } = props;
  if (!isOrgEditor || !('type' in link)) return null;
  return (
    <IconButton
      key='resource-settings-button'
      onClick={() => handleClick(link)}
      sx={{ color: 'white', ml: 1 }}>
      <FontAwesomeIcon icon={['fal', 'cog']} size='sm' />
    </IconButton>
  );
}

export default SettingsIcon;
