import { useSelector } from 'react-redux';
import find from 'lodash/find';
import includes from 'lodash/includes';
import toLower from 'lodash/toLower';

const useDeviceNamePredicate = () => {
  const sites = useSelector((state) => state.sites.data);
  const meters = useSelector((state) => state.meters.data);
  const inverters = useSelector((state) => state.inverters.data);
  const sensors = useSelector((state) => state.sensors.data);

  const findDevice = (deviceId) => {
    switch (deviceId.split(':')[0]) {
      case 'site':
        return find(sites, { site_id: deviceId });
      case 'meter':
        return find(meters, { meter_id: deviceId });
      case 'inverter':
        return find(inverters, { inverter_id: deviceId });
      case 'sensor':
        return find(sensors, { sensor_id: deviceId });
      default:
        return null;
    }
  };

  return (value, filter) => {
    const device = findDevice(value);
    return includes(toLower(device?.name), toLower(filter.value));
  };
};

export default useDeviceNamePredicate;
