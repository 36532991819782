import React, { useEffect, useState } from 'react';
import isNull from 'lodash/isNull';
import isUndefined from 'lodash/isUndefined';
import PropTypes from 'prop-types';

import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { useTheme } from '@emotion/react';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';

const FORCE_ACTIVE = {
  text: 'force_active',
  checked: true,
  message: 'Force Active',
};
const FORCE_INACTIVE = {
  text: 'force_inactive',
  checked: false,
  message: 'Force Inactive',
};
const ACTIVE = {
  text: 'active',
  checked: true,
  message: 'Active Stripe subscription',
};
const INACTIVE = {
  text: 'inactive',
  checked: false,
  message: 'Inactive Stripe subscription',
};

const CustomCheckboxPopover = (props) => {
  const { row: license } = props;
  const theme = useTheme();
  const [color, setColor] = useState('');
  const [value, setValue] = useState(INACTIVE);

  useEffect(() => {
    if (license.force_active === true) {
      // FORCE ACTIVE
      setValue(FORCE_ACTIVE);
      setColor(theme.palette.primary.main);
    } else if (license.force_active === false) {
      // FORCE INACTIVE
      setValue(FORCE_INACTIVE);
      setColor(theme.palette.primary.main);
    } else if (license.active === true) {
      // ACTIVE
      setValue(ACTIVE);
      setColor(theme.palette.skeleton);
    } else if (
      license.active === false ||
      isNull(license.active) ||
      isUndefined(license.active)
    ) {
      // INACTIVE
      setValue(INACTIVE);
      setColor(theme.palette.skeleton);
    }
  }, [license, theme]);

  return (
    <>
      <Tooltip placement='left' title={value.message}>
        <Checkbox
          checked={value.checked}
          sx={{
            color,
            '&.Mui-checked': { color },
          }}
        />
      </Tooltip>
    </>
  );
};

CustomCheckboxPopover.propTypes = {
  row: PropTypes.object,
};

export const SelectTypeProvider = (props) => {
  return (
    <DataTypeProvider
      formatterComponent={(innerProps) => {
        return <CustomCheckboxPopover {...innerProps} />;
      }}
      {...props}
    />
  );
};
