import React from 'react';
import isNull from 'lodash/isNull';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import Checkbox from '@mui/material/Checkbox';

export const CheckBoxTypeProvider = (props) => {
  const { handleClick = () => {} } = props;
  return (
    <DataTypeProvider
      formatterComponent={(props) => {
        const { value, row } = props;
        return (
          <Checkbox
            checked={value}
            disabled={isNull(value)}
            onClick={() => handleClick(row)}
          />
        );
      }}
      {...props}
    />
  );
};
