import React from 'react';
import { useSelector } from 'react-redux';
import get from 'lodash/get';

import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import TextField from '@mui/material/TextField';

import { dateStringToDayJS } from '../../../helpers/date';
import ClipboardField from '../../../components/ClipboardField';

const Field = (props) => {
  return (
    <ListItem>
      <TextField
        variant='standard'
        sx={{ width: '100%' }}
        InputProps={{ readOnly: true, disableUnderline: true }}
        {...props}
      />
    </ListItem>
  );
};

const APIInfo = () => {
  const { item: user } = useSelector((state) => state.user);

  return (
    <Grid item xs={12} display='flex' justifyContent='center'>
      <Card raised sx={{ p: 1, maxWidth: 650, width: '100%' }}>
        <List disablePadding>
          <ListItem>
            <ClipboardField
              id='api-key'
              label='API Key'
              value={user.api.key}
              clipboardPosition='start'
            />
          </ListItem>
          <Field
            id='invocations'
            label='Invocations'
            defaultValue={user.api.invocations}
          />
          <Field
            id='key-generation-date'
            label='Key Generation Date'
            defaultValue={dateStringToDayJS(
              get(user, 'api.key_generation_datetime')
            ).format('MM/DD/YYYY HH:mm:ss Z')}
          />
          <Field
            id='last-invocation-date'
            label='Last Invocation Date'
            defaultValue={dateStringToDayJS(
              get(user, 'api.last_invocation_datetime')
            ).format('MM/DD/YYYY HH:mm:ss Z')}
          />
        </List>
      </Card>
    </Grid>
  );
};

export default APIInfo;
