import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';

import FormControl from '@mui/material/FormControl';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';

import ROLES from '../../../constants/roles';
import { closeDialog } from '../../../store/dialogs';
import { putSensor } from '../../../store/sensors';
import useSensor, { defaultState } from '../../../store/hooks/useSensor';
import useVerifyOrgRole from '../../../store/hooks/useVerifyOrgRole';
import BaseDialog from '../BaseDialog';
import { TextFieldListItem } from '../../List/TextFieldListItem';
import ClipboardField from '../../ClipboardField';

function SensorDialog() {
  const dispatch = useDispatch();

  const { id } = useSelector((state) => state.dialogs.sensor);
  const sensorItem = useSensor(id);
  const isOrgEditor = useVerifyOrgRole(sensorItem?.org_id, ROLES.EDITOR.value);

  const [sensor, setSensor] = useState(defaultState);

  useEffect(() => {
    setSensor(sensorItem);
  }, [sensorItem]);

  const handleChange = (e) => {
    setSensor((prevSensor) => ({
      ...prevSensor,
      [e.target.id]: e.target.value,
    }));
  };

  const handleClose = () => {
    dispatch(closeDialog('sensor'));
    setSensor(defaultState);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(putSensor(sensor));
    handleClose();
  };

  return (
    <BaseDialog
      id={id}
      title='Update Sensor'
      disabled={!isOrgEditor}
      handleSubmit={handleSubmit}
      handleClose={handleClose}>
      <form
        onSubmit={handleSubmit}
        onKeyDown={(event) => {
          if (event?.key === 'Enter') {
            handleSubmit(event);
          }
        }}>
        <List disablePadding>
          <ListItem disableGutters>
            <ClipboardField
              id='sensor-id-value'
              value={sensor.sensor_id}
              label='Sensor ID'
            />
          </ListItem>
          <TextFieldListItem
            id='name'
            label={`Sensor ${sensor.parent_index} Name`}
            value={sensor.name}
            onChange={handleChange}
          />
          <ListItem disableGutters>
            <FormControl id='orientation' variant='standard' fullWidth>
              <InputLabel id='orientation-label'>Orientation</InputLabel>
              <Select
                labelId='orientation-label'
                name='orientation'
                value={get(sensor, 'orientation', '')}
                onChange={handleChange}
                MenuProps={{
                  MenuListProps: { disablePadding: true },
                  slotProps: {
                    paper: { sx: { minWidth: 'unset !important' } },
                  },
                }}
                sx={{
                  backgroundColor: 'unset',
                  '& .MuiSelect-select': {
                    display: 'flex',
                    alignItems: 'center',
                  },
                }}>
                <MenuItem value=''>None</MenuItem>
                {['Plane of Array', 'Global Horizontal Irradiance'].map(
                  (option) => {
                    return (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    );
                  }
                )}
              </Select>
            </FormControl>
          </ListItem>
          <ListItem
            disableGutters
            sx={{ display: 'flex', justifyContent: 'space-around' }}>
            <TextField
              type='number'
              variant='standard'
              id='tilt'
              label='Tilt'
              value={get(sensor, 'tilt', '')}
              onChange={handleChange}
              sx={{ width: '45%' }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>°</InputAdornment>
                ),
              }}
            />
            <TextField
              type='number'
              variant='standard'
              id='azimuth'
              label='Azimuth'
              value={get(sensor, 'azimuth', '')}
              onChange={handleChange}
              sx={{ width: '45%' }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>°</InputAdornment>
                ),
              }}
            />
          </ListItem>
        </List>
      </form>
    </BaseDialog>
  );
}

export default SensorDialog;
