import { createAsyncThunk } from '@reduxjs/toolkit';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { toastr } from 'react-redux-toastr';
import cloneDeep from 'lodash/cloneDeep';
import concat from 'lodash/concat';
import get from 'lodash/get';
import remove from 'lodash/remove';

import WebAPIClient, { errorResponseToastr } from '../../api';

const getMemberships = createAsyncThunk(
  'memberships/getMemberships',
  async () => {
    try {
      const memberships = await new WebAPIClient().GET('/resource/memberships');
      return { data: memberships };
    } catch (err) {
      console.error(err);
    }
  }
);

const postMembership = createAsyncThunk(
  'memberships/postMembership',
  async (membership, { dispatch, getState }) => {
    const { data: memberships } = getState().memberships;
    let _memberships = cloneDeep(memberships);

    try {
      dispatch(showLoading());
      const { membership: newMembership } = await new WebAPIClient().POST(
        '/resource/memberships',
        membership
      );

      _memberships = concat(_memberships, newMembership);
      toastr.success('Membership created');
      return { data: _memberships };
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

const putMembership = createAsyncThunk(
  'memberships/putMembership',
  async (meter, { dispatch, getState }) => {
    const { data: memberships } = getState().memberships;
    let _memberships = cloneDeep(memberships);

    try {
      dispatch(showLoading());
      let updatedMembership = await new WebAPIClient().PUT(
        `/resource/memberships/${meter.org_id}/${meter.user_id}`,
        meter
      );

      remove(_memberships, {
        org_id: get(updatedMembership, 'org_id'),
        user_id: get(updatedMembership, 'user_id'),
      });

      toastr.success('Membership role updated');
      return { data: concat(_memberships, updatedMembership) };
    } catch (err) {
      toastr.error('Error', get(err, 'message', err));
    } finally {
      dispatch(hideLoading());
    }
  }
);

const deleteMembership = createAsyncThunk(
  'memberships/deleteMembership',
  async (membership, { dispatch, getState }) => {
    const { data: memberships } = getState().memberships;
    let _memberships = cloneDeep(memberships);
    const org_id = get(membership, 'org_id');
    const user_id = get(membership, 'user_id');

    try {
      dispatch(showLoading());
      await new WebAPIClient().DELETE(
        `/resource/memberships/${org_id}/${user_id}`
      );

      remove(_memberships, {
        org_id: get(membership, 'org_id'),
        user_id: get(membership, 'user_id'),
      });

      toastr.success('Membership deleted');
      return { data: _memberships };
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

export { getMemberships, postMembership, putMembership, deleteMembership };
