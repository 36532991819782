import dayjs from 'dayjs';
import concat from 'lodash/concat';
import compact from 'lodash/compact';
import get from 'lodash/get';
import find from 'lodash/find';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import times from 'lodash/times';

export const thisMonthActual = (meters) => {
  const today = dayjs();

  return reduce(
    meters,
    (acc, meter) => {
      let actual =
        get(
          find(get(meter, 'energy_stats.production', []), {
            year: today.year(),
            month: today.month() + 1,
          }),
          'value',
          0
        ) +
        get(
          find(get(meter, 'energy_stats.adjusted_production', []), {
            year: today.year(),
            month: today.month() + 1,
          }),
          'value',
          0
        );

      return acc + actual + get(meter, 'energy_stats.today', 0);
    },
    0
  );
};

export const thisMonthExpected = (expectations) => {
  const today = dayjs();
  const proRate = (today.date() - 1) / today.daysInMonth();

  return (
    reduce(
      expectations,
      (acc, siteExpectations) => {
        let expected = get(
          find(siteExpectations, {
            year: today.year(),
            month: today.month() + 1,
          }),
          'value',
          0
        );
        return acc + expected;
      },
      0
    ) * proRate
  );
};

export const lastMonthSum = (summaries) => {
  const lastMonthDayJS = dayjs().subtract(1, 'month');
  return reduce(
    summaries,
    (acc, summary) => {
      let value = get(
        find(summary, {
          year: lastMonthDayJS.year(),
          month: lastMonthDayJS.month() + 1,
        }),
        'value',
        0
      );
      return acc + value;
    },
    0
  );
};

export const trailing12MonthSum = (summaries) => {
  const lastMonth = dayjs().subtract(1, 'month');
  return reduce(
    summaries,
    (acc, summary) => {
      let value = 0;
      let currentMonth = lastMonth;
      times(12, () => {
        value += get(
          find(summary, {
            year: currentMonth.year(),
            month: currentMonth.month() + 1,
          }),
          'value',
          0
        );
        currentMonth = currentMonth.subtract(1, 'month');
      });
      return acc + value;
    },
    0
  );
};

export const allTimeSum = (summaries) => {
  // does not include current month
  const today = dayjs();
  return reduce(
    summaries,
    (acc, summary) => {
      return (
        acc +
        reduce(
          summary,
          (acc, datapoint) => {
            let date = dayjs(
              `${get(datapoint, 'year')}-${get(datapoint, 'month')}-15`
            );

            if (date.isSame(today, 'month') || date.isAfter(today, 'month')) {
              return acc;
            }
            return acc + get(datapoint, 'value', 0);
          },
          0
        )
      );
    },
    0
  );
};

export const compilePerformanceIndexes = (meters, expectations) => {
  const recordedProductions = map(meters, (meter) =>
    get(meter, 'energy_stats.production', [])
  );
  const adjustedProductions = compact(
    map(meters, (meter) => get(meter, 'energy_stats.adjusted_production', null))
  );

  const thisMonth = {
    actual: thisMonthActual(meters),
    expected: thisMonthExpected(expectations),
  };

  const lastMonth = {
    actual: lastMonthSum(concat(recordedProductions, adjustedProductions)),
    expected: lastMonthSum(expectations),
  };

  const trailing12Month = {
    actual: trailing12MonthSum(
      concat(recordedProductions, adjustedProductions)
    ),
    expected: trailing12MonthSum(expectations),
  };

  const allTime = {
    actual:
      allTimeSum(concat(recordedProductions, adjustedProductions)) +
      thisMonth.actual,
    expected: allTimeSum(expectations) + thisMonth.expected,
  };

  return {
    thisMonth,
    lastMonth,
    trailing12Month,
    allTime,
  };
};

export const monthPerformanceData = (date, expectations) => {
  return reduce(
    expectations,
    (acc, expectationArr) => {
      const monthData = find(expectationArr, {
        year: date.year(),
        month: date.month() + 1,
      });

      return {
        actual: acc.actual + get(monthData, 'actual', 0),
        expected: acc.expected + get(monthData, 'expected', 0),
      };
    },
    { actual: 0, expected: 0 }
  );
};

export const compileCorrectedPerformanceIndexes = (expectations) => {
  const today = dayjs();
  const thisMonth = monthPerformanceData(today, expectations);

  const lastMonthDate = today.subtract(1, 'month');
  const lastMonth = monthPerformanceData(lastMonthDate, expectations);

  const trailing12Month = reduce(
    expectations,
    (acc, expectationArr) => {
      let actual = 0;
      let expected = 0;
      let currentMonth = lastMonthDate;
      times(12, () => {
        const monthData = find(expectationArr, {
          year: currentMonth.year(),
          month: currentMonth.month() + 1,
        });
        actual += get(monthData, 'actual', 0);
        expected += get(monthData, 'expected', 0);
        currentMonth = currentMonth.subtract(1, 'month');
      });
      return {
        actual: acc.actual + actual,
        expected: acc.expected + expected,
      };
    },
    { actual: 0, expected: 0 }
  );

  const allTime = reduce(
    expectations,
    (acc1, expectationArr) => {
      let res = reduce(
        expectationArr,
        (acc2, monthData) => {
          return {
            actual: acc2.actual + get(monthData, 'actual', 0),
            expected: acc2.expected + get(monthData, 'expected', 0),
          };
        },
        { actual: 0, expected: 0 }
      );
      return {
        actual: acc1.actual + get(res, 'actual', 0),
        expected: acc1.expected + get(res, 'expected', 0),
      };
    },
    { actual: 0, expected: 0 }
  );

  return {
    thisMonth,
    lastMonth,
    trailing12Month,
    allTime,
  };
};
