import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import find from 'lodash/find';

import { displaykW } from '../../../../helpers/display-energy';
import BaseTooltip from '../../tooltips/BaseTooltip';

function Tooltip({ active, payload, label, timezone }) {
  const { data: meters } = useSelector((state) => state.meters);

  const meterName = (resource) => {
    const estimated = resource.dataKey.endsWith(':estimated');
    const meterId = estimated
      ? resource.dataKey.replace(':estimated', '')
      : resource.dataKey;
    const meter = find(meters, { meter_id: meterId });
    return meter?.name;
  };

  return (
    <BaseTooltip
      active={active}
      payload={payload}
      label={dayjs
        .unix(Number(label))
        .tz(timezone.zone)
        .format('MMM Do h:mm A')}
      getName={meterName}
      getValue={(resource) => displaykW(resource?.value)}
    />
  );
}

Tooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  label: PropTypes.number,
  timezone: PropTypes.object,
  meters: PropTypes.array,
};

export default Tooltip;
