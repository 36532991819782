import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useTheme from '@mui/material/styles/useTheme';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import ROUTES from '../../../constants/routes';

function LastUpdateItem() {
  const theme = useTheme();
  const location = useLocation();

  const {
    websocket: { lastUpdate, orgId },
  } = useSelector((state) => state.pages);

  const [show, setShow] = useState(false);
  const [timeSinceLastUpdate, setTimeSinceLastUpdate] = useState('');
  const [intervalSeconds] = useState(15000);

  useEffect(() => {
    if (location.pathname === ROUTES.AUTH.PORTFOLIO) {
      setShow(false);
    } else {
      setShow(true);
    }
  }, [location.pathname]);

  // use interval to update timeSinceLastUpdate every 15 seconds
  useEffect(() => {
    const updateTime = () => {
      const now = dayjs();
      if (lastUpdate) {
        const seconds = now.diff(lastUpdate, 'seconds');
        if (seconds < 60) {
          setTimeSinceLastUpdate(`${seconds}s`);
        } else if (seconds < 3600) {
          setTimeSinceLastUpdate(`${Math.floor(seconds / 60)}m`);
        } else {
          setTimeSinceLastUpdate(`${Math.floor(seconds / 3600)}h`);
        }
      }
    };

    // Run immediately
    updateTime();

    // Set up interval to run every 30 seconds
    const interval = setInterval(updateTime, intervalSeconds);

    // Clean up interval on unmount
    return () => clearInterval(interval);
  }, [lastUpdate, intervalSeconds]);

  if (!show) return null;

  const icon = orgId ? 'link-horizontal' : 'link-horizontal-slash';
  const color = orgId ? theme.palette.primary.main : theme.palette.error.main;
  const message = orgId
    ? `Last update ${timeSinceLastUpdate} ago`
    : 'Disconnected';

  return (
    <ListItem divider={true}>
      <ListItemIcon style={{ minWidth: '34px' }}>
        <FontAwesomeIcon icon={['fal', icon]} size='lg' style={{ color }} />
      </ListItemIcon>
      <ListItemText
        primary={message}
        primaryTypographyProps={{ align: 'left', color }}
      />
    </ListItem>
  );
}

export default LastUpdateItem;
