import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { updateNodes } from '../nodes';

import includes from 'lodash/includes';

const useNodes = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { loading: userLoading } = useSelector((state) => state.user);
  const nodes = useSelector((state) => state.nodes.data);
  const { data: licenses, licensesLoading } = useSelector(
    (state) => state.licenses
  );
  const {
    data: organizations,
    portfolioMemberships,
    loading: orgLoading,
  } = useSelector((state) => state.organizations);
  const { data: sites, loading: siteLoading } = useSelector(
    (state) => state.sites
  );
  const { data: loggers, loading: loggerLoading } = useSelector(
    (state) => state.loggers
  );
  const { data: meters, loading: meterLoading } = useSelector(
    (state) => state.meters
  );
  const { data: inverters, loading: inverterLoading } = useSelector(
    (state) => state.inverters
  );
  const { data: sensors, loading: sensorLoading } = useSelector(
    (state) => state.sensors
  );

  const [firstLoad, setFirstLoad] = useState(false);

  useEffect(() => {
    let nav = false;
    if (includes(location.search, '?refreshToken=')) {
      nav = true;
    }

    if (
      organizations.length > 0 &&
      !userLoading &&
      !orgLoading &&
      !siteLoading &&
      !loggerLoading &&
      !meterLoading &&
      !inverterLoading &&
      !sensorLoading &&
      !licensesLoading &&
      !firstLoad
    ) {
      dispatch(
        updateNodes({
          organizations,
          portfolioMemberships,
          sites,
          licenses,
          loggers,
          meters,
          inverters,
          sensors,
          nav,
        })
      );
      setFirstLoad(true);
    }
  }, [
    dispatch,
    organizations,
    portfolioMemberships,
    sites,
    licenses,
    loggers,
    meters,
    inverters,
    sensors,
    userLoading,
    orgLoading,
    siteLoading,
    licensesLoading,
    loggerLoading,
    meterLoading,
    inverterLoading,
    sensorLoading,
    firstLoad,
    location.search,
  ]);

  return nodes;
};

export default useNodes;
