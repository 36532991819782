import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import { InfoWindowF } from '@react-google-maps/api';

import PerformanceIndexChart from '../charts/PerformanceIndexChart';
import { displaykW } from '../../helpers/display-energy';
import dayjs from 'dayjs';
import { Typography } from '@mui/material';

const getResourceId = (resource) => {
  if (resource.type_ === 'organization') {
    return resource.org_id;
  } else return get(resource, `${resource.type_}_id`);
};

const InfoWindow = (props) => {
  const {
    resource,
    generation,
    capacity,
    actual,
    expected,
    markerMap,
    onClose,
    onClick,
  } = props;

  return (
    <InfoWindowF
      key={resource.org_id}
      onCloseClick={() => onClose(resource)}
      anchor={markerMap[getResourceId(resource)]}>
      <List
        disablePadding
        subheader={
          <Link onClick={() => onClick(resource)}>
            <Typography variant='h6' align='center'>
              {resource.name}
            </Typography>
          </Link>
        }>
        <ListItem disablePadding>
          <ListItemText
            primaryTypographyProps={{
              align: 'left',
              variant: 'body2',
            }}>
            {resource.address}
          </ListItemText>
        </ListItem>
        <Divider />
        <ListItem disablePadding>
          <ListItemText
            primaryTypographyProps={{
              align: 'left',
            }}>
            Current Generation
          </ListItemText>
          <ListItemText
            primaryTypographyProps={{
              align: 'right',
            }}>
            {displaykW(generation)} / {displaykW(capacity)}
          </ListItemText>
        </ListItem>
        <ListItem disablePadding>
          <ListItemText
            primaryTypographyProps={{
              align: 'left',
            }}>
            {dayjs().format('MMMM')} Performance
          </ListItemText>
          <PerformanceIndexChart
            actual={actual}
            expected={expected}
            width={80}
          />
        </ListItem>
      </List>
    </InfoWindowF>
  );
};

InfoWindow.propTypes = {
  resource: PropTypes.object,
  generation: PropTypes.number,
  capacity: PropTypes.number,
  actual: PropTypes.number,
  expected: PropTypes.number,
  mapMarker: PropTypes.object,
  onClose: PropTypes.func,
  onClick: PropTypes.func,
};

export default InfoWindow;
