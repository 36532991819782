import { createSlice } from '@reduxjs/toolkit';

import { buildAsyncReducers } from '../thunkTemplate';
import { images as initialState } from '../initialState';
import {
  getImages,
  postImage,
  renameImage,
  deleteImage,
  getPresignedUrl,
} from './_images';

const { reducer } = createSlice({
  name: 'images',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    buildAsyncReducers(builder, [
      getImages,
      postImage,
      renameImage,
      deleteImage,
      getPresignedUrl,
    ]);
  },
});

export { getImages, postImage, renameImage, deleteImage, getPresignedUrl };
export default reducer;
