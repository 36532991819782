import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import PropTypes from 'prop-types';

import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function GenerateCSVButton(props) {
  const { generateCsvString, filename } = props;
  const dispatch = useDispatch();
  const [csvString, setCsvString] = useState([]);

  useEffect(() => {
    if (csvString.length > 0) {
      let myBlob = new Blob([csvString], { type: 'text/csv' });

      let url = window.URL.createObjectURL(myBlob);
      let anchor = document.createElement('a');
      anchor.href = url;
      anchor.download = filename + '.csv';

      anchor.click();
      window.URL.revokeObjectURL(url);
      anchor.remove();
      setCsvString('');
    }
  }, [csvString, filename]);

  const handleClick = (event) => {
    event.preventDefault();
    dispatch(showLoading());
    setCsvString(generateCsvString());
    dispatch(hideLoading());
  };

  return (
    <Tooltip title='Download CSV' placement='top'>
      <IconButton onClick={handleClick}>
        <FontAwesomeIcon icon={['fal', 'arrow-to-bottom']} size='sm' />
      </IconButton>
    </Tooltip>
  );
}

GenerateCSVButton.propTypes = {
  generateCsvString: PropTypes.func,
  filename: PropTypes.string,
};

export default GenerateCSVButton;
