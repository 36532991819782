import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import find from 'lodash/find';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';

import {
  FilteringState,
  IntegratedPaging,
  PagingState,
  SortingState,
} from '@devexpress/dx-react-grid';

import { closeDialog } from '../../store/dialogs';
import BaseTable from '../table/tables/BaseTable';
import BaseDialog from './BaseDialog';

function EmailedUsersDialog() {
  const dispatch = useDispatch();

  const { alarm } = useSelector((state) => state.dialogs.emailedUsers);
  const allUsers = useSelector((state) => state.admin.users);
  const [users, setUsers] = useState([]);
  const [columns] = useState([
    { title: 'Name', name: 'name', width: 0.45 },
    { title: 'Email', name: 'email', width: 0.45 },
  ]);

  useEffect(() => {
    if (!isEmpty(alarm)) {
      const _users = map(alarm?.emailed_users, (user_id) => {
        return find(allUsers, { user_id: user_id });
      });
      setUsers(_users);
    }
  }, [alarm, allUsers]);

  const handleClose = () => {
    dispatch(closeDialog('emailedUsers'));
  };

  return (
    <BaseDialog
      id={get(alarm, 'alarm_id', '')}
      title='Emailed Users'
      handleClose={handleClose}>
      <BaseTable rows={users} columns={columns}>
        <FilteringState />
        <SortingState
          defaultSorting={[{ columnName: 'name', direction: 'asc' }]}
        />
        <PagingState defaultCurrentPage={0} pageSize={10} />
        <IntegratedPaging />
      </BaseTable>
    </BaseDialog>
  );
}

export default EmailedUsersDialog;
