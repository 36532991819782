import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import concat from 'lodash/concat';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';

const useLoggerDevices = (loggerId) => {
  const { data: allAlarms } = useSelector((state) => state.alarms);
  const { data: allMeters, loading: meterLoading } = useSelector(
    (state) => state.meters
  );
  const { data: allInverters, loading: inverterLoading } = useSelector(
    (state) => state.inverters
  );
  const { data: allSensors, loading: sensorLoading } = useSelector(
    (state) => state.sensors
  );

  const [alarms, setAlarms] = useState([]);
  const [meters, setMeters] = useState([]);
  const [meterIds, setMeterIds] = useState([]);
  const [inverters, setInverters] = useState([]);
  const [sensors, setSensors] = useState([]);

  useEffect(() => {
    let deviceIds = concat(
      loggerId,
      meterIds,
      map(inverters, (inverter) => inverter.inverter_id),
      map(sensors, (sensor) => sensor.sensor_id)
    );
    setAlarms(
      filter(allAlarms, (alarm) => includes(deviceIds, alarm.device_id))
    );
  }, [loggerId, meterIds, inverters, sensors, allAlarms]);

  useEffect(() => {
    const _meters = filter(allMeters, { logger_id: loggerId });
    setMeters(_meters);
    setMeterIds(map(_meters, (meter) => meter.meter_id));
  }, [allMeters, loggerId]);

  useEffect(() => {
    let _inverters = filter(allInverters, (inverter) =>
      includes(meterIds, inverter.meter_id)
    );
    setInverters(sortBy(_inverters, 'parent_index'));
  }, [allInverters, meterIds]);

  useEffect(() => {
    setSensors(
      filter(allSensors, (sensor) => includes(meterIds, sensor.meter_id))
    );
  }, [allSensors, meterIds]);

  return {
    meters,
    inverters,
    sensors,
    alarms,
    loading: meterLoading || inverterLoading || sensorLoading,
  };
};

export default useLoggerDevices;
