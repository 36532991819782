import find from 'lodash/find';
import { useSelector } from 'react-redux';

const useCompareReportName = () => {
  const reports = useSelector((state) => state.reports.data);

  return (reportId1, reportId2) => {
    const report1 = find(reports, { report_id: reportId1 });
    const report2 = find(reports, { report_id: reportId2 });

    return report1?.name < report2?.name ? -1 : 1;
  };
};

export default useCompareReportName;
