import React from 'react';

import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { DeviceTypeProviderFormatter } from './Formatter';

export const DeviceTypeProvider = (props) => {
  return (
    <DataTypeProvider
      formatterComponent={DeviceTypeProviderFormatter}
      {...props}
    />
  );
};
