import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { toastr } from 'react-redux-toastr';
import find from 'lodash/find';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';

import WebAPIClient, { errorResponseToastr } from '../../../../api';
import { roundNumber } from '../../../../helpers';
import { getFaultMessage } from '../../Inverter/Dashboard/faultAlarmMessages';
import useLogger from '../../../../store/hooks/useLogger';
import useLoggerDevices from '../../../../store/hooks/useLoggerDevices';
import { REGISTER_OBJECTS as METER_REGISTER_OBJECTS } from '../../Meter/Dashboard/LatestData';
import ValuesList from '../../../../components/RegisterDataGrid/ValuesList';
import { REGISTER_OBJECTS as INVERTER_REGISTER_OBJECTS } from '../../Inverter/Dashboard/LatestData';
import { REGISTER_OBJECTS as SENSOR_REGISTER_OBJECTS } from '../../Sensor/Dashboard/LatestData';

function LiveData() {
  const dispatch = useDispatch();
  const { id } = useSelector((state) => state.pages.logger);
  const logger = useLogger(id);
  const { inverters } = useLoggerDevices(id);

  const [data, setData] = useState([]);
  const [analogValues, setAnalogValues] = useState([]);
  const [binaryValues, setBinaryValues] = useState([]);
  const [multiStateValues, setMultiStateValues] = useState([]);

  useEffect(() => {
    let _values = data.filter((item) => item.type === 'analogValue');
    _values = _values.map((item) => {
      return {
        ...item,
        value: roundNumber.format(item.measure_value),
      };
    });
    setAnalogValues(_values);
  }, [data]);

  useEffect(() => {
    let _values = data.filter((item) => item.type === 'binaryValue');
    _values = _values.map((item) => {
      let value = '-';
      if (item?.measure_value === 0) {
        value = 'Inactive';
      }
      if (item?.measure_value === 1) {
        value = 'Active';
      }
      return { ...item, value };
    });
    setBinaryValues(_values);
  }, [data]);

  useEffect(() => {
    let _values = data.filter((item) => item.type === 'multiStateValue');
    _values = _values.map((item) => {
      let value = item.measure_value;
      if (item.measure_name.includes('_I')) {
        const inverterIdx = item.measure_name[item.measure_name.length - 1];
        const inverter = find(inverters, {
          parent_index: Number(inverterIdx),
        });

        const measureName = item.measure_name.slice(0, -3);
        if (measureName === 'InverterState') {
          value =
            INVERTER_REGISTER_OBJECTS.multiStateValue[1].stateText[
              item.measure_value
            ];
        } else {
          value = getFaultMessage(
            measureName,
            item.measure_value,
            inverter.manufacturer
          );
        }
      }
      if (item.measure_name.includes('_S')) {
        const measureName = item.measure_name.slice(0, -3);
        const registerObject = find(SENSOR_REGISTER_OBJECTS.multiStateValue, {
          objectName: measureName,
        });
        value = registerObject.stateText[item.measure_value];
      } else if (
        item.measure_name ===
        METER_REGISTER_OBJECTS.multiStateValue[501].objectName
      ) {
        value =
          METER_REGISTER_OBJECTS.multiStateValue[501].stateText[
            item.measure_value
          ];
      }
      return { ...item, value };
    });
    setMultiStateValues(_values);
  }, [data, inverters]);

  const handleFetchData = async () => {
    dispatch(showLoading());
    try {
      const payload = await new WebAPIClient().GET(
        `/resource/loggers/${logger.org_id}/${logger.logger_id}`
      );
      if (payload.length > 0) {
        setData(payload);
      } else {
        setData([]);
      }
    } catch (error) {
      if (error?.code === 'ECONNABORTED') {
        toastr.error('Timeout Error', error?.message);
      } else {
        errorResponseToastr(error);
      }
    } finally {
      dispatch(hideLoading());
    }
  };

  return (
    <>
      <Grid
        item
        xs={12}
        display='flex'
        justifyContent='flex-start'
        alignItems='center'>
        <Box sx={{ maxWidth: 500, width: '100%', py: 1 }}>
          <Stack direction='row' spacing={2} alignItems='center' sx={{ ml: 2 }}>
            <Tooltip
              title='force fetch'
              placement='top'
              onClick={handleFetchData}>
              <IconButton sx={{ height: 30, width: 30 }}>
                <FontAwesomeIcon size='sm' icon={['fal', 'router']} />
              </IconButton>
            </Tooltip>
            <Typography
              sx={{ minWidth: 100 }}
              variant='body1'
              color='text.secondary'>
              Fetch data directly from data logger
            </Typography>
          </Stack>
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <ValuesList title='Analog Values' values={analogValues} />
      </Grid>
      <Grid item xs={12} md={6}>
        <ValuesList title='Binary Values' values={binaryValues} />
        <ValuesList
          title='Multi State Values'
          values={multiStateValues}
          mt={1}
        />
      </Grid>
    </>
  );
}

export default LiveData;
