import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import find from 'lodash/find';

const defaultState = {
  name: '',
  level: 0,
  device_type: '',
  description: '',
};

const useTrigger = (triggerId) => {
  const { triggers } = useSelector((state) => state.alarms);

  const [trigger, setTrigger] = useState({});

  useEffect(() => {
    if (triggerId && triggers.length) {
      setTrigger(find(triggers, { trigger_id: triggerId }));
    } else setTrigger(defaultState);
  }, [triggers, triggerId]);

  return trigger;
};

export default useTrigger;
