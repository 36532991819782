import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import find from 'lodash/find';

const defaultState = {
  org_id: '',
  inverter_id: '',
  name: '',
  meter_id: '',
  parent_index: '',
  manufacturer: '',
  serial_number: '',
  dc_size: '',
  ac_size: '',
  sensor_id: '',
};

const useInverter = (inverterId) => {
  const inverters = useSelector((state) => state.inverters.data);
  const [inverter, setInverter] = useState(defaultState);

  useEffect(() => {
    if (inverterId && inverterId.startsWith('inverter') && inverters.length) {
      setInverter(find(inverters, { inverter_id: inverterId }));
    } else setInverter(defaultState);
  }, [inverters, inverterId]);

  return inverter;
};

export { defaultState };
export default useInverter;
