import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import find from 'lodash/find';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import startCase from 'lodash/startCase';

import MenuItem from '@mui/material/MenuItem';

import ROLES from '../../../constants/roles';
import {
  closeDialog,
  openConfirmDialog,
  openDialog,
} from '../../../store/dialogs';
import {
  deactivateReport,
  deleteUserReport,
  postReport,
  postUserReport,
  putReport,
} from '../../../store/reports/_reports';
import Form from './Form';
import useVerifyOrgRole from '../../../store/hooks/useVerifyOrgRole';
import BaseDialog from '../BaseDialog';

const defaultState = {
  name: '',
  report_id: '',
  device_id: '',
  day: 1,
  period: 'month',
  optional: [],
};
function ReportDialog() {
  const dispatch = useDispatch();

  const {
    id,
    mode,
    template: _template,
    resource,
  } = useSelector((state) => state.dialogs.report);
  const {
    data: reports,
    templates,
    userReports,
  } = useSelector((state) => state.reports);

  const [report, setReport] = useState(defaultState);
  const [template, setTemplate] = useState({});
  const [userReport, setUserReport] = useState({});
  const orgId = resource?.org_id || report?.org_id;
  const isOrgAdmin = useVerifyOrgRole(orgId, ROLES.ADMIN.value);

  useEffect(() => {
    if (id && reports) {
      const _report = find(reports, { report_id: id });
      setTemplate(find(templates, { title: _report.name }));
      setReport(_report);
    } else {
      setReport(defaultState);
      setTemplate(_template);
    }
  }, [id, reports, templates, _template]);

  useEffect(() => {
    setUserReport(find(userReports, { report_id: report.report_id }) || {});
  }, [userReports, report]);

  const handleClose = () => {
    dispatch(closeDialog('report'));
    setReport(defaultState);
  };

  const handleSubscribe = () => {
    if (isEmpty(userReport)) {
      dispatch(postUserReport(report));
    } else {
      dispatch(deleteUserReport(userReport));
    }
    handleClose();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    switch (mode) {
      case 'create':
        const deviceId = get(resource, `${resource.type_}_id`);
        let newReport = {
          org_id: resource.org_id,
          name: template.title,
          period: report.period,
          day: Number(report.day),
          optional: report.optional,
          device_id: deviceId,
          equipment: report.equipment,
          contact: report.contact,
        };
        dispatch(postReport(newReport));
        break;

      case 'edit':
        dispatch(putReport({ ...report, day: Number(report.day) }));
        break;

      default:
        break;
    }
    handleClose();
  };

  const handleDelete = () => {
    dispatch(
      openConfirmDialog({
        title: 'Deactivate Report',
        message: 'Are you sure you want to deactivate this report?',
        onConfirm: () => {
          dispatch(deactivateReport(report));
          handleClose();
        },
      })
    );
  };

  const handleOpenGenerateReportDialog = () => {
    dispatch(
      openDialog({ type: 'generateReport', reportId: report.report_id })
    );
  };

  const titleOptions = [
    isOrgAdmin && mode === 'edit' && (
      <MenuItem key='deactivate-button' onClick={handleDelete}>
        Deactivate
      </MenuItem>
    ),
    mode !== 'view' && (
      <MenuItem key='subscribe-button' onClick={handleSubscribe}>
        {!isEmpty(userReport) ? 'Unsubscribe' : 'Subscribe'}
      </MenuItem>
    ),
    isOrgAdmin && mode === 'edit' && (
      <MenuItem key='generate-button' onClick={handleOpenGenerateReportDialog}>
        Generate
      </MenuItem>
    ),
  ].filter(Boolean);

  const submit = (() => {
    if (mode === 'create' || mode === 'edit') return handleSubmit;
    return null;
  })();
  const submitText = (() => {
    if (mode === 'create') return 'Create';
    if (mode === 'edit') return 'Save';
    return null;
  })();
  return (
    <BaseDialog
      id={id}
      submitText={submitText}
      title={startCase(mode) + ' Report'}
      disabled={!isOrgAdmin}
      handleSubmit={submit}
      handleClose={handleClose}
      titleOptions={titleOptions}>
      <Form
        template={template}
        report={report}
        mode={mode}
        setReport={setReport}
        handleClose={handleClose}
      />
    </BaseDialog>
  );
}

export default ReportDialog;
