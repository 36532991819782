import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import find from 'lodash/find';
import map from 'lodash/map';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';

function WarningIcon(props) {
  const { errors } = props;
  const inverters = useSelector((state) => state.inverters.data);
  const meters = useSelector((state) => state.meters.data);

  if (errors.length === 0) return null;
  return (
    <Tooltip
      title={
        <List disablePadding>
          {map(errors, (error) => {
            let inverter = find(inverters, { inverter_id: error?.id });
            let meter = find(meters, { meter_id: inverter?.meter_id });
            return (
              <ListItem key={error.id} sx={{ p: 0 }}>
                <ListItemText primaryTypographyProps={{ variant: 'body2' }}>
                  {meter?.name}
                  {' - '}
                  {inverter?.name}
                  {': '}
                  {error.message}
                </ListItemText>
              </ListItem>
            );
          })}
        </List>
      }>
      <IconButton>
        <FontAwesomeIcon size='sm' icon={['fal', 'exclamation-triangle']} />
      </IconButton>
    </Tooltip>
  );
}

WarningIcon.propTypes = {
  errors: PropTypes.array.isRequired,
};

export default WarningIcon;
