import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import startCase from 'lodash/startCase';

import InputAdornment from '@mui/material/InputAdornment';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import TextField from '@mui/material/TextField';

import { closeDialog } from '../../store/dialogs';

import { ALARM_LEVELS } from '../table/providers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useTrigger from '../../store/hooks/useTrigger';
import BaseDialog from './BaseDialog';

function TriggerDialog() {
  const dispatch = useDispatch();

  const { id } = useSelector((state) => state.dialogs.trigger);
  const trigger = useTrigger(id);

  const handleClose = () => {
    dispatch(closeDialog('trigger'));
  };

  return (
    <BaseDialog
      id={id}
      title={startCase(trigger.trigger_id) + ' Trigger'}
      handleClose={handleClose}>
      <List disablePadding>
        <ListItem disableGutters>
          <TextField
            id='device_type'
            label='Device Type'
            value={startCase(trigger.device_type)}
            fullWidth
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
            variant='standard'
          />
        </ListItem>
        <ListItem disableGutters>
          <TextField
            id='level'
            label='Severity'
            value={ALARM_LEVELS[trigger.level]}
            fullWidth
            InputProps={{
              readOnly: true,
              disableUnderline: true,
              startAdornment: (
                <InputAdornment position='start'>
                  <FontAwesomeIcon
                    icon={['fal', `signal-${trigger.level + 1}`]}
                  />
                </InputAdornment>
              ),
            }}
            variant='standard'
          />
        </ListItem>
        <ListItem disableGutters>
          <TextField
            id='message'
            label='Description'
            value={trigger.message}
            fullWidth
            multiline
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
            variant='standard'
          />
        </ListItem>
      </List>
    </BaseDialog>
  );
}

export default TriggerDialog;
