import { createAsyncThunk } from '@reduxjs/toolkit';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { toastr } from 'react-redux-toastr';
import cloneDeep from 'lodash/cloneDeep';
import concat from 'lodash/concat';
import get from 'lodash/get';
import remove from 'lodash/remove';

import WebAPIClient, { errorResponseToastr } from '../../api';

const getReportsData = createAsyncThunk(
  'reports/fetchReports',
  async (user, { dispatch, getState, requestId }) => {
    const { currentRequestId, loading } = getState().reports;

    if (loading !== true || requestId !== currentRequestId || !user) {
      return;
    }

    try {
      dispatch(showLoading());

      const { reports, templates } = await new WebAPIClient().GET(
        '/resource/reports'
      );
      const userReports = await new WebAPIClient().GET(
        '/resource/user_reports'
      );

      return {
        data: reports,
        userReports,
        templates,
      };
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

const postReport = createAsyncThunk(
  'reports/postReport',
  async (report, { dispatch, getState, requestId }) => {
    try {
      const {
        currentRequestId,
        loading,
        data: allReports,
      } = getState().reports;

      if (loading !== true || requestId !== currentRequestId) {
        return;
      }

      dispatch(showLoading());
      const newReport = await new WebAPIClient().POST(
        '/resource/reports',
        report
      );

      toastr.success('Report created');
      return { data: concat(allReports, newReport) };
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

const putReport = createAsyncThunk(
  'reports/putReport',
  async (report, { dispatch, getState, requestId }) => {
    try {
      const { currentRequestId, loading, data: reports } = getState().reports;

      if (loading !== true || requestId !== currentRequestId) {
        return;
      }

      dispatch(showLoading());
      let updatedReport = await new WebAPIClient().PUT(
        `/resource/reports/${report.report_id}`,
        report
      );

      let _reports = cloneDeep(reports);
      remove(_reports, { report_id: get(updatedReport, 'report_id') });

      toastr.success('Report updated');
      return { data: concat(_reports, updatedReport) };
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

const deactivateReport = createAsyncThunk(
  'reports/deactivateReport',
  async (data, { dispatch, getState, requestId }) => {
    try {
      const {
        currentRequestId,
        loading,
        data: allReports,
      } = getState().reports;

      if (loading !== true || requestId !== currentRequestId) {
        return;
      }

      dispatch(showLoading());
      const deletedReportId = await new WebAPIClient().DELETE(
        `/resource/reports/${data.report_id}`
      );
      let _reports = cloneDeep(allReports);
      remove(_reports, { report_id: deletedReportId });
      toastr.success('Report deleted');

      return { data: _reports };
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

const postUserReport = createAsyncThunk(
  'reports/postUserReport',
  async (userReport, { dispatch, getState, requestId }) => {
    try {
      const {
        currentRequestId,
        loading,
        userReports: allUserReports,
      } = getState().reports;

      if (loading !== true || requestId !== currentRequestId) {
        return;
      }

      dispatch(showLoading());
      const newUserReport = await new WebAPIClient().POST(
        '/resource/user_reports',
        userReport
      );

      toastr.success('Subscribed');
      return { userReports: concat(allUserReports, newUserReport) };
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

const deleteUserReport = createAsyncThunk(
  'reports/deleteUserReport',
  async (userReport, { dispatch, getState, requestId }) => {
    try {
      const {
        currentRequestId,
        loading,
        userReports: allUserReports,
      } = getState().reports;

      if (loading !== true || requestId !== currentRequestId) {
        return;
      }

      dispatch(showLoading());
      let deletedUserReportId = await new WebAPIClient().DELETE(
        `/resource/user_reports/${userReport.report_id}`
      );

      let userReports = cloneDeep(allUserReports);
      remove(userReports, { report_id: deletedUserReportId });
      toastr.success('Unsubscribed');

      return { userReports };
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

export {
  getReportsData,
  postReport,
  putReport,
  deactivateReport,
  postUserReport,
  deleteUserReport,
};
