import React from 'react';
import ReactLoader from 'react-loading';
import styled from '@mui/material/styles/styled';

const ReactLoaderStyled = styled(ReactLoader)({
  position: 'relative',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
});

export default function ComponentLoader({ width, height }) {
  return (
    <ReactLoaderStyled type='spin' color='grey' height={height} width={width} />
  );
}
