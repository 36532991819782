import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { displaykW } from '../../../../helpers/display-energy';
import BaseTooltip from '../../tooltips/BaseTooltip';

function Tooltip({ active, payload, label, timezone, inverter }) {
  return (
    <BaseTooltip
      active={active}
      payload={payload}
      label={dayjs
        .unix(Number(label))
        .tz(timezone.zone)
        .format('MMM Do h:mm A')}
      getName={() => inverter?.name}
      getValue={(resource) => displaykW(resource?.value)}
    />
  );
}

Tooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  label: PropTypes.number,
  timezone: PropTypes.object,
  inverters: PropTypes.array,
};

export default Tooltip;
