import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';

function TabPanel(props) {
  const { children, tab, selectedTab, ...other } = props;

  return (
    <Box
      role='tabpanel'
      hidden={tab !== selectedTab}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
      }}
      {...other}>
      {tab === selectedTab && children}
    </Box>
  );
}

TabPanel.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  tab: PropTypes.string.isRequired,
  selectedTab: PropTypes.string.isRequired,
};

export default TabPanel;
