import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';

import { getCurrentSession } from '../../helpers/auth';
import { handleWebsocketMessage } from '../pages';

const WEBSOCKET_API_ID = process.env.REACT_APP_WEBSOCKET_API_ID;
const REGION = process.env.REACT_APP_REGION;
const ENV = process.env.REACT_APP_ENV;
export const WEBSOCKET_URL = `wss://${WEBSOCKET_API_ID}.execute-api.${REGION}.amazonaws.com/${ENV}`;
export const PING_INTERVAL = 8 * 60 * 1000; // 8 minutes

const useWebsocket = () => {
  const dispatch = useDispatch();
  const socketRef = useRef(null);
  const heartbeatIntervalRef = useRef(null);
  const { orgId } = useSelector((state) => state.pages.websocket);

  useEffect(() => {
    if (!orgId) return;

    // Cleanup old WebSocket before creating a new one
    if (socketRef.current) {
      socketRef.current.close();
      clearInterval(heartbeatIntervalRef.current);
    }

    const connect = async () => {
      const { accessToken } = await getCurrentSession();
      if (!accessToken) return;

      let socket = new WebSocket(
        `${WEBSOCKET_URL}?token=${accessToken}&orgId=${orgId}`
      );
      socketRef.current = socket;

      socket.onopen = () => {
        console.log(
          `Connected to WebSocket: ${orgId}: `,
          dayjs().format('HH:mm:ss')
        );
        // Start the heartbeat to keep the connection alive
        heartbeatIntervalRef.current = setInterval(() => {
          if (socketRef.current?.readyState === WebSocket.OPEN) {
            console.log('Sending heartbeat... ', dayjs().format('HH:mm:ss'));
            socketRef.current.send(JSON.stringify({ type: 'ping' }));
          }
        }, PING_INTERVAL); // Send ping every 8 minutes
      };

      socket.onmessage = (event) => {
        dispatch(handleWebsocketMessage(JSON.parse(event.data)));
      };

      socket.onclose = () => {
        console.log(`WebSocket closed for orgId: ${orgId}`);
        if (socketRef.current === socket) {
          clearInterval(heartbeatIntervalRef.current);
        }
      };

      socket.onerror = (error) => {
        console.error('WebSocket error:', error);
      };
    };

    connect();

    // Cleanup on unmount or orgId change
    return () => {
      if (socketRef.current) {
        socketRef.current.close();
        clearInterval(heartbeatIntervalRef.current);
      }
    };
  }, [orgId, dispatch]);
};

export default useWebsocket;
