import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import find from 'lodash/find';

const defaultState = {
  org_id: '',
  sensor_id: '',
  name: '',
  meter_id: '',
  parent_index: '',
  orientation: '',
  tilt: '',
  azimuth: '',
};

const useSensor = (sensorId) => {
  const sensors = useSelector((state) => state.sensors.data);

  const [sensor, setSensor] = useState({});

  useEffect(() => {
    if (sensorId && sensorId.startsWith('sensor') && sensors.length) {
      setSensor(find(sensors, { sensor_id: sensorId }));
    } else setSensor(defaultState);
  }, [sensors, sensorId]);

  return sensor;
};

export { defaultState };
export default useSensor;
