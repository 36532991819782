import React from 'react';
import PropTypes from 'prop-types';

function ToastrMessages(props) {
  const { messages } = props;

  return (
    <div>
      <div className='messages'>
        {messages.map((item, key) => (
          <span key={key}>
            • {item}
            <br />
          </span>
        ))}
      </div>
    </div>
  );
}

ToastrMessages.propTypes = {
  messages: PropTypes.array.isRequired,
};

export default ToastrMessages;
