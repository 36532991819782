import cloneDeep from 'lodash/cloneDeep';
import reduce from 'lodash/reduce';
import numeral from 'numeral';
import { numberToCurrency } from '.';

// consumes a value in kW and returns the value to 4 significant figures and the appropriate unit denomination
// energy === true if the unit is (kWh, MWh, etc)
export function displaykW(value, energy, insolation, showUnits = true) {
  value = Number(value);
  const valueSigFig = value.toExponential(4);
  const exponent = Number(valueSigFig.slice(-2));
  let unit = 'kW';
  let returnValue = 0;

  switch (true) {
    case value === 0:
      returnValue = 0;
      break;
    case exponent <= -3:
      unit = 'W';
      returnValue = numeral(valueSigFig * 1000).format('0.000');
      break;
    case exponent === -2:
      unit = 'W';
      returnValue = numeral(valueSigFig * 1000).format('0.00');
      break;
    case exponent === -1:
      unit = 'W';
      returnValue = numeral(valueSigFig * 1000).format('0.0');
      break;
    case exponent === 0:
      returnValue = numeral(valueSigFig).format('0.000');
      break;
    case exponent === 1:
      returnValue = numeral(valueSigFig).format('0.00');
      break;
    case exponent === 2:
      returnValue = numeral(valueSigFig).format('0.0');
      break;
    case exponent === 3:
      unit = 'MW';
      returnValue = numeral(valueSigFig / 1000).format('0.000');
      break;
    case exponent === 4:
      unit = 'MW';
      returnValue = numeral(valueSigFig / 1000).format('0.00');
      break;
    case exponent === 5:
      unit = 'MW';
      returnValue = numeral(valueSigFig / 1000).format('0.0');
      break;
    case exponent === 6:
      unit = 'GW';
      returnValue = numeral(valueSigFig / 1000000).format('0.000');
      break;
    case exponent === 7:
      unit = 'GW';
      returnValue = numeral(valueSigFig / 1000000).format('0.00');
      break;
    case exponent === 8:
      unit = 'GW';
      returnValue = numeral(valueSigFig / 1000000).format('0.0');
      break;
    default:
      unit = 'GW';
      returnValue = numeral(valueSigFig / 1000000).format('0,0.0');
      break;
  }

  if (energy) unit = unit + 'h';
  else if (insolation) unit = unit + 'h/m2';

  return ` ${returnValue} ${showUnits ? unit : ''}`;
}

export function calculateCostMeterSavings(
  chartData,
  energyRate,
  isEnergy = true
) {
  const data = cloneDeep(chartData);
  const totalSum = reduce(
    data,
    (totalSum, intervalData) => {
      const meterIntervalSum = reduce(
        intervalData,
        (intervalSum, value, key) => {
          if (key.startsWith('meter') && value) {
            if (energyRate) {
              return intervalSum + value * energyRate * (isEnergy ? 1 : 0.25);
            }
          }
          return intervalSum;
        },
        0
      );
      return totalSum + meterIntervalSum;
    },
    0
  );

  if (totalSum === 0) {
    return null;
  }

  return numberToCurrency.format(totalSum);
}
