import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import find from 'lodash/find';

const defaultState = {
  org_id: '',
  logger_id: '',
  name: '',
  ip_address: '',
  active: false,
  online: false,
  ingestion_method: '',
};

const useLogger = (loggerId) => {
  const loggers = useSelector((state) => state.loggers.data);
  const [logger, setLogger] = useState({});

  useEffect(() => {
    if (loggerId && loggerId.startsWith('logger') && loggers.length) {
      setLogger(find(loggers, { logger_id: loggerId }));
    } else setLogger(defaultState);
  }, [loggers, loggerId]);

  return logger;
};

export { defaultState };
export default useLogger;
