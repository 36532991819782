import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

export const PERIODS = ['day', 'week', 'month', 'year'];

function SelectPeriod(props) {
  const {
    selectedPeriod,
    setSelectedPeriod,
    periods = PERIODS,
    disabled,
  } = props;
  const [period, setUnit] = useState('');

  useEffect(() => {
    setUnit(selectedPeriod);
  }, [selectedPeriod]);

  const handleSelect = (e) => {
    e.preventDefault();
    setSelectedPeriod(e.target.value);
  };

  return (
    <FormControl
      variant='standard'
      sx={{
        width: '45%',
        '& .MuiInputBase-input.Mui-disabled': {
          WebkitTextFillColor: 'rgba(0, 0, 0, 0.87)',
        },
      }}>
      <InputLabel id='period-label'>Period</InputLabel>
      <Select
        disableUnderline={disabled}
        disabled={disabled}
        labelId='period-label'
        id='period-select'
        label='Period'
        value={period}
        variant='standard'
        onChange={handleSelect}
        IconComponent={() => null}
        MenuProps={{ MenuListProps: { disablePadding: true } }}>
        {periods.map((period) => {
          return (
            <MenuItem key={period} value={period} disabled={period !== 'month'}>
              {period}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}

SelectPeriod.propTypes = {
  selectedPeriod: PropTypes.string,
  setSelectedPeriod: PropTypes.func,
};

export default SelectPeriod;
