import React from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';

import styled from '@emotion/styled';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';

export const StyledTd = styled('td')(
  ({
    color,
    hideOverflow,
    align = 'left',
    borderBottom = false,
    colSpan = 1,
  }) => {
    return {
      color,
      ...(hideOverflow && {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        display: '-webkit-box',
        WebkitLineClamp: '1',
        WebkitBoxOrient: 'vertical',
      }),
      textAlign: align,
      ...(borderBottom && {
        borderBottom: '1px solid #e0e0e0',
      }),
      colSpan,
    };
  }
);

function BaseTooltip({
  active,
  payload,
  label,
  getName,
  getValue,
  width = '18rem',
}) {
  if (active && payload && payload.length) {
    return (
      <Card raised sx={{ width, p: 1 }}>
        {label && (
          <Typography variant='body1' align='center'>
            {label}
          </Typography>
        )}
        <table style={{ width: '100%' }}>
          <tbody>
            {map(payload, (resource) => {
              const color = resource.color;
              return (
                <tr key={resource?.dataKey}>
                  <StyledTd color={color} hideOverflow>
                    {getName(resource)}
                  </StyledTd>
                  <StyledTd color={color} align='right'>
                    {getValue(resource)}
                  </StyledTd>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Card>
    );
  }

  return null;
}

BaseTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  label: PropTypes.string,
  getName: PropTypes.func,
  getValue: PropTypes.func,
};

export default BaseTooltip;
