import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import DialogTitleOptions from '../../Dialogs/DialogTitleOptions';

function SelectNumber(props) {
  const { value, setValue, label } = props;
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const [open, setOpen] = useState(false);

  const [tempValue, setTempValue] = useState('');

  useEffect(() => {
    setTempValue(value);
  }, [value]);

  const handleChange = (e) => {
    e.preventDefault();
    setTempValue(e.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setValue(Number(tempValue));
    handleClose();
  };

  return (
    <>
      <Box
        sx={{
          width: '100%',
          maxWidth: 100,
          display: 'flex',
          flexDirection: 'column',
          cursor: 'pointer',
        }}
        onClick={() => setOpen(true)}>
        <Typography align='center' color='text.secondary' variant='caption'>
          {label}
        </Typography>
        <Typography align='center' color='primary' variant='body1'>
          {value}
        </Typography>
      </Box>
      <Dialog open={open} onClose={handleClose} fullScreen={isMobile}>
        <form onSubmit={handleSubmit}>
          <DialogTitle>
            {label}
            <DialogTitleOptions handleClose={handleClose} />
          </DialogTitle>
          <DialogContent sx={{ minWidth: 200 }}>
            <TextField
              fullWidth
              type='text'
              variant='standard'
              inputProps={{
                inputMode: 'numeric',
                // pattern: '/^-?d+(?:.d+)?$/g',
              }}
              value={tempValue}
              onChange={handleChange}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button variant='contained' onClick={handleSubmit}>
              Submit
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}

SelectNumber.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  setValue: PropTypes.func,
};

export default SelectNumber;
