import React from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

function SelectOrganization(props) {
  const {
    orgId,
    setOrgId,
    organizations = [],
    label = 'Select Organization',
  } = props;

  const handleChange = (event) => {
    setOrgId(event.target.value);
  };

  return (
    <FormControl fullWidth size='small' variant='filled'>
      <InputLabel size='small' id='organization-label'>
        {label}
      </InputLabel>
      <Select
        labelId='organization-label'
        disableUnderline
        id='organization'
        value={orgId}
        onChange={handleChange}
        MenuProps={{
          MenuListProps: {
            disablePadding: true,
            sx: {
              '& .MuiPaper-root': {
                minWidth: 'unset',
              },
            },
          },
          slotProps: {
            paper: {
              sx: {
                minWidth: 'unset !important',
              },
            },
          },
        }}
        sx={{
          backgroundColor: 'unset',
          '& .MuiSelect-select': {
            display: 'flex',
            alignItems: 'center',
          },
        }}>
        <MenuItem id='empty-menu-item' value=''>
          <ListItemText
            primary='Show All'
            primaryTypographyProps={{ variant: 'body1' }}
          />
        </MenuItem>
        {organizations.map((organization) => (
          <MenuItem
            key={organization.org_id}
            id={`${organization.org_id}-menu-item`}
            value={organization.org_id}>
            <ListItemIcon>
              <FontAwesomeIcon icon={['fal', 'buildings']} />
            </ListItemIcon>
            <ListItemText
              primary={organization.name}
              primaryTypographyProps={{ variant: 'body1' }}
            />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

SelectOrganization.defaultProps = {
  organizations: [],
};

SelectOrganization.propTypes = {
  orgId: PropTypes.string,
  setOrgId: PropTypes.func.isRequired,
  organizations: PropTypes.array,
  label: PropTypes.string,
};

export default SelectOrganization;
