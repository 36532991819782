import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'redux-first-history';
import find from 'lodash/find';
import useMediaQuery from '@mui/material/useMediaQuery';

import ROUTES from '../../../constants/routes';
import { setWebsocketOrgId } from '../../../store/pages';
import usePageId from '../../../store/hooks/usePageId';
import useInverter from '../../../store/hooks/useInverter';
import useTimeseriesData from '../../../store/hooks/useTimeseriesData';
import TabBar, {
  ANALYSIS,
  CONFIGURATION,
  DASHBOARD,
} from '../../../components/tab/TabBar';
import InverterDashboard from './Dashboard';
import Analysis from './Analysis';
import AlarmConfigurationPage from '../../../components/AlarmConfigurationPage';

const PAGE = 'inverter';
const TABS = [DASHBOARD, ANALYSIS, CONFIGURATION];

export default function Inverter() {
  const location = useLocation();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const { id } = useSelector((state) => state.pages.inverter);
  const inverter = useInverter(id);
  usePageId(id, PAGE);
  useTimeseriesData(id);

  const [tabs, setTabs] = useState([]);
  const [selectedTab, setSelectedTab] = useState(DASHBOARD);

  useEffect(() => {
    if (inverter?.org_id) dispatch(setWebsocketOrgId(inverter.org_id));
  }, [inverter?.org_id, dispatch]);

  useEffect(() => {
    if (isMobile) setTabs([DASHBOARD, CONFIGURATION]);
    else setTabs(TABS);
  }, [isMobile]);

  useEffect(() => {
    const hash = location.hash.replace('#', '');
    if (hash) {
      let _tab = find(TABS, (value) => value === hash);
      setSelectedTab(_tab);
    } else {
      setSelectedTab(DASHBOARD);
    }
  }, [location.hash]);

  const handleChangeTab = (event, value) => {
    dispatch(push(ROUTES.AUTH.INVERTER + '#' + value));
  };

  return (
    <TabBar
      tabs={tabs}
      handleChangeTab={handleChangeTab}
      selectedTab={selectedTab}>
      <InverterDashboard tab={DASHBOARD} />
      <Analysis tab={ANALYSIS} />
      <AlarmConfigurationPage tab={CONFIGURATION} resource={inverter} />
    </TabBar>
  );
}
