import React from 'react';
import { useSelector } from 'react-redux';

import useSite from '../../../../store/hooks/useSite';
import useSiteDevices from '../../../../store/hooks/useSiteDevices';
import AnalysisPage from '../../Analysis';

export default function Analysis() {
  const { view, id } = useSelector((state) => state.pages.site);
  const site = useSite(id);
  const { meters, inverters, sensors } = useSiteDevices(id);

  return (
    <AnalysisPage
      resource={site}
      timezone={site.timezone}
      view={view}
      sites={[site]}
      meters={meters}
      inverters={inverters}
      sensors={sensors}
    />
  );
}
