import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Checkbox from '@mui/material/Checkbox';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { closeDialog } from '../../store/dialogs';
import BaseDialog from './BaseDialog';
import { updateTriggerDevices } from '../../store/alarms';

const IRREGULAR_INVERTER_DATA_ALARM_ID = 'irregular_inverter_data';

const generateDefaultThresholdValues = (ac_size) => {
  if (ac_size in [50, 60]) {
    return [
      0.6, // January
      0.62, // February
      0.67, // March
      0.7, // April
      0.7, // May
      0.7, // June
      0.7, // July
      0.69, // August
      0.68, // September
      0.63, // October
      0.62, // November
      0.6, // December
    ];
  }

  if (ac_size in [25, 28, 36]) {
    return Array(12).fill(0.55);
  }

  return Array(12).fill(0.7);
};

const getMonthName = (index) => {
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  return monthNames[index];
};

function IrregularInverterDataAlarmDialog() {
  const dispatch = useDispatch();

  const { trigger_device_id, allActive } = useSelector(
    (state) => state.dialogs.irregularInverterDataAlarm
  );
  const allTriggerDevices = useSelector((state) => state.alarms.triggerDevices);
  const allMeters = useSelector((state) => state.meters.data);
  const inverters = useSelector((state) => state.inverters.data);

  const [openThresholdValues, setOpenThresholdValues] = useState(true);
  const [openInverters, setOpenInverters] = useState(false);

  const [clickedInverter, setClickedInverter] = useState({});
  const [activeInverters, setActiveInverters] = useState([]);
  const [selectedInverters, setSelectedInverters] = useState([]);
  const [thresholdValues, setThresholdValues] = useState([]);

  useEffect(() => {
    const _triggerDevice = allTriggerDevices.find(
      (td) => td.trigger_device_id === trigger_device_id
    );
    const inverter = inverters.find(
      (i) => i.inverter_id === _triggerDevice?.device_id
    );
    setClickedInverter(inverter);
    setThresholdValues(
      _triggerDevice?.params?.threshold_values ||
        generateDefaultThresholdValues(inverter?.ac_size)
    );
    setSelectedInverters([_triggerDevice?.device_id]);
  }, [trigger_device_id, allTriggerDevices, inverters]);

  useEffect(() => {
    let _inverters = allActive.map((a) =>
      inverters.find((i) => i.inverter_id === a.device_id)
    );

    let groupedInverters = groupBy(_inverters, (i) => i.meter_id);
    groupedInverters = Object.keys(groupedInverters).map((key) => {
      const meter = allMeters.find((m) => m.meter_id === key);
      return {
        meterId: key,
        meterName: meter?.name,
        inverters: sortBy(groupedInverters[key], 'parent_index'),
      };
    });
    setActiveInverters(groupedInverters);
  }, [allActive, inverters, allMeters]);

  const handleChange = (e, index) => {
    setThresholdValues((old) => {
      let newThresholdValues = [...old];
      newThresholdValues[index] = e.target.value;
      return newThresholdValues;
    });
  };

  const handleClose = () => {
    dispatch(closeDialog('irregularInverterDataAlarm'));
    setOpenThresholdValues(true);
    setOpenInverters(false);
  };

  const handleToggle = (inverterId) => () => {
    setSelectedInverters((old) => {
      if (old.includes(inverterId)) {
        return old.filter((id) => id !== inverterId);
      } else {
        return [...old, inverterId];
      }
    });
  };

  const handleClickCollapse = (section) => {
    if (section === 'thresholdValues') {
      setOpenThresholdValues(!openThresholdValues);
      if (!openThresholdValues && openInverters) {
        setOpenInverters(false);
      }
    } else if (section === 'inverters') {
      setOpenInverters(!openInverters);
      if (!openInverters && openThresholdValues) {
        setOpenThresholdValues(false);
      }
    }
  };

  const handleSubmit = () => {
    let _allTriggerDevices = cloneDeep(allTriggerDevices);
    let _triggerDevices = selectedInverters.map((inverterId) => {
      return _allTriggerDevices.find(
        (td) =>
          td.device_id === inverterId &&
          td.trigger_id === IRREGULAR_INVERTER_DATA_ALARM_ID
      );
    });
    dispatch(
      updateTriggerDevices({
        triggerDevices: _triggerDevices,
        thresholdValues: thresholdValues.map((val) => parseFloat(val)),
      })
    );
    handleClose();
  };

  return (
    <BaseDialog
      id={trigger_device_id}
      title='Irregular Inverter Data Threshold Values'
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      submitText='Update'>
      <Typography variant='body1' color='text.secondary' sx={{ width: 400 }}>
        If an inverter's production does not exceed the threshold percentage of
        it's capacity in a 5-day period, an alarm will be triggered.
      </Typography>
      <List disablePadding>
        <ListItemButton onClick={() => handleClickCollapse('thresholdValues')}>
          <ListItemText primary='Monthly Threshold Values' />
          {openThresholdValues ? (
            <FontAwesomeIcon icon={['fal', 'chevron-down']} size='lg' />
          ) : (
            <FontAwesomeIcon icon={['fal', 'chevron-right']} size='lg' />
          )}
        </ListItemButton>
        <Collapse in={openThresholdValues} timeout='auto' unmountOnExit>
          <List component='div' disablePadding sx={{ px: 4 }}>
            {thresholdValues.map((value, index) => (
              <ListItem disablePadding disableGutters key={index} dense>
                <ListItemText
                  sx={{ width: '50%' }}
                  primary={getMonthName(index)}
                />
                <TextField
                  sx={{ width: '25%' }}
                  type='text'
                  variant='standard'
                  inputProps={{
                    inputMode: 'numeric',
                    sx: { textAlign: 'right' },
                  }}
                  value={value}
                  onChange={(e) => handleChange(e, index)}
                />
              </ListItem>
            ))}
          </List>
        </Collapse>
        <ListItemButton onClick={() => handleClickCollapse('inverters')}>
          <ListItemText primary='Inverters' />
          {openInverters ? (
            <FontAwesomeIcon icon={['fal', 'chevron-down']} size='lg' />
          ) : (
            <FontAwesomeIcon icon={['fal', 'chevron-right']} size='lg' />
          )}
        </ListItemButton>
        <Collapse in={openInverters} timeout='auto' unmountOnExit>
          <List
            component='div'
            disablePadding
            sx={{ px: 4, maxHeight: 300, overflow: 'auto' }}>
            {activeInverters.map(({ meterId, meterName, inverters }) => {
              return (
                <React.Fragment key={meterId}>
                  <ListSubheader component='div'>{meterName}</ListSubheader>
                  {inverters.map((inverter, idx) => (
                    <ListItemButton
                      key={idx}
                      role={undefined}
                      onClick={handleToggle(inverter.inverter_id)}
                      dense>
                      <ListItemIcon>
                        <Checkbox
                          edge='start'
                          checked={selectedInverters.includes(
                            inverter.inverter_id
                          )}
                          tabIndex={-1}
                          disableRipple
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={inverter.name}
                        primaryTypographyProps={{ align: 'center' }}
                      />
                      <ListItemText
                        secondary={
                          inverter.ac_size ? `${inverter.ac_size} kW` : ''
                        }
                        secondaryTypographyProps={{
                          align: 'right',
                          color:
                            clickedInverter.ac_size === inverter.ac_size
                              ? 'text.secondary'
                              : 'error',
                          fontWeight:
                            clickedInverter.ac_size === inverter.ac_size
                              ? 'normal'
                              : 'bold',
                        }}
                      />
                    </ListItemButton>
                  ))}
                </React.Fragment>
              );
            })}
          </List>
        </Collapse>
      </List>
    </BaseDialog>
  );
}

export default IrregularInverterDataAlarmDialog;
