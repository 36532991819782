import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import find from 'lodash/find';
import get from 'lodash/get';
import map from 'lodash/map';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DataTypeProvider } from '@devexpress/dx-react-grid';

import Badge from '@mui/material/Badge';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';

import { navigate } from '../../../store/pages';
import { MaxWidthTooltip } from '../../MaxWidthTooltip';

export const AlarmBadgeTypeProvider = (props) => {
  const dispatch = useDispatch();
  const { data: sites } = useSelector((state) => state.sites);
  const { data: loggers } = useSelector((state) => state.loggers);
  const { data: meters } = useSelector((state) => state.meters);
  const { data: inverters } = useSelector((state) => state.inverters);
  const { data: sensors } = useSelector((state) => state.sensors);

  const getDeviceIdentifier = (deviceId) => {
    if (deviceId.startsWith('site')) {
      return get(
        find(sites, {
          site_id: deviceId,
        }),
        'name'
      );
    } else if (deviceId.startsWith('logger')) {
      return get(
        find(loggers, {
          logger_id: deviceId,
        }),
        'name'
      );
    } else if (deviceId.startsWith('meter')) {
      return get(
        find(meters, {
          meter_id: deviceId,
        }),
        'name'
      );
    } else if (deviceId.startsWith('inverter')) {
      let inverter = find(inverters, {
        inverter_id: deviceId,
      });
      let meterName = get(
        find(meters, {
          meter_id: inverter?.meter_id,
        }),
        'name'
      );
      return `${meterName} - ${inverter?.name}`;
    } else if (deviceId.startsWith('sensor')) {
      let sensor = find(sensors, {
        sensor_id: deviceId,
      });
      let meterName = get(
        find(meters, {
          meter_id: sensor?.meter_id,
        }),
        'name'
      );
      return `${meterName} - ${sensor?.name}`;
    } else {
      return deviceId;
    }
  };

  const handleClick = (orgId) => {
    dispatch(
      navigate({
        page: 'organization',
        id: orgId,
        tab: 'dashboard',
        view: 'alarms',
      })
    );
  };

  const renderBadgeIcon = (alarms) => {
    if (alarms.length === 0) {
      return null;
    }

    return (
      <Badge
        badgeContent={alarms.length}
        color='error'
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}>
        <FontAwesomeIcon
          icon={['fal', 'alarm-exclamation']}
          style={{ height: 20, width: 20 }}
        />
      </Badge>
    );
  };

  return (
    <DataTypeProvider
      formatterComponent={({ value: alarms, row }) => {
        if (alarms.length > 0) {
          return (
            <MaxWidthTooltip
              placement='right'
              title={
                <List sx={{ p: 0 }}>
                  {map(alarms, (alarm) => {
                    let deviceName = getDeviceIdentifier(alarm.device_id);
                    let text = `${deviceName}: ${alarm.message}`;
                    return (
                      <ListItemText
                        key={alarm.alarm_id}
                        primaryTypographyProps={{
                          variant: 'caption',
                          sx: {
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: '-webkit-box',
                            WebkitLineClamp: '1',
                            WebkitBoxOrient: 'vertical',
                          },
                        }}>
                        {text}
                      </ListItemText>
                    );
                  })}
                </List>
              }
              onClick={() => handleClick(row.org_id)}>
              {renderBadgeIcon(alarms)}
            </MaxWidthTooltip>
          );
        }
        return renderBadgeIcon(alarms);
      }}
      {...props}
    />
  );
};
