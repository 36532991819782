import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import find from 'lodash/find';

const useDevice = (deviceId) => {
  const sites = useSelector((state) => state.sites.data);
  const loggers = useSelector((state) => state.loggers.data);
  const meters = useSelector((state) => state.meters.data);
  const inverters = useSelector((state) => state.inverters.data);
  const sensors = useSelector((state) => state.sensors.data);

  const [device, setDevice] = useState({});

  useEffect(() => {
    if (!deviceId) {
      setDevice({});
    } else {
      if (deviceId.startsWith('site') && sites.length) {
        setDevice(find(sites, { site_id: deviceId }));
      } else if (deviceId.startsWith('logger') && loggers.length) {
        setDevice(find(loggers, { logger_id: deviceId }));
      } else if (deviceId.startsWith('meter') && meters.length) {
        setDevice(find(meters, { meter_id: deviceId }));
      } else if (deviceId.startsWith('inverter') && inverters.length) {
        setDevice(find(inverters, { inverter_id: deviceId }));
      } else if (deviceId.startsWith('sensor') && sensors.length) {
        setDevice(find(sensors, { sensor_id: deviceId }));
      } else setDevice({});
    }
  }, [deviceId, sites, loggers, meters, inverters, sensors]);

  return device;
};

export default useDevice;
