import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { refreshDeviceTimeseries } from '../timeseries';

const useTimeseriesData = (id) => {
  const dispatch = useDispatch();
  const { data: organizations, portfolioMemberships } = useSelector(
    (state) => state.organizations
  );
  const { data: meters, loading: meterLoading } = useSelector(
    (state) => state.meters
  );
  const { data: inverters, loading: inverterLoading } = useSelector(
    (state) => state.inverters
  );
  const { data: sensors, loading: sensorLoading } = useSelector(
    (state) => state.sensors
  );

  const [devices, setDevices] = useState([]);
  const loading = meterLoading || inverterLoading || sensorLoading;

  useEffect(() => {
    if (id?.startsWith('org')) {
      const org = organizations.find((org) => org.org_id === id);
      if (org?.is_portfolio) {
        const _portfolioMemberships = portfolioMemberships.filter(
          (member) => member.portfolio_id === id
        );
        const _meters = meters.filter((meter) =>
          _portfolioMemberships.some(
            (member) => member.member_id === meter.org_id
          )
        );
        setDevices(_meters);
      } else if (org?.is_portfolio === false) {
        const _meters = meters.filter((meter) => meter.org_id === id);
        setDevices(_meters);
      }
    } else if (id?.startsWith('site')) {
      let _meters = meters.filter((meter) => meter.site_id === id);
      let _meterIds = _meters.map((meter) => meter.meter_id);
      let _inverters = inverters.filter((inverter) =>
        _meterIds.includes(inverter.meter_id)
      );
      setDevices([..._meters, ..._inverters]);
    } else if (id?.startsWith('logger')) {
      const _meters = meters.filter((meter) => meter.logger_id === id);
      const _meterIds = _meters.map((meter) => meter.meter_id);
      let _inverters = inverters.filter((inverter) =>
        _meterIds.includes(inverter.meter_id)
      );
      let _sensors = sensors.filter((sensor) =>
        _meterIds.includes(sensor.meter_id)
      );
      setDevices([..._meters, ..._inverters, ..._sensors]);
    } else if (id?.startsWith('meter')) {
      const meter = meters.find((meter) => meter.meter_id === id);
      if (meter) {
        setDevices([meter]);
      }
    } else if (id?.startsWith('inverter')) {
      const inverter = inverters.find(
        (inverter) => inverter.inverter_id === id
      );
      if (inverter) {
        setDevices([inverter]);
      }
    } else if (id?.startsWith('sensor')) {
      const sensor = sensors.find((sensor) => sensor.sensor_id === id);
      if (sensor) {
        setDevices([sensor]);
      }
    }
  }, [id, organizations, portfolioMemberships, meters, inverters, sensors]);

  useEffect(() => {
    const devicesHaveType =
      devices.length > 0 && devices.every((device) => device.type_);

    if (devicesHaveType && !loading) {
      dispatch(refreshDeviceTimeseries(devices));
    }
  }, [dispatch, devices, loading]);
};

export default useTimeseriesData;
