import React from 'react';

import { CssBaseline, useMediaQuery } from '@mui/material';
import styled from '@mui/material/styles/styled';
import Grid from '@mui/material/Grid';

import AuthenticationLinks from './AuthenticationLinks';
import HeadlineContent from './HeadlineContent';
import HowItWorks from './HowItWorks';
import BenefitItem from './BenefitItem';
import VeregyIcon from './VeregyIcon';

export const backgroundImage = [
  'Airport-Parking.jpeg',
  'EVV4.jpeg',
  'Fort-Wayne-Airport.jpeg',
  'HamiltonCo2.jpeg',
  'Saint-Meinrad.jpeg',
  'solar-array.jpg',
][Math.floor(Math.random() * 6)];
export const background = require(`../../assets/images/${backgroundImage}`);

const MobileRoot = styled('div')({
  background: `url(${background})`,
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
});
const DesktopRoot = styled('div')({
  display: 'flex',
  height: '100%',
  width: '100vw',
  background: `url(${background})`,
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
});

const Main = styled('div')({
  display: 'flex',
  height: '100%',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  background: 'rgba(0, 0, 0, .35)',
});

export default function Authentication() {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const Root = isMobile ? MobileRoot : DesktopRoot;
  return (
    <Root>
      <CssBaseline />
      <Main>
        <Grid
          container
          spacing={2}
          sx={{ maxWidth: 1000, width: '100%', py: 2 }}>
          <Grid item xs={12} sm={6}>
            <AuthenticationLinks />
          </Grid>
          <Grid item xs={12} sm={6}>
            <HeadlineContent />
          </Grid>
          <Grid item xs={12}>
            <HowItWorks />
          </Grid>
          <Grid item xs={12} sm={4}>
            <BenefitItem
              title='Optimized Performance'
              icon={<VeregyIcon name='Data Growth Statistics' />}>
              Maximize the efficiency of your solar arrays by identifying
              underperforming panels or equipment issues promptly.
            </BenefitItem>
          </Grid>
          <Grid item xs={12} sm={4}>
            <BenefitItem
              title='Data Driven Decision Making'
              icon={<VeregyIcon name='Presentation' />}>
              Make informed decisions backed by real-time and historical
              performance data, empowering you to maximize ROI and
              sustainability.
            </BenefitItem>
          </Grid>
          <Grid item xs={12} sm={4}>
            <BenefitItem
              title='Simplified Management'
              icon={<VeregyIcon name='Data Analysis' />}>
              With LightLevel's user-friendly interface, managing your solar
              portfolio has never been easier. Access real-time and historical
              performance data from anywhere, anytime.
            </BenefitItem>
          </Grid>
        </Grid>
      </Main>
    </Root>
  );
}
