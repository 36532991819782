import React, { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const LoggerInactiveCard = (props) => {
  const { deviceName } = props;
  const [show, setShow] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShow(true);
    }, 100);

    return () => clearTimeout(timer);
  }, []);

  if (!show) return null;
  return (
    <Box sx={{ my: 4, width: '100%' }}>
      <Typography align='center' color='text.secondary'>
        {deviceName} is inactive
      </Typography>
    </Box>
  );
};

export default LoggerInactiveCard;
