import React from 'react';
import { useSelector } from 'react-redux';

import AnalysisPage from '../../Analysis';
import usePortfolioDevices from '../../../../store/hooks/usePortfolioDevices';
import useOrganization from '../../../../store/hooks/useOrganization';

export default function Analysis() {
  const { view, id } = useSelector((state) => state.pages.portfolio);
  const { organization: portfolio } = useOrganization(id);
  const { sites, meters, inverters, sensors } = usePortfolioDevices(id);

  return (
    <AnalysisPage
      resource={portfolio}
      timezone={portfolio.timezone}
      view={view}
      sites={sites}
      meters={meters}
      inverters={inverters}
      sensors={sensors}
    />
  );
}
