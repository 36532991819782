import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { SubHeading, Paragraph, Title } from './index';

const TermsContent = () => (
  <Card raised>
    <CardContent sx={{ p: 1, pb: '4px !important' }}>
      <List disablePadding>
        <Title>Terms & Conditions</Title>
        <Paragraph>
          These terms of service outline the rules and regulations for the use
          of Orchestrate's Website.
        </Paragraph>
        <Paragraph>
          By accessing this website we assume you accept these terms of service
          in full. Do not continue to use Orchestrate's website if you do not
          accept all of the terms of service stated on this page.
        </Paragraph>
        <Paragraph>
          The following terminology applies to these Terms of Service, Privacy
          Statement and Disclaimer Notice and any or all Agreements: "Client",
          "You" and "Your" refers to you, the person accessing this website and
          accepting the Company's terms of service. "The Company", "Ourselves",
          "We", "Our" and "Us", refers to our Company. "Party", "Parties", or
          "Us", refers to both the Client and ourselves, or either the Client or
          ourselves. All terms refer to the offer, acceptance and consideration
          of payment necessary to undertake the process of our assistance to the
          Client in the most appropriate manner, whether by formal meetings of a
          fixed duration, or any other means, for the express purpose of meeting
          the Client's needs in respect of provision of the Company's stated
          services/products, in accordance with and subject to, prevailing law
          of . Any use of the above terminology or other words in the singular,
          plural, capitalization and/or he/she or they, are taken as
          interchangeable and therefore as referring to same.
        </Paragraph>

        <SubHeading>Cookies</SubHeading>
        <Paragraph>
          We employ the use of cookies. By using Orchestrate's website you
          consent to the use of cookies in accordance with Orchestrate's privacy
          policy.
        </Paragraph>
        <Paragraph>
          Most of the modern day interactive web sites use cookies to enable us
          to retrieve user details for each visit. Cookies are used in some
          areas of our site to enable the functionality of this area and ease of
          use for those people visiting. Some of our affiliate / advertising
          partners may also use cookies.
        </Paragraph>

        <SubHeading>License</SubHeading>
        <Paragraph>
          Unless otherwise stated, Orchestrate and/or it's licensors own the
          intellectual property rights for all material on Orchestrate. All
          intellectual property rights are reserved. You may view and/or print
          pages from orchestratehub.com for your own personal use subject to
          restrictions set in these terms of service.
        </Paragraph>
        <Paragraph>You must not:</Paragraph>
        <ListItem>
          <ol>
            <li>Republish material from orchestratehub.com</li>
            <li>Sell, rent or sub-license material from orchestratehub.com</li>
            <li>
              Reproduce, duplicate or copy material from orchestratehub.com
            </li>
          </ol>
        </ListItem>
        <Paragraph>
          Redistribute content from Orchestrate (unless content is specifically
          made for redistribution).
        </Paragraph>

        <SubHeading>Hyperlinking to our Content</SubHeading>
        <ListItem>
          <ol>
            <li>
              The following organizations may link to our Web site without prior
              written approval:
            </li>
            <ol>
              <li>Government agencies;</li>
              <li>Search engines;</li>
              <li>News organizations;</li>
              <li>
                Online directory distributors when they list us in the directory
                may link to our Web site in the same manner as they hyperlink to
                the Web sites of other listed businesses; and
              </li>
              <li>
                Systemwide Accredited Businesses except soliciting non-profit
                organizations, charity shopping malls, and charity fundraising
                groups which may not hyperlink to our Web site.
              </li>
            </ol>
            <li>
              These organizations may link to our home page, to publications or
              to other Web site information so long as the link: (a) is not in
              any way misleading; (b) does not falsely imply sponsorship,
              endorsement or approval of the linking party and its products or
              services; and (c) fits within the context of the linking party's
              site.
            </li>
            <li>
              We may consider and approve in our sole discretion other link
              requests from the following types of organizations:
              <ol>
                <li>
                  commonly-known consumer and/or business information sources
                  such as Chambers of Commerce, American Automobile Association,
                  AARP and Consumers Union;
                </li>
                <li>dot.com community sites;</li>
                <li>
                  associations or other groups representing charities, including
                  charity giving sites,
                </li>
                <li>online directory distributors;</li>
                <li>internet portals;</li>
                <li>
                  accounting, law and consulting firms whose primary clients are
                  businesses; and
                </li>
                <li>educational institutions and trade associations.</li>
              </ol>
            </li>
          </ol>
        </ListItem>
        <Paragraph>
          We will approve link requests from these organizations if we determine
          that: (a) the link would not reflect unfavorably on us or our
          accredited businesses (for example, trade associations or other
          organizations representing inherently suspect types of business, such
          as work-at-home opportunities, shall not be allowed to link); (b)the
          organization does not have an unsatisfactory record with us; (c) the
          benefit to us from the visibility associated with the hyperlink
          outweighs the absence of Orchestrate; and (d) where the link is in the
          context of general resource information or is otherwise consistent
          with editorial content in a newsletter or similar product furthering
          the mission of the organization.
        </Paragraph>
        <Paragraph>
          These organizations may link to our home page, to publications or to
          other Web site information so long as the link: (a) is not in any way
          misleading; (b) does not falsely imply sponsorship, endorsement or
          approval of the linking party and it products or services; and (c)
          fits within the context of the linking party's site.
        </Paragraph>
        <Paragraph>
          If you are among the organizations listed in paragraph 2 above and are
          interested in linking to our website, you must notify us by sending an
          e-mail to team@dynamix-ltd.com. Please include your name, your
          organization name, contact information (such as a phone number and/or
          e-mail address) as well as the URL of your site, a list of any URLs
          from which you intend to link to our Web site, and a list of the
          URL(s) on our site to which you would like to link. Allow 2-3 weeks
          for a response.
        </Paragraph>
        <Paragraph>
          Approved organizations may hyperlink to our Web site as follows:
        </Paragraph>
        <ListItem>
          <ol>
            <li>By use of our corporate name; or</li>
            <li>
              By use of the uniform resource locator (Web address) being linked
              to; or
            </li>
            <li>
              By use of any other description of our Web site or material being
              linked to that makes sense within the context and format of
              content on the linking party's site.
            </li>
          </ol>
        </ListItem>
        <Paragraph>
          No use of Orchestrate's logo or other artwork will be allowed for
          linking absent a trademark license agreement.
        </Paragraph>

        <SubHeading>Removal of links from our website</SubHeading>
        <Paragraph>
          If you find any link on our Web site or any linked web site
          objectionable for any reason, you may contact us about this. We will
          consider requests to remove links but will have no obligation to do so
          or to respond directly to you.
        </Paragraph>
        <Paragraph>
          Whilst we endeavor to ensure that the information on this website is
          correct, we do not warrant its completeness or accuracy; nor do we
          commit to ensuring that the website remains available or that the
          material on the website is kept up to date.
        </Paragraph>

        <SubHeading>Disclaimer</SubHeading>
        <Paragraph>
          To the maximum extent permitted by applicable law, we exclude all
          representations, warranties and conditions relating to our website and
          the use of this website (including, without limitation, any warranties
          implied by law in respect of satisfactory quality, fitness for purpose
          and/or the use of reasonable care and skill). Nothing in this
          disclaimer will:
        </Paragraph>
        <ListItem>
          <ol>
            <li>
              limit or exclude our or your liability for death or personal
              injury resulting from negligence.
            </li>
            <li>
              limit or exclude our or your liability for fraud or fraudulent
              misrepresentation.
            </li>
            <li>
              limit any of our or your liabilities in any way that is not
              permitted under applicable law; or
            </li>
            <li>
              exclude any of our or your liabilities that may not be excluded
              under applicable law.
            </li>
          </ol>
        </ListItem>
        <Paragraph>
          The limitations and exclusions of liability set out in this Section
          and elsewhere in this disclaimer: (a) are subject to the preceding
          paragraph; and (b) govern all liabilities arising under the disclaimer
          or in relation to the subject matter of this disclaimer, including
          liabilities arising in contract, in tort (including negligence) and
          for breach of statutory duty.
        </Paragraph>
        <Paragraph>
          To the extent that the website and the information and services on the
          website are provided free of charge, we will not be liable for any
          loss or damage of any nature.
        </Paragraph>

        <SubHeading>Credit & Contact Information</SubHeading>
        <Paragraph>
          This Terms of service page was created at 
          <a href='https://privacyterms.io/terms-conditions-generator/'>
            privacyterms.io terms & conditions generator
          </a>
          . If you have any queries regarding any of our terms, please contact
          us.
        </Paragraph>
      </List>
    </CardContent>
  </Card>
);

export default TermsContent;
