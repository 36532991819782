import React from 'react';

import ListItem from '@mui/material/ListItem';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TableEditColumn } from '@devexpress/dx-react-grid-material-ui';

const HeaderEditCell = ({
  disabled = true,
  tooltipText,
  handleClick,
  ...props
}) => {
  return (
    <TableEditColumn.HeaderCell {...props} style={{ padding: 1 }}>
      <ListItem sx={{ padding: 0 }}>
        <Tooltip
          title={disabled ? 'Insufficient Permissions' : tooltipText}
          placement='left'>
          <span>
            <IconButton
              aria-label='create'
              size='small'
              onClick={handleClick}
              disabled={disabled}>
              <FontAwesomeIcon icon={['fal', 'plus-circle']} />
            </IconButton>
          </span>
        </Tooltip>
      </ListItem>
    </TableEditColumn.HeaderCell>
  );
};

export default HeaderEditCell;
