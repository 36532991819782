import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import Card from '@mui/material/Card';

import {
  SortingState,
  IntegratedSorting,
  IntegratedFiltering,
  IntegratedPaging,
  EditingState,
  FilteringState,
  PagingState,
} from '@devexpress/dx-react-grid';
import { TableEditColumn } from '@devexpress/dx-react-grid-material-ui';

import ROLES, { findRole } from '../../../constants/roles';
import useMemberships from '../../../store/hooks/useMemberships';
import useVerifyOrgRole from '../../../store/hooks/useVerifyOrgRole';
import useOrgNamePredicate from '../../../store/hooks/useOrgNamePredicate';
import useCompareOrgName from '../../../store/hooks/useCompareOrgName';
import { openConfirmDialog, openDialog } from '../../../store/dialogs';
import { deleteMembership, putMembership } from '../../../store/memberships';
import BaseTable from './BaseTable';
import { EditCell, HeaderEditCell } from '../cells';
import {
  MembershipRoleTypeProvider,
  OrgIdNameLinkTypeProvider,
} from '../providers';

function MembershipsTable(props) {
  const { orgId } = props;
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.item);
  const isOrgAdmin = useVerifyOrgRole(orgId, ROLES.ADMIN.value);

  const memberships = useMemberships(orgId);
  const orgNamePredicate = useOrgNamePredicate();
  const compareOrgName = useCompareOrgName();

  const [integratedFilteringColumnExtensions] = useState([
    { columnName: 'org_id', predicate: orgNamePredicate },
  ]);
  const [integratedSortingColumnExtensions] = useState([
    { columnName: 'org_id', compare: compareOrgName },
  ]);

  const [columns] = useState([
    { title: 'Name', name: 'name', width: 0.35 },
    { title: 'Email', name: 'email', width: 0.35 },
    { title: 'Role', name: 'role', width: 0.25 },
  ]);

  const handleEditClick = (organization, value) => {
    const updatedMembership = { ...organization, role: value };
    const role = findRole(value);
    const userName = updatedMembership.name || updatedMembership.email;
    const message = (
      <div>
        <p>Are you sure you want to update this membership?</p>
        <table style={{ width: '100%' }}>
          <tbody>
            <tr>
              <td>
                <strong>User:</strong>
              </td>
              <td>{userName}</td>
            </tr>
            <tr>
              <td>
                <strong>New Role:</strong>
              </td>
              <td>{role.toLowerCase()}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );

    dispatch(
      openConfirmDialog({
        title: 'Update Membership',
        message: message,
        onConfirm: () => {
          dispatch(putMembership(updatedMembership));
        },
      })
    );
  };

  const handleDeleteClick = (membership) => {
    const message = <p>Are you sure you want to delete this membership?</p>;
    dispatch(
      openConfirmDialog({
        title: 'Delete Membership',
        message: message,
        onConfirm: () => {
          dispatch(deleteMembership(membership));
        },
      })
    );
  };

  const handleCreateClick = () => {
    dispatch(
      openDialog({
        orgId: orgId,
        type: 'membership',
        mode: 'create',
      })
    );
  };

  return (
    <Card raised sx={{ width: '100%', px: 1, pb: 0.5 }}>
      <BaseTable
        minWidth={600}
        maxWidth={1200}
        rows={memberships}
        columns={columns}
        editColumn={
          <TableEditColumn
            width={42}
            headerCellComponent={(props) => {
              return (
                <HeaderEditCell
                  disabled={!isOrgAdmin}
                  tooltipText='Create Membership'
                  handleClick={handleCreateClick}
                  {...props}
                />
              );
            }}
            cellComponent={(props) => {
              const disabled = // eslint-disable-next-line
                user?.user_id === props.row?.user_id || !isOrgAdmin;
              return (
                <EditCell
                  disabled={disabled}
                  tooltipText='Delete Membership'
                  icon={['fal', 'trash']}
                  // eslint-disable-next-line
                  handleClick={() => handleDeleteClick(props.row)}
                  {...props}
                />
              );
            }}
          />
        }>
        <OrgIdNameLinkTypeProvider for={['org_id']} />
        <MembershipRoleTypeProvider
          for={['role']}
          handleClick={handleEditClick}
          disabled={!isOrgAdmin}
        />

        <EditingState />
        <FilteringState />
        <SortingState
          defaultSorting={[
            { columnName: 'org_id', direction: 'asc' },
            { columnName: 'role', direction: 'asc' },
          ]}
        />
        <PagingState defaultCurrentPage={0} pageSize={10} />

        <IntegratedFiltering
          columnExtensions={integratedFilteringColumnExtensions}
        />
        <IntegratedSorting
          columnExtensions={integratedSortingColumnExtensions}
        />
        <IntegratedPaging />
      </BaseTable>
    </Card>
  );
}

MembershipsTable.propTypes = { orgId: PropTypes.string };

export default MembershipsTable;
