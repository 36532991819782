import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import find from 'lodash/find';
import filter from 'lodash/filter';
import map from 'lodash/map';
const defaultState = {
  org_id: '',
  name: '',
  address: '',
  location: { lat: '', lng: '' },
  timezone: {
    name: '',
    zone: '',
    offset: '',
  },
  is_portfolio: false,
};

const useOrganization = (orgId) => {
  const {
    data: organizations,
    portfolioMemberships: allPortfolioMemberships,
    loading: orgLoading,
  } = useSelector((state) => state.organizations);
  const { data: allMeters, loading: meterLoading } = useSelector(
    (state) => state.meters
  );
  const [organization, setOrganization] = useState({});
  const [meters, setMeters] = useState([]);

  useEffect(() => {
    if (orgId && orgId.startsWith('org') && organizations.length) {
      setOrganization(find(organizations, { org_id: orgId }));
    } else setOrganization(defaultState);
  }, [organizations, orgId]);

  useEffect(() => {
    if (allMeters.length) {
      if (organization?.is_portfolio) {
        const portfolioMemberships = filter(allPortfolioMemberships, {
          portfolio_id: organization.org_id,
        });
        const memberIds = map(portfolioMemberships, 'member_id');
        setMeters(
          filter(allMeters, (meter) => memberIds.includes(meter.org_id))
        );
      } else {
        setMeters(filter(allMeters, { org_id: organization?.org_id }));
      }
    }
  }, [allMeters, organization, allPortfolioMemberships]);

  return { organization, meters, loading: orgLoading || meterLoading };
};

export { defaultState };
export default useOrganization;
