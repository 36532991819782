import React from 'react';
import PropTypes from 'prop-types';

import { darken, lighten } from '@mui/material/styles';
import useTheme from '@mui/material/styles/useTheme';
import styled from '@mui/material/styles/styled';
import Skeleton from '@mui/material/Skeleton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { displaykW } from '../../helpers/display-energy';

const FullBar = styled('div')(({ theme, width }) => {
  return {
    width: `${width}px`,
    height: 15,
    display: 'flex',
    alignItems: 'flex-start',
    my: 1,
    backgroundImage: `linear-gradient(to bottom, ${
      theme.palette.veregy_colors.lightgrey50
    }, ${lighten(theme.palette.veregy_colors.lightgrey50, 0.5)})`,
    borderRadius: '4px',
  };
});

const FillBar = styled('div')(({ theme, width, color }) => ({
  borderRadius: '4px',
  height: '100%',
  overflow: 'hidden',
  backgroundImage: `linear-gradient(to bottom, ${color}, ${darken(
    color,
    0.2
  )})`,
  width,
}));

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

function PerformanceIndexChart({
  actual: actualProp,
  expected: expectedProp,
  width,
  loading,
}) {
  const theme = useTheme();

  let { actual, expected, performance } = (() => {
    let _performance = 0;
    let _actual = Number(actualProp);
    let _expected = Number(expectedProp);

    if (!isNaN(_actual) && !isNaN(_expected) && _expected > 0) {
      _performance = _actual / _expected;
    }

    return {
      actual: _actual,
      expected: _expected,
      performance: _performance,
    };
  })();

  let color = (() => {
    if (performance >= 0.75 && performance < 0.9) {
      return theme.palette.veregy_colors.yellow;
    } else if (performance >= 0.9) {
      return theme.palette.veregy_colors.green;
    }
    return theme.palette.veregy_colors.red;
  })();

  return (
    <Root>
      <Typography
        variant='body1'
        align='right'
        sx={{
          mr: 1,
          minWidth: '2rem',
        }}>
        {performance === 0 ? '' : String(Math.round(performance * 100) / 100)}
      </Typography>
      <Tooltip
        placement='top'
        title={
          <React.Fragment>
            {performance === 0 ? (
              <Typography color='inherit'>No Data</Typography>
            ) : (
              <>
                <Typography color='inherit'>{`Actual: ${displaykW(
                  actual,
                  true
                )}`}</Typography>
                <Typography color='inherit'>{`Expected: ${displaykW(
                  expected,
                  true
                )}`}</Typography>
              </>
            )}
          </React.Fragment>
        }>
        {loading ? (
          <Skeleton
            variant='rounded'
            width={width}
            height={15}
            sx={{ display: 'inline-flex', verticalAlign: 'middle' }}
          />
        ) : (
          <FullBar width={width}>
            <FillBar
              width={`${Math.min(performance * 100, 100)}%`}
              color={color}
            />
          </FullBar>
        )}
      </Tooltip>
    </Root>
  );
}

PerformanceIndexChart.propTypes = {
  actual: PropTypes.number.isRequired,
  expected: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
};

export default PerformanceIndexChart;
