import { useCallback, useEffect, useState } from 'react';

const useFitBounds = (locations) => {
  const [map, setMap] = useState(null);

  const onLoad = useCallback((map) => setMap(map), []);
  useEffect(() => {
    if (map && locations.length > 1) {
      const bounds = new window.google.maps.LatLngBounds();
      locations.map(({ location }) => {
        return bounds.extend({ lat: location.lat, lng: location.lng });
      });
      map.fitBounds(bounds);
      setMap(map);
    }
  }, [map, locations]);

  const onUnmount = useCallback((map) => {
    setMap(null);
  }, []);

  return [onLoad, onUnmount];
};

export default useFitBounds;
