import { createSlice } from '@reduxjs/toolkit';
import { buildAsyncReducers } from '../thunkTemplate';
import { sensors as initialState } from '../initialState';

import { getSensors, putSensor } from './_sensors';

// NOTE: "Mutating" state is safe in redux toolkit because it uses Immer
const { reducer } = createSlice({
  name: 'sensors',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    buildAsyncReducers(builder, [getSensors, putSensor]);
  },
});

// Export the reducer, either as a default or named export
export { getSensors, putSensor };
export default reducer;
