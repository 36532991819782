import { Table } from '@devexpress/dx-react-grid-material-ui';

const TableCell = (props) => (
  <Table.Cell
    {...props}
    style={{ padding: 0, paddingLeft: 6, paddingRight: 6, height: '3rem' }}
  />
);

export default TableCell;
