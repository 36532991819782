import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';

function DialogTitleOptions(props) {
  const { handleClose, children } = props;

  const [menuAnchor, setMenuAnchor] = useState(null);

  const handleMenuClick = (event) => {
    setMenuAnchor(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setMenuAnchor(null);
  };

  return (
    <div style={{ position: 'absolute', top: 8, right: 8 }}>
      {children?.length > 0 && (
        <>
          <IconButton onClick={handleMenuClick} sx={{ height: 30, width: 30 }}>
            <FontAwesomeIcon icon={['fal', 'ellipsis-v']} />
          </IconButton>
          <Menu
            id='settings-menu'
            anchorEl={menuAnchor}
            open={Boolean(menuAnchor)}
            onClose={handleCloseMenu}
            MenuListProps={{ disablePadding: true }}>
            {children}
          </Menu>
        </>
      )}

      <IconButton
        onClick={handleClose}
        sx={{
          color: (theme) => theme.palette.grey[500],
          width: 30,
          height: 30,
        }}>
        <FontAwesomeIcon icon={['fal', 'times']} />
      </IconButton>
    </div>
  );
}

DialogTitleOptions.propTypes = {
  handleClose: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

export default DialogTitleOptions;
