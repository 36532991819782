import React from 'react';
import { useSelector } from 'react-redux';

import useMeterDevices from '../../../../store/hooks/useMeterDevices';
import useMeter from '../../../../store/hooks/useMeter';
import useSite from '../../../../store/hooks/useSite';
import AnalysisPage from '../../Analysis';

export default function Analysis() {
  const { view, id } = useSelector((state) => state.pages.meter);
  const meter = useMeter(id);
  const site = useSite(meter?.site_id);
  const { inverters, sensors } = useMeterDevices(id);

  return (
    <AnalysisPage
      resource={meter}
      timezone={site.timezone}
      view={view}
      sites={[site]}
      meters={[meter]}
      inverters={inverters}
      sensors={sensors}
    />
  );
}
