import React from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import dayjs from 'dayjs';
import map from 'lodash/map';

import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import { StyledTd } from './BaseTooltip';

function GeneralTooltip({ active, payload, label, timezone }) {
  if (active && payload && payload.length) {
    return (
      <Card raised sx={{ width: '20rem', p: 1 }}>
        {label && (
          <Typography variant='body1' align='center'>
            {dayjs
              .unix(Number(label))
              .tz(timezone.zone)
              .format('MMM Do h:mm A')}
          </Typography>
        )}
        <table style={{ width: '100%' }}>
          <tbody>
            {map(payload.slice(0, 9), (resource) => {
              const color = resource.color;
              return (
                <tr key={resource?.dataKey}>
                  <StyledTd color={color} hideOverflow>
                    {resource.dataKey}
                  </StyledTd>
                  <StyledTd color={color} align='right'>
                    {numeral(resource.value.toPrecision(4)).format('0,0.[000]')}
                  </StyledTd>
                </tr>
              );
            })}
          </tbody>
        </table>
        {payload.length > 10 && (
          <>
            <Divider />
            <Typography variant='body2' align='center'>
              +{payload.length - 10} more. Click to view all
            </Typography>
          </>
        )}
      </Card>
    );
  }

  return null;
}

GeneralTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  label: PropTypes.number,
  timezone: PropTypes.object,
};

export default GeneralTooltip;
