import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import MaskedInput from 'react-text-mask';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import ROLES from '../../../constants/roles';
import { closeDialog } from '../../../store/dialogs';
import { putLogger } from '../../../store/loggers/_loggers';
import useVerifyOrgRole from '../../../store/hooks/useVerifyOrgRole';
import useLogger, { defaultState } from '../../../store/hooks/useLogger';
import BaseDialog from '../BaseDialog';
import { TextFieldListItem } from '../../List/TextFieldListItem';
import ClipboardField from '../../ClipboardField';

function IPAddressMask(props) {
  const { onChange, value } = props;
  return (
    <MaskedInput
      onChange={onChange}
      guide={false}
      mask={(value) => Array(value.length).fill(/[\d.]/)}
      render={(ref, innerProps) => {
        return (
          <ClipboardField
            ref={ref}
            id='ip-address'
            value={value}
            label='IP Address'
            {...innerProps}
          />
        );
      }}
    />
  );
}

function LoggerDialog() {
  const dispatch = useDispatch();
  const { id } = useSelector((state) => state.dialogs.logger);

  const loggerItem = useLogger(id);

  const [logger, setLogger] = useState(defaultState);
  const isOrgEditor = useVerifyOrgRole(logger?.org_id, ROLES.EDITOR.value);

  useEffect(() => {
    setLogger(loggerItem);
  }, [loggerItem]);

  const handleChange = (e) => {
    setLogger((prevLogger) => ({
      ...prevLogger,
      [e.target.id]: e.target.value,
    }));
  };

  const handleClose = () => {
    dispatch(closeDialog('logger'));
    setLogger(defaultState);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(putLogger(logger));
    handleClose();
  };

  return (
    <BaseDialog
      id={id}
      title='Update Logger'
      disabled={!isOrgEditor}
      handleSubmit={handleSubmit}
      handleClose={handleClose}>
      <form
        onSubmit={handleSubmit}
        onKeyDown={(event) => {
          if (event?.key === 'Enter') {
            handleSubmit(event);
          }
        }}>
        <List disablePadding>
          <ListItem disableGutters>
            <ClipboardField
              id='logger-id-value'
              value={logger.logger_id}
              label='Logger ID'
            />
          </ListItem>
          <ListItem disableGutters>
            <IPAddressMask
              fullWidth
              variant='standard'
              id='ip_address'
              label='IP Address'
              value={logger.ip_address}
              onChange={handleChange}
            />
          </ListItem>
          <TextFieldListItem
            id='name'
            label='Logger Name'
            value={logger.name}
            onChange={handleChange}
          />
          <ListItem disableGutters>
            <FormControl id='ingestion_method' variant='standard' fullWidth>
              <InputLabel id='ingestion_method-label'>
                Data Ingestion Method
              </InputLabel>
              <Select
                labelId='ingestion_method-label'
                name='ingestion_method'
                value={logger?.ingestion_method || ''}
                onChange={handleChange}
                MenuProps={{
                  MenuListProps: { disablePadding: true },
                  slotProps: {
                    paper: { sx: { minWidth: 'unset !important' } },
                  },
                }}
                sx={{
                  backgroundColor: 'unset',
                  '& .MuiSelect-select': {
                    display: 'flex',
                    alignItems: 'center',
                  },
                }}>
                {['vpn', 'public', 'api', 'demo'].map((method) => {
                  return (
                    <MenuItem key={method} value={method}>
                      {method}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </ListItem>
          <ListItem disableGutters sx={{ pb: 0 }}>
            <ListItemButton
              sx={{ pb: 0 }}
              disableRipple
              role={undefined}
              onClick={(e) =>
                handleChange({
                  target: { id: 'active', value: !logger.active },
                })
              }
              dense>
              <ListItemIcon>
                <Checkbox edge='start' checked={logger.active} tabIndex={-1} />
              </ListItemIcon>
              <ListItemText primary={logger.active ? 'Active' : 'Inactive'} />
            </ListItemButton>
          </ListItem>
        </List>
      </form>
    </BaseDialog>
  );
}

export default LoggerDialog;
