import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

const ConfigurationAlert = (props) => {
  const { portfolio, portfolioMembers } = props;
  const loading = useSelector((state) => state.loadingBar);

  const user = useSelector((state) => state.user.item);
  const [message, setMessage] = useState('');
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (user !== null && loading?.default === 0) {
      if (portfolio?.org_id === '') {
        setMessage('This account does not have any memberships');
      } else if (portfolioMembers.length === 0 && portfolio?.name) {
        setMessage(`${portfolio?.name} has no organizations configured`);
      } else {
        setMessage('');
      }
    }
  }, [loading.default, user, portfolio, portfolioMembers.length]);

  useEffect(() => {
    if (message !== '') {
      const timer = setTimeout(() => {
        setShow(true);
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [message]);

  if (!show || message === '') return null;
  return (
    <Grid item xs={12}>
      <Alert
        variant='filled'
        severity='warning'
        sx={{
          '& .MuiAlert-message': { width: '100%' },
          alignItems: 'center',
        }}>
        <Typography variant='body1'>{message}</Typography>
        <Typography variant='body1'>
          Please contact a LightLevel administrator for further assitance
        </Typography>
      </Alert>
    </Grid>
  );
};

ConfigurationAlert.propTypes = {
  portfolio: PropTypes.object.isRequired,
  portfolioMembers: PropTypes.array.isRequired,
};
export default ConfigurationAlert;
