import { createSlice } from '@reduxjs/toolkit';
import { buildAsyncReducers } from '../thunkTemplate';
import { meters as initialState } from '../initialState';

import { getMeters, putMeter } from './_meters';

// NOTE: "Mutating" state is safe in redux toolkit because it uses Immer
const { reducer } = createSlice({
  name: 'meters',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    buildAsyncReducers(builder, [getMeters, putMeter]);
  },
});

// Export the reducer, either as a default or named export
export { getMeters, putMeter };
export default reducer;
