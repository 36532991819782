import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import has from 'lodash/has';
import head from 'lodash/head';
import toUpper from 'lodash/toUpper';

import Grid from '@mui/material/Grid';

import ViewStack from '../../../components/ViewStack';
import {
  ESTIMATED,
  METER,
  PERFORMANCE,
  PRODUCTION,
  WEATHER,
} from '../../../components/charts/analysis/views';
import ProductionProfileChartContainer from '../../../components/charts/analysis/ProductionProfileChart/Container';
import PerformanceChartContainer from '../../../components/charts/analysis/PerformanceChart/Container';
import EstimatedChartContainer from '../../../components/charts/analysis/EstimatedChart/Container';
import WeatherStationChartContainer from '../../../components/charts/analysis/WeatherStationChart/Container';
import MeterChart from '../../../components/charts/analysis/MeterChart';

function AnalysisPage(props) {
  const { resource, timezone, view, sites, meters, inverters, sensors } = props;

  const [views, setViews] = useState([]);
  const [selectedView, setSelectedView] = useState(PRODUCTION);

  useEffect(() => {
    if (has(views, toUpper(view))) {
      setSelectedView(view);
    } else if (view === '') {
      if (resource?.type_ === 'organization' && resource?.is_portfolio) {
        setSelectedView(PERFORMANCE);
      } else {
        setSelectedView(PRODUCTION);
      }
    }
  }, [view, views, resource]);

  useEffect(() => {
    const type = get(resource, 'type_', 'organization');
    if (type === 'organization' && get(resource, 'is_portfolio', false)) {
      setViews([PERFORMANCE]);
    } else if (type === 'organization') {
      setViews([PRODUCTION, PERFORMANCE, WEATHER]);
    } else if (type === 'site') {
      setViews([PRODUCTION, PERFORMANCE, ESTIMATED, WEATHER]);
    } else if (type === 'logger') {
      setViews([PRODUCTION, WEATHER]);
    } else if (type === 'meter') {
      setViews([PRODUCTION, METER]);
    }
  }, [resource]);

  return (
    <Grid
      container
      spacing={1}
      direction='row'
      justifyContent='center'
      alignItems='center'
      sx={{ mt: '4px', px: 2 }}>
      <Grid item xs={12}>
        <ViewStack
          selectedView={selectedView}
          setSelectedView={setSelectedView}
          views={views}
        />
      </Grid>
      {selectedView === PRODUCTION && (
        <ProductionProfileChartContainer
          defaultTimezone={timezone}
          meters={meters}
          inverters={inverters}
        />
      )}
      {selectedView === PERFORMANCE && (
        <PerformanceChartContainer sites={sites} meters={meters} />
      )}
      {selectedView === ESTIMATED && (
        <EstimatedChartContainer
          defaultTimezone={timezone}
          site={head(sites)}
        />
      )}
      {selectedView === WEATHER && (
        <WeatherStationChartContainer
          defaultTimezone={timezone}
          meters={meters}
          sensors={sensors}
        />
      )}
      {selectedView === METER && (
        <MeterChart site={head(sites)} meter={resource} />
      )}
    </Grid>
  );
}

AnalysisPage.propTypes = {
  resource: PropTypes.object,
  timezone: PropTypes.object,
  view: PropTypes.string,
  sites: PropTypes.array,
  meters: PropTypes.array,
  inverters: PropTypes.array,
  sensors: PropTypes.array,
};

export default AnalysisPage;
