import React from 'react';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';

export const AlarmStatusTypeProvider = (props) => {
  return (
    <DataTypeProvider
      formatterComponent={({ value }) => {
        const icon = value ? 'bell-on' : 'bell-slash';

        return (
          <Tooltip title={value ? 'active' : 'inactive'} placement='top'>
            <Box
              style={{
                display: 'flex',
                justifyContent: 'left',
                alignItems: 'center',
              }}>
              <FontAwesomeIcon
                icon={['fal', icon]}
                style={{ height: 25, width: 25 }}
              />
            </Box>
          </Tooltip>
        );
      }}
      {...props}
    />
  );
};
