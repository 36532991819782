import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import filter from 'lodash/filter';

const useDeviceAlarms = (deviceId) => {
  const allAlarms = useSelector((state) => state.alarms.data);
  const [alarms, setAlarms] = useState([]);

  useEffect(() => {
    setAlarms(filter(allAlarms, (alarm) => alarm.device_id === deviceId));
  }, [deviceId, allAlarms]);

  return alarms;
};

export default useDeviceAlarms;
