import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTheme } from '@emotion/react';
import styled from '@mui/material/styles/styled';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';

const SearchIconWrapper = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
}));

const XmarkIconWrapper = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
}));

const MinusCircleIconWrapper = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  paddingRight: 2,
}));

export default function SearchBar(props) {
  const { searchText, setSearchText, setExpanded } = props;
  const theme = useTheme();

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  return (
    <Stack direction='row' sx={{ ml: 1 }}>
      <SearchIconWrapper>
        <FontAwesomeIcon
          icon={['fal', 'search']}
          color={theme.palette.text.secondary}
        />
      </SearchIconWrapper>
      <InputBase
        placeholder='Search…'
        value={searchText}
        onChange={handleSearchChange}
        sx={{ width: '100%', pl: 1, color: theme.palette.text.secondary }}
      />
      <XmarkIconWrapper>
        <Tooltip placement='top' title='clear'>
          <IconButton
            onClick={() => setSearchText('')}
            sx={{ height: 25, width: 25 }}>
            <FontAwesomeIcon
              icon={['fal', 'xmark']}
              color={theme.palette.text.secondary}
              size='xs'
              style={{ paddingLeft: 1 }}
            />
          </IconButton>
        </Tooltip>
      </XmarkIconWrapper>
      <Divider orientation='vertical' />
      <MinusCircleIconWrapper>
        <Tooltip placement='top' title='collapse'>
          <IconButton
            onClick={() => setExpanded([])}
            sx={{ height: 25, width: 25 }}>
            <FontAwesomeIcon
              icon={['fal', 'minus-circle']}
              color={theme.palette.text.secondary}
              size='xs'
              style={{ paddingLeft: 1 }}
            />
          </IconButton>
        </Tooltip>
      </MinusCircleIconWrapper>
    </Stack>
  );
}
