import React from 'react';
import PropTypes from 'prop-types';

import useTheme from '@mui/material/styles/useTheme';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

function SelectAttributes(props) {
  const { selectedAttributes, setSelectedAttributes, attributes } = props;
  const theme = useTheme();

  const handleSelect = (e) => {
    e.preventDefault();
    setSelectedAttributes(e.target.value);
  };

  return (
    <Select
      displayEmpty
      multiple
      disableUnderline
      id='attribute-select'
      renderValue={() => 'Attributes'}
      value={selectedAttributes}
      variant='standard'
      onChange={handleSelect}
      sx={{ minWidth: '4rem' }}
      IconComponent={() => null}
      inputProps={{
        sx: {
          padding: '0 !important',
          color: theme.palette.primary.main,
          ...theme.typography.body2,
        },
      }}
      MenuProps={{ MenuListProps: { disablePadding: true } }}>
      {attributes.map((attribute) => {
        return (
          <MenuItem key={attribute} value={attribute}>
            <Checkbox
              sx={{ p: '4px' }}
              checked={selectedAttributes.indexOf(attribute) > -1}
            />
            <ListItemText primary={attribute} />
          </MenuItem>
        );
      })}
    </Select>
  );
}

SelectAttributes.propTypes = {
  selectedAttributes: PropTypes.array,
  setSelectedAttributes: PropTypes.func,
  attributes: PropTypes.array,
};

export default SelectAttributes;
