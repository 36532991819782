import { APP_ID } from '.';

const WEBCLIENT_ID = process.env.REACT_APP_USER_POOL_WEBCLIENT_ID;
const REFRESH_TOKEN = 'RefreshToken';
const ACCESS_TOKEN = 'AccessToken';
const ID_TOKEN = 'IdToken';
const EXPR_TIME = 'ExprTime';

const getTokens = () => {
  return {
    refreshToken: localStorage.getItem(
      `${APP_ID}.${WEBCLIENT_ID}.${REFRESH_TOKEN}`
    ),
    accessToken: localStorage.getItem(
      `${APP_ID}.${WEBCLIENT_ID}.${ACCESS_TOKEN}`
    ),
    idToken: localStorage.getItem(`${APP_ID}.${WEBCLIENT_ID}.${ID_TOKEN}`),
    exprTime: localStorage.getItem(`${APP_ID}.${WEBCLIENT_ID}.${EXPR_TIME}`),
  };
};

const setTokens = (payload) => {
  if ('ExpiresIn' in payload) {
    const exprTime =
      Math.round(new Date().getTime() / 1000) + payload.ExpiresIn;
    localStorage.setItem(`${APP_ID}.${WEBCLIENT_ID}.${EXPR_TIME}`, exprTime);
  }
  for (const token of [ACCESS_TOKEN, ID_TOKEN, REFRESH_TOKEN]) {
    if (!(token in payload)) {
      continue;
    }
    localStorage.setItem(`${APP_ID}.${WEBCLIENT_ID}.${token}`, payload[token]);
  }
};

const removeTokens = () => {
  for (const token of [ACCESS_TOKEN, ID_TOKEN, REFRESH_TOKEN, EXPR_TIME]) {
    localStorage.removeItem(`${APP_ID}.${WEBCLIENT_ID}.${token}`);
  }
};

export { setTokens, getTokens, removeTokens };
