import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { displaykW } from '../../../../helpers/display-energy';

function ProductionSumItem({ label, value }) {
  return (
    <>
      <Grid item xs={5}>
        <Typography gutterBottom variant='body1' align='left'>
          {label}
        </Typography>
      </Grid>
      <Grid item xs={7}>
        <Typography gutterBottom variant='body1' align='right'>
          {displaykW(value, true)}
        </Typography>
      </Grid>
    </>
  );
}

ProductionSumItem.propTypes = {
  label: PropTypes.string,
  value: PropTypes.number,
};

export default ProductionSumItem;
