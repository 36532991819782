import React from 'react';

import ListItem from '@mui/material/ListItem';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TableEditColumn } from '@devexpress/dx-react-grid-material-ui';

export const HeaderEditCell = ({
  disabled = true,
  handleInsertYear,
  expectations,
  ...props
}) => {
  return (
    <TableEditColumn.HeaderCell {...props} style={{ padding: 1 }}>
      <ListItem sx={{ padding: 0 }}>
        <Tooltip title={disabled ? 'Insufficient Permissions' : 'Add Year'}>
          <span>
            <IconButton
              aria-label='create'
              onClick={handleInsertYear}
              disabled={disabled}>
              <FontAwesomeIcon icon={['fal', 'plus-circle']} size='xs' />
            </IconButton>
          </span>
        </Tooltip>
      </ListItem>
    </TableEditColumn.HeaderCell>
  );
};
