import { TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';

const HeaderCell = (props) => (
  <TableHeaderRow.Cell
    {...props}
    style={{ padding: 0, paddingLeft: 6, paddingRight: 6, height: '3rem' }}
  />
);

export default HeaderCell;
