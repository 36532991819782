import React from 'react';
import PropTypes from 'prop-types';

import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
function CustomLink(props) {
  const { handleClick, children } = props;

  return (
    <Link underline='hover' variant='h6' onClick={handleClick}>
      <Typography variant='h6' align='center'>
        {children}
      </Typography>
    </Link>
  );
}

CustomLink.propTypes = {
  handleClick: PropTypes.func.isRequired,
  children: PropTypes.string.isRequired,
};

export default CustomLink;
