import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import find from 'lodash/find';
import forEach from 'lodash/forEach';
import get from 'lodash/get';
import reduce from 'lodash/reduce';

import { darken } from '@mui/material/styles';
import useTheme from '@mui/material/styles/useTheme';
import styled from '@mui/material/styles/styled';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { Pie, PieChart, ResponsiveContainer } from 'recharts';

import { displaykW } from '../../helpers/display-energy';
import WidgetLoader from './Loader';
import { currentGeneration } from '../../helpers/chart-data';
import InfoButton from '../charts/buttons/InfoButton';
import WarningIcon from '../WarningIcon';

const BottomContent = styled('div')(({ theme }) => ({
  display: 'flex',
  marginLeft: theme.spacing(1),
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '40px',
}));

const BottomPanel = styled('div')(({ theme }) => ({
  height: 10,
  width: '100%',
  bottom: 0,
  backgroundColor: theme.palette.veregy_colors.orange,
}));

const calculateGenerationEndAngle = (generation, capacity) => {
  if (capacity === 0 || generation === 0) return 180;
  if (generation > capacity) return 0;
  return ((generation / capacity) * 180 - 180) * -1;
};

function CurrentGeneration(props) {
  const { loggers, meters, inverters, loading } = props;
  const theme = useTheme();
  const { data: timeseries, loading: timeseriesLoading } = useSelector(
    (state) => state.timeseries
  );

  const [capacity, setCapacity] = useState(0);
  const [generation, setGeneration] = useState(0);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    let _errors = [];
    forEach(inverters, (inverter) => {
      if (!inverter?.ac_size) {
        _errors.push({
          id: inverter.inverter_id,
          message: `AC Size not set`,
        });
      }
    });
    setErrors(_errors);
  }, [inverters]);

  useEffect(() => {
    const meterIds = meters.map((meter) => meter.meter_id);
    const timeseriesData = timeseries.filter((item) => {
      return meterIds.includes(item?.device_id) && item?.data_type === 'recent';
    });
    setGeneration(currentGeneration(timeseriesData));
    setCapacity(
      reduce(
        inverters,
        (acc, inverter) => {
          const meter = find(meters, { meter_id: inverter?.meter_id });
          const logger = find(loggers, { logger_id: meter?.logger_id });
          if (logger?.active) {
            return acc + get(inverter, 'ac_size', 0);
          } else {
            return acc;
          }
        },
        0
      )
    );
  }, [loggers, meters, inverters, timeseries]);

  let cy = '80%';
  let innerRadius = 50;
  let outerRadius = 95;
  let endAngle = calculateGenerationEndAngle(generation, capacity);

  return (
    <Card raised sx={{ width: '100%' }}>
      <ResponsiveContainer width='100%' height={154}>
        <PieChart
          width={350}
          height={170}
          margin={{ top: 0, left: 0, bottom: 0, right: 0 }}>
          <defs>
            <linearGradient id='gradient' x1='0' y1='1' x2='1' y2='0'>
              <stop
                offset='0%'
                stopColor={darken(theme.palette.veregy_colors.orange, 0.15)}
                stopOpacity={1}
              />
              <stop
                offset='100%'
                stopColor={theme.palette.veregy_colors.orange}
                stopOpacity={1}
              />
            </linearGradient>
          </defs>
          <Pie
            data={[{ name: 'background', value: 1 }]}
            startAngle={180}
            endAngle={0}
            dataKey='value'
            nameKey='name'
            cy={cy}
            innerRadius={innerRadius}
            outerRadius={outerRadius}
            fill={theme.palette.veregy_colors.lightgrey50}
            stroke='transparent'
          />
          <Pie
            data={[{ name: 'generation', value: 1 }]}
            startAngle={180}
            endAngle={endAngle}
            dataKey='value'
            nameKey='name'
            cy={cy}
            innerRadius={innerRadius}
            outerRadius={outerRadius}
            stroke='transparent'
            fill='url(#gradient)'
          />
        </PieChart>
      </ResponsiveContainer>
      <Box
        sx={{
          position: 'relative',
          top: -90,
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
        }}>
        <ListItem disablePadding sx={{ height: 0, width: '80%' }}>
          <ListItemText
            primary={displaykW(generation)}
            secondary='Generation'
          />
          <ListItemText
            primary={displaykW(capacity)}
            secondary='Capacity'
            primaryTypographyProps={{ align: 'right' }}
            secondaryTypographyProps={{ align: 'right' }}
          />
        </ListItem>
      </Box>
      <BottomContent>
        <Typography align='left' variant='h5'>
          Current Generation
        </Typography>
        {loading || timeseriesLoading ? (
          <WidgetLoader delay={250} />
        ) : (
          <div style={{ display: 'flex' }}>
            <WarningIcon errors={errors} />
            <InfoButton
              title='Current Generation'
              content={
                <>
                  <Typography variant='body2' color='text.secondary'>
                    Shows the total kilowatt-hour production from the most
                    recent 15 minute interval.
                  </Typography>
                  <Typography variant='body2' color='text.secondary'>
                    Capacity is based on total inverter AC size.
                  </Typography>
                </>
              }
            />
          </div>
        )}
      </BottomContent>
      <BottomPanel />
    </Card>
  );
}

CurrentGeneration.propTypes = {
  loggers: PropTypes.array.isRequired,
  meters: PropTypes.array.isRequired,
  inverters: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default CurrentGeneration;
