import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

function CountGrid({ number, name }) {
  return (
    <Grid container alignItems='center' direction='column'>
      <Grid item xs>
        <Typography gutterBottom variant={'h3'}>
          {number}
        </Typography>
      </Grid>
      <Grid item xs>
        <Typography gutterBottom variant='body1'>
          {name}
        </Typography>
      </Grid>
    </Grid>
  );
}

CountGrid.propTypes = {
  number: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
};

export default CountGrid;
