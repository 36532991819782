import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import find from 'lodash/find';
import get from 'lodash/get';
import startCase from 'lodash/startCase';

import useTheme from '@mui/material/styles/useTheme';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import ROLES from '../../../constants/roles';
import { closeDialog, openDialog } from '../../../store/dialogs';
import {
  createTriggerDevices,
  deleteTriggerDevices,
} from '../../../store/alarms';
import {
  createSubscriptions,
  deleteSubscriptions,
} from '../../../store/user/_users';
import useVerifyOrgRole from '../../../store/hooks/useVerifyOrgRole';
import useDevice from '../../../store/hooks/useDevice';
import useTrigger from '../../../store/hooks/useTrigger';
import { TextFieldListItem } from '../../List/TextFieldListItem';
import BaseDialog from '../BaseDialog';

function TriggerDeviceSubscriptionDialog() {
  const theme = useTheme();
  const dispatch = useDispatch();

  const { item: user, subscriptions } = useSelector((state) => state.user);
  const triggerDevice = useSelector((state) => state.dialogs.trigger_device);

  const trigger = useTrigger(triggerDevice.trigger_id);
  const device = useDevice(triggerDevice.device_id);
  const isOrgEditor = useVerifyOrgRole(
    triggerDevice.org_id,
    ROLES.EDITOR.value
  );

  const [active, setActive] = useState(false);
  const [subscribed, setSubscribed] = useState(false);

  useEffect(() => {
    setActive(!!triggerDevice.trigger_device_id);
  }, [triggerDevice.trigger_device_id]);

  useEffect(() => {
    setSubscribed(!!triggerDevice.subscription_id);
  }, [triggerDevice.subscription_id]);

  const handleActiveClick = () => {
    if (active) {
      setSubscribed(false);
    }
    setActive(!active);
  };

  const handleClose = () => {
    dispatch(closeDialog('trigger_device'));
    setActive(false);
    setSubscribed(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (active !== !!triggerDevice.trigger_device_id) {
      if (active) {
        const devices = [{ ...device, trigger_id: trigger.trigger_id }];
        dispatch(createTriggerDevices(devices));
      } else {
        dispatch(deleteTriggerDevices([triggerDevice]));
      }
    }

    if (subscribed !== !!triggerDevice.subscription_id) {
      if (subscribed) {
        dispatch(
          createSubscriptions([
            {
              userId: user.user_id,
              orgId: device.org_id,
              deviceId: triggerDevice.device_id,
              triggerId: triggerDevice.trigger_id,
            },
          ])
        );
      } else {
        const subscription = find(subscriptions, {
          trigger_id: triggerDevice.trigger_id,
          device_id: triggerDevice.device_id,
        });
        dispatch(deleteSubscriptions([subscription]));
      }
    }

    handleClose();
  };

  return (
    <BaseDialog
      id={triggerDevice.trigger_id}
      title='Update Alarm Settings'
      disabled={
        active === !!triggerDevice.trigger_device_id &&
        subscribed === triggerDevice.subscription_id
      }
      handleSubmit={handleSubmit}
      handleClose={handleClose}>
      <List disablePadding>
        <ListItem disableGutters>
          <Link
            underline='hover'
            sx={{ color: theme.palette.veregy_colors.blue }}
            onClick={() => {
              handleClose();
              dispatch(
                openDialog({
                  type: 'trigger',
                  id: trigger.trigger_id,
                })
              );
            }}>
            {startCase(get(trigger, 'trigger_id')) + ' Trigger'}
          </Link>
        </ListItem>
        <TextFieldListItem
          label='Device'
          value={device.name || ''}
          InputProps={{
            readOnly: true,
            disableUnderline: true,
            fullWidth: true,
          }}
        />
        <ListItem disableGutters>
          <ListItemButton onClick={handleActiveClick} dense>
            <ListItemIcon
              sx={{
                height: 15,
                '&.MuiListItemIcon-root': { minWidth: 40 },
              }}>
              <Checkbox
                edge='start'
                checked={active}
                tabIndex={-1}
                disableRipple
                disabled={!isOrgEditor}
              />
            </ListItemIcon>
            <ListItemText
              id='active'
              primary={`Alarm trigger ${active ? 'active' : 'inactive'}`}
            />
          </ListItemButton>
        </ListItem>
        <ListItem disableGutters>
          <ListItemButton
            disabled={!active}
            onClick={() => setSubscribed(!subscribed)}
            dense>
            <ListItemIcon
              sx={{
                height: 15,
                '&.MuiListItemIcon-root': { minWidth: 40 },
              }}>
              <Checkbox
                edge='start'
                checked={subscribed}
                tabIndex={-1}
                disableRipple
              />
            </ListItemIcon>
            <ListItemText
              id='subscribed'
              primary={`${user?.email} ${
                subscribed ? 'is subscribed' : 'is not subscribed'
              }`}
            />
          </ListItemButton>
        </ListItem>
      </List>
    </BaseDialog>
  );
}

export default TriggerDeviceSubscriptionDialog;
