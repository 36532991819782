import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import cloneDeep from 'lodash/cloneDeep';
import filter from 'lodash/filter';
import get from 'lodash/get';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import sortBy from 'lodash/sortBy';

import Card from '@mui/material/Card';
import {
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  SortingState,
} from '@devexpress/dx-react-grid';

import {
  compileCorrectedPerformanceIndexes,
  compilePerformanceIndexes,
} from '../../../helpers/performance-data';
import { currentGeneration } from '../../../helpers/chart-data';
import useCompareOrgName from '../../../store/hooks/useCompareOrgName';
import useOrgNamePredicate from '../../../store/hooks/useOrgNamePredicate';
import BaseTable from './BaseTable';
import {
  AlarmBadgeTypeProvider,
  CurrentGenerationTypeProvider,
  OrgIdNameLinkTypeProvider,
  PIChartTypeProvider,
} from '../providers';

function OrganizationsTable(props) {
  const { children, sites, meters, inverters, alarms, expectationType } = props;
  const tableRef = useRef(null);
  const compareOrgName = useCompareOrgName();
  const orgNamePredicate = useOrgNamePredicate();
  const width = tableRef?.current?.offsetWidth;
  const timeseries = useSelector((state) => state.timeseries.data);

  const [organizations, setOrganizations] = useState([]);
  const [integratedFilteringColumnExtensions] = useState([
    { columnName: 'org_id', predicate: orgNamePredicate },
  ]);
  const [integratedSortingColumnExtensions] = useState([
    { columnName: 'org_id', compare: compareOrgName },
  ]);

  const [columns] = useState([
    { title: ' ', name: 'alarms', width: 0.05 },
    { title: 'Organization', name: 'org_id', width: 0.17 },
    { title: 'Meters', name: 'meters', width: 0.07 },
    { title: 'Current Generation', name: 'current', width: 0.17 },
    { title: 'This Month', name: 'thisMonth', width: 0.17 },
    { title: 'Last Month', name: 'lastMonth', width: 0.17 },
    { title: 'Last 12 months', name: 'trailing', width: 0.17 },
  ]);

  const [tableColumnExtensions] = useState([
    { columnName: 'alarms', align: 'center' },
    { columnName: 'org_id', align: 'left' },
    { columnName: 'meters', align: 'right' },
    { columnName: 'current', align: 'right' },
    { columnName: 'thisMonth', align: 'center' },
    { columnName: 'lastMonth', align: 'center' },
    { columnName: 'trailing', align: 'center' },
  ]);

  useEffect(() => {
    const _meters = cloneDeep(meters);
    let _organizations = map(children, (organization) => {
      const orgAlarms = filter(alarms, (alarm) => {
        return (
          get(alarm, 'org_id') === get(organization, 'org_id') && alarm.status
        );
      });
      const orgSites = filter(sites, (site) => {
        return site?.org_id === organization?.org_id;
      });
      const orgMeters = filter(_meters, (meter) => {
        return meter?.org_id === organization?.org_id;
      });
      const orgInverters = filter(inverters, {
        org_id: organization?.org_id,
      });

      let expectationSummaries = map(orgSites, (site) => {
        return get(
          site,
          expectationType === 'corrected'
            ? 'wc_expectations'
            : 'modeled_expectations',
          []
        );
      });
      let performanceData = { thisMonth: 0, lastMonth: 0, trailing12Month: 0 };

      if (expectationType === 'corrected') {
        performanceData =
          compileCorrectedPerformanceIndexes(expectationSummaries);
      } else {
        performanceData = compilePerformanceIndexes(
          orgMeters,
          expectationSummaries
        );
      }

      const meterIds = orgMeters.map((meter) => meter.meter_id);
      const timeseriesData = timeseries.filter((item) => {
        return meterIds.includes(item.device_id) && item.data_type === 'recent';
      });

      const current = {
        generation: currentGeneration(timeseriesData),
        capacity: reduce(
          orgInverters,
          (acc, inverter) => {
            return acc + get(inverter, 'ac_size', 0);
          },
          0
        ),
      };

      return {
        alarms: orgAlarms,
        org_id: organization?.org_id,
        meters: orgMeters.length,
        thisMonth: performanceData.thisMonth,
        lastMonth: performanceData.lastMonth,
        trailing: performanceData.trailing12Month,
        current,
      };
    });

    setOrganizations(sortBy(_organizations, 'name'));
  }, [children, alarms, sites, meters, inverters, expectationType, timeseries]);

  return (
    <Card raised sx={{ maxWidth: '96vw', p: 1, pb: 0.5 }}>
      <div ref={tableRef}>
        <BaseTable
          minWidth={900}
          maxWidth={1600}
          rows={organizations}
          columns={columns}
          tableColumnExtensions={tableColumnExtensions}>
          <OrgIdNameLinkTypeProvider for={['org_id']} />
          <AlarmBadgeTypeProvider for={['alarms']} />
          <PIChartTypeProvider
            for={['thisMonth', 'lastMonth', 'trailing']}
            width={width * (15 / 100)}
          />
          <CurrentGenerationTypeProvider for={['current']} />

          <FilteringState />
          <IntegratedFiltering
            columnExtensions={integratedFilteringColumnExtensions}
          />

          <SortingState
            defaultSorting={[{ columnName: 'org_id', direction: 'asc' }]}
          />
          <IntegratedSorting
            columnExtensions={integratedSortingColumnExtensions}
          />

          <PagingState defaultCurrentPage={0} pageSize={10} />
          <IntegratedPaging />
        </BaseTable>
      </div>
    </Card>
  );
}

OrganizationsTable.propTypes = {
  children: PropTypes.array,
  sites: PropTypes.array,
  meters: PropTypes.array,
  inverters: PropTypes.array,
  alarms: PropTypes.array,
  expectationType: PropTypes.string,
};

export default OrganizationsTable;
