import { createAsyncThunk } from '@reduxjs/toolkit';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { toastr } from 'react-redux-toastr';
import dayjs from 'dayjs';

import cloneDeep from 'lodash/cloneDeep';
import concat from 'lodash/concat';
import get from 'lodash/get';
import includes from 'lodash/includes';
import map from 'lodash/map';
import remove from 'lodash/remove';

import WebAPIClient, { errorResponseToastr } from '../../api';

const getAlarms = createAsyncThunk(
  'alarms/getAlarms',
  async (_, { dispatch, getState, requestId }) => {
    const { currentRequestId, loading } = getState().alarms;

    if (loading !== true || requestId !== currentRequestId) {
      return;
    }

    try {
      dispatch(showLoading());
      const alarms = await new WebAPIClient().GET('/resource/alarms', {
        status: true,
      });
      const triggers = await new WebAPIClient().GET('/resource/alarm_triggers');
      const triggerDevices = await new WebAPIClient().GET(
        `/resource/trigger_devices`
      );

      return {
        data: alarms,
        triggers,
        triggerDevices,
        lastUpdate: dayjs(),
      };
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

const getAllAlarms = createAsyncThunk(
  'alarms/getAllAlarms',
  async (_, { dispatch, getState, requestId }) => {
    const { currentRequestId, loading } = getState().alarms;
    const { item: user } = getState().user;

    if (loading !== true || requestId !== currentRequestId || !user) {
      return;
    }

    try {
      dispatch(showLoading());
      const allAlarms = await new WebAPIClient().GET('/resource/alarms');
      return { data: allAlarms };
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

const acknowledgeAlarm = createAsyncThunk(
  'alarms/acknowledgeAlarm',
  async (alarm, { dispatch, getState, requestId }) => {
    const { currentRequestId, loading, data: allAlarms } = getState().alarms;

    if (loading !== true || requestId !== currentRequestId) {
      return;
    }

    let alarms = cloneDeep(allAlarms);
    let alarmId = get(alarm, 'alarm_id');

    try {
      dispatch(showLoading());
      const updatedAlarm = await new WebAPIClient().PUT(
        `/resource/alarms/${alarmId}`,
        {
          action: 'ack',
        }
      );

      remove(alarms, { alarm_id: alarmId });
      alarms = concat(alarms, updatedAlarm);

      toastr.success('Alarm Acknowledged');
      return { data: alarms };
    } catch (err) {
      toastr.error('Error', get(err, 'response.data.reason', err));
    } finally {
      dispatch(hideLoading());
    }
  }
);

const closeAlarm = createAsyncThunk(
  'alarms/closeAlarm',
  async (alarm, { dispatch, getState, requestId }) => {
    const { currentRequestId, loading, data: allAlarms } = getState().alarms;

    if (loading !== true || requestId !== currentRequestId) {
      return;
    }

    let alarms = cloneDeep(allAlarms);
    let alarmId = get(alarm, 'alarm_id');

    try {
      dispatch(showLoading());
      const updatedAlarm = await new WebAPIClient().PUT(
        `/resource/alarms/${alarmId}`,
        {
          action: 'close',
        }
      );

      remove(alarms, { alarm_id: alarmId });
      alarms = concat(alarms, updatedAlarm);

      toastr.success('Alarm closed');
      return { data: alarms };
    } catch (err) {
      toastr.error('Error', get(err, 'response.data.reason', err));
    } finally {
      dispatch(hideLoading());
    }
  }
);

const deleteAlarm = createAsyncThunk(
  'alarms/deleteAlarm',
  async (alarm, { dispatch, getState, requestId }) => {
    const { currentRequestId, loading, data: allAlarms } = getState().alarms;

    if (loading !== true || requestId !== currentRequestId) {
      return;
    }

    let alarms = cloneDeep(allAlarms);
    let alarmId = get(alarm, 'alarm_id');

    try {
      dispatch(showLoading());
      await new WebAPIClient().DELETE(`/resource/alarms/${alarmId}`);

      toastr.success('Alarm deleted');
      remove(alarms, { alarm_id: alarmId });
      return { data: alarms };
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

// WEBSOCKET
const handleInsertAlarm = createAsyncThunk(
  'alarms/handleInsertAlarm',
  async (message, { dispatch, getState, requestId }) => {
    const { currentRequestId, loading, data: allAlarms } = getState().alarms;
    if (loading !== true || requestId !== currentRequestId) {
      return;
    }

    let alarms = cloneDeep(allAlarms);

    const orgId = get(message, 'org_id');
    const alarmId = get(message, 'alarm_id');
    try {
      dispatch(showLoading());
      const newAlarm = await new WebAPIClient().GET(
        `/resource/alarms/${orgId}/${alarmId}`
      );

      alarms = concat(alarms, newAlarm);
      return { data: alarms };
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

const handleModifyAlarm = createAsyncThunk(
  'alarms/handleModifyAlarm',
  async (message, { dispatch, getState, requestId }) => {
    const { currentRequestId, loading, data: allAlarms } = getState().alarms;
    if (loading !== true || requestId !== currentRequestId) {
      return;
    }

    let alarms = cloneDeep(allAlarms);

    const orgId = get(message, 'org_id');
    const alarmId = get(message, 'alarm_id');
    try {
      dispatch(showLoading());
      const updatedAlarm = await new WebAPIClient().GET(
        `/resource/alarms/${orgId}/${alarmId}`
      );
      remove(alarms, { alarm_id: alarmId });
      alarms = concat(alarms, updatedAlarm);
      return { data: alarms };
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

// TRIGGER DEVICE
const createTriggerDevices = createAsyncThunk(
  'alarms/createTriggerDevices',
  async (devices, { dispatch, getState, requestId }) => {
    const { loading, currentRequestId, triggerDevices } = getState().alarms;

    if (loading !== true || requestId !== currentRequestId) {
      return;
    }

    try {
      dispatch(showLoading());
      let _triggerDevices = cloneDeep(triggerDevices);
      let relationsAdded = await Promise.all(
        map(devices, async (device) => {
          const deviceId =
            device?.device_id || get(device, `${device.type_}_id`);
          return await new WebAPIClient().POST(
            `/resource/trigger_devices/${device.org_id}`,
            {
              deviceId: deviceId,
              triggerId: device.trigger_id,
            }
          );
        })
      );

      toastr.success(`Alarm trigger${devices.length > 1 ? 's' : ''} activated`);

      // update store
      return {
        triggerDevices: concat(_triggerDevices, relationsAdded),
      };
    } catch (err) {
      toastr.error('Error', get(err, 'response.data.reason', err));
    } finally {
      dispatch(hideLoading());
    }
  }
);

const updateTriggerDevices = createAsyncThunk(
  'alarms/updateTriggerDevices',
  async (
    { triggerDevices: updatedTriggerDevices, thresholdValues },
    { dispatch, getState, requestId }
  ) => {
    try {
      const { currentRequestId, loading, triggerDevices } = getState().alarms;
      if (loading !== true || requestId !== currentRequestId) {
        return;
      }

      let _triggerDevices = cloneDeep(triggerDevices);
      dispatch(showLoading());
      const newTriggerDevices = await Promise.all(
        map(updatedTriggerDevices, async (triggerDevice) => {
          return await new WebAPIClient().PUT(
            `/resource/trigger_devices/${triggerDevice.org_id}/${triggerDevice.trigger_device_id}`,
            { threshold_values: thresholdValues }
          );
        })
      );

      const newTriggerDevicesIds = map(newTriggerDevices, 'trigger_device_id');
      remove(_triggerDevices, (triggerDevice) =>
        includes(newTriggerDevicesIds, triggerDevice.trigger_device_id)
      );
      toastr.success(
        `Alarm${updatedTriggerDevices.length > 1 ? 's' : ''} updated`
      );
      _triggerDevices = concat(_triggerDevices, newTriggerDevices);

      return { triggerDevices: _triggerDevices };
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

const deleteTriggerDevices = createAsyncThunk(
  'alarms/deleteTriggerDevices',
  async (relations, { dispatch, getState, requestId }) => {
    const { loading, currentRequestId, triggerDevices } = getState().alarms;
    if (loading !== true || requestId !== currentRequestId) {
      return;
    }
    try {
      dispatch(showLoading());
      let _triggerDevices = cloneDeep(triggerDevices);

      let relationsRemoved = await Promise.all(
        map(
          relations,
          async (relation) =>
            await new WebAPIClient().DELETE(
              `/resource/trigger_devices/${relation.org_id}/${relation.trigger_device_id}`
            )
        )
      );

      toastr.success(
        `Alarm trigger${relations.length > 1 ? 's' : ''} deactivated`
      );

      // update store
      remove(_triggerDevices, (relation) =>
        includes(relationsRemoved, relation.trigger_device_id)
      );

      return {
        triggerDevices: _triggerDevices,
      };
    } catch (err) {
      toastr.error('Error', get(err, 'response.data.reason', err));
    } finally {
      dispatch(hideLoading());
    }
  }
);

export {
  getAlarms,
  getAllAlarms,
  acknowledgeAlarm,
  closeAlarm,
  deleteAlarm,
  handleInsertAlarm,
  handleModifyAlarm,
  createTriggerDevices,
  deleteTriggerDevices,
  updateTriggerDevices,
};
