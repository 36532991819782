import React from 'react';
import PropTypes from 'prop-types';

import styled from '@mui/material/styles/styled';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import WidgetLoader from './Loader';

const BottomContent = styled('div')(({ theme }) => ({
  display: 'flex',
  marginTop: '20px',
  justifyContent: 'space-between',
}));

const BottomPanel = styled('div')(({ theme }) => ({
  height: 10,
  width: '100%',
  bottom: 0,
  backgroundColor: theme.palette.veregy_colors.grey,
}));

function ResourceCount(props) {
  const { children, loading } = props;

  return (
    <Card raised>
      <CardContent sx={{ p: 1, mt: 3 }}>
        <Stack direction='row'>{children}</Stack>
        <BottomContent>
          <Typography align='left' variant='h5'>
            Resources
          </Typography>
          {loading ? <WidgetLoader /> : null}
        </BottomContent>
      </CardContent>
      <BottomPanel />
    </Card>
  );
}

ResourceCount.propTypes = {
  children: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default ResourceCount;
