import React from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import dayjs from 'dayjs';
import lastIndexOf from 'lodash/lastIndexOf';
import find from 'lodash/find';

import { displaykW } from '../../../../helpers/display-energy';
import BaseTooltip from '../../tooltips/BaseTooltip';

function Tooltip({ active, payload, label, timezone, sensors, meters }) {
  const deviceName = (resource) => {
    let deviceId = resource.dataKey;
    if (deviceId.startsWith('sensor')) {
      let attrName = deviceId.substring(lastIndexOf(deviceId, ':') + 1);
      deviceId = deviceId.substring(0, lastIndexOf(deviceId, ':'));
      let sensor = find(sensors, { sensor_id: deviceId });
      return sensor?.name + `: ${attrName}`;
    } else {
      let meter = find(meters, { meter_id: deviceId });
      return meter?.name;
    }
  };

  const deviceValue = (resource) => {
    let deviceId = resource.dataKey;
    if (deviceId.startsWith('sensor')) {
      let unit = '';
      let attrName = deviceId.substring(lastIndexOf(deviceId, ':') + 1);
      switch (attrName) {
        case 'Radiation':
          unit = 'W/m^2';
          break;

        case 'PanelTemp':
          unit = '°C';
          break;

        default:
          break;
      }

      return `${numeral(resource.value.toPrecision(2)).format(
        '0,0.[000]'
      )} ${unit}`;
    } else {
      return displaykW(resource.value, false, false, true);
    }
  };

  return (
    <BaseTooltip
      active={active}
      payload={payload}
      label={dayjs
        .unix(Number(label))
        .tz(timezone.zone)
        .format('MMM Do h:mm A')}
      getName={deviceName}
      getValue={deviceValue}
    />
  );
}

Tooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  label: PropTypes.number,
  timezone: PropTypes.object,
  site: PropTypes.object,
};

export default Tooltip;
