import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';

function VeregyIcon(props) {
  const { name, height = 80, sx = {} } = props;

  const [imageSrc, setImageSrc] = useState(null);
  useEffect(() => {
    import(`./images/${name}.png`).then((module) => {
      setImageSrc(module.default);
    });
  }, [name]);

  return (
    <Box
      component='img'
      sx={{ height, ...sx }}
      alt={name}
      src={imageSrc}
      title={name}
    />
  );
}

VeregyIcon.propTypes = {
  name: PropTypes.string.isRequired,
  height: PropTypes.number,
  sx: PropTypes.object,
};

export default VeregyIcon;
