import { createAsyncThunk } from '@reduxjs/toolkit';
import { push } from 'redux-first-history';
import isString from 'lodash/isString';
import dayjs from 'dayjs';

import { setPage } from '.';
import { handleFailure, handleSuccess } from '../loggers';
import {
  handleInsertAlarm,
  handleModifyAlarm,
  handleRemoveAlarm,
} from '../alarms';

const navigate = createAsyncThunk('pages/navigate', async (state, action) => {
  const { dispatch } = action;

  if (state.page === 'org') state.page = 'organization';
  let response = await dispatch(
    setPage({
      page: state.page,
      id: state.id,
      tab: isString(state.tab) ? state.tab : '#dashboard',
      view: isString(state.view) ? state.view : '',
    })
  );
  const pages = response.payload;
  dispatch(push(`/app/${state.page}`));
  return pages;
});

const handleWebsocketMessage = createAsyncThunk(
  'pages/handleWebsocketMessage',
  async (message, { dispatch, getState }) => {
    const { websocket } = getState().pages;
    const type = message.type;
    const action = message.action;

    if (type === 'logger') {
      if (action === 'success') {
        dispatch(handleSuccess(message));
      } else if (action === 'failure') {
        dispatch(handleFailure(message));
      }
    } else if (type === 'alarm') {
      if (action === 'INSERT') {
        dispatch(handleInsertAlarm(message));
      } else if (action === 'MODIFY') {
        dispatch(handleModifyAlarm(message));
      } else if (action === 'REMOVE') {
        dispatch(handleRemoveAlarm(message));
      }
    }
    return {
      websocket: {
        ...websocket,
        lastUpdate: dayjs(),
      },
    };
  }
);

export { navigate, handleWebsocketMessage };
