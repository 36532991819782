import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

import ROLES from '../../../constants/roles';
import { closeDialog } from '../../../store/dialogs';
import useVerifyOrgRole from '../../../store/hooks/useVerifyOrgRole';
import { putMeter } from '../../../store/meters/_meters';
import useMeter, { defaultState } from '../../../store/hooks/useMeter';
import BaseDialog from '../BaseDialog';
import { TextFieldListItem } from '../../List/TextFieldListItem';
import ClipboardField from '../../ClipboardField';

function MeterDialog() {
  const dispatch = useDispatch();
  const { id } = useSelector((state) => state.dialogs.meter);
  const meterItem = useMeter(id);
  const isOrgEditor = useVerifyOrgRole(meterItem?.org_id, ROLES.EDITOR.value);

  const [meter, setMeter] = useState(defaultState);

  useEffect(() => {
    setMeter(meterItem);
  }, [meterItem]);

  const handleChange = (e) => {
    setMeter((prevMeter) => ({
      ...prevMeter,
      [e.target.id]: e.target.value,
    }));
  };

  const handleClose = () => {
    dispatch(closeDialog('meter'));
    setMeter(defaultState);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      putMeter({
        org_id: meter.org_id,
        meter_id: meter.meter_id,
        name: meter.name,
      })
    );
    handleClose();
  };

  return (
    <BaseDialog
      id={id}
      title='Update Meter'
      disabled={!isOrgEditor}
      handleSubmit={handleSubmit}
      handleClose={handleClose}>
      <form
        onSubmit={handleSubmit}
        onKeyDown={(event) => {
          if (event?.key === 'Enter') {
            handleSubmit(event);
          }
        }}>
        <List disablePadding>
          <ListItem disableGutters>
            <ClipboardField
              id='meter-id-value'
              value={meter.meter_id}
              label='Meter ID'
            />
          </ListItem>
          <TextFieldListItem
            id='name'
            label={`Meter ${meter.parent_index} Name`}
            value={meter.name}
            onChange={handleChange}
          />
        </List>
      </form>
    </BaseDialog>
  );
}

export default MeterDialog;
