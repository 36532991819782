import { createSlice } from '@reduxjs/toolkit';

import { buildAsyncReducers } from '../thunkTemplate';
import { generators as initialState } from '../initialState';
import { getGenerators } from './_generators';

const { reducer } = createSlice({
  name: 'generators',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    buildAsyncReducers(builder, [getGenerators]);
  },
});

export { getGenerators };
export default reducer;
