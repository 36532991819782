import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';

import Link from '@mui/material/Link';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { navigate } from '../../../store/pages';

function SelectInverter(props) {
  const { inverters } = props;
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleSelect = (e) => {
    e.preventDefault();
    let inverterId = e.target.getAttribute('value');
    dispatch(
      navigate({
        page: 'inverter',
        id: inverterId,
      })
    );
    handleClose();
  };

  const handleOpen = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      <Link
        underline='hover'
        variant='h6'
        mb={1}
        ml={2}
        align='center'
        onClick={handleOpen}>
        Inverters
      </Link>
      <Menu
        id={'popover'}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{ disablePadding: true }}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}>
        {map(sortBy(inverters, 'parent_index'), (inverter) => {
          return (
            <MenuItem
              key={inverter.inverter_id}
              onClick={handleSelect}
              value={inverter.inverter_id}>
              {inverter.name}
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
}

SelectInverter.propTypes = {
  inverters: PropTypes.array.isRequired,
};

export default SelectInverter;
