const ROLES = {
  ADMIN: {
    icon: 'user-crown',
    value: 0,
  },
  EDITOR: {
    icon: 'edit',
    value: 1,
  },
  VIEWER: {
    icon: 'eye',
    value: 2,
  },
};

function findRole(value) {
  for (const role in ROLES) {
    if (ROLES[role].value === Number(value)) {
      return role;
    }
  }
  return null;
}

export { findRole };

export default ROLES;
