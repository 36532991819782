import find from 'lodash/find';
import { useSelector } from 'react-redux';

const useCompareOrgName = () => {
  const organizations = useSelector((state) => state.organizations.data);
  return (organizationId1, organizationId2) => {
    const organization1 = find(organizations, { org_id: organizationId1 });
    const organization2 = find(organizations, { org_id: organizationId2 });
    return organization1?.name.toLowerCase() < organization2?.name.toLowerCase()
      ? -1
      : 1;
  };
};

export default useCompareOrgName;
