import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import find from 'lodash/find';

const defaultState = {
  created_on: '',
  device_id: '',
  level: 0,
  message: '',
  status: null,
  trigger_id: '',
};

const useAlarm = (alarmId) => {
  const { data: alarms } = useSelector((state) => state.alarms);

  const [alarm, setAlarm] = useState(defaultState);

  useEffect(() => {
    if (alarmId && alarmId.startsWith('alarm') && alarms.length) {
      setAlarm(find(alarms, { alarm_id: alarmId }));
    } else setAlarm(defaultState);
  }, [alarms, alarmId]);

  return alarm;
};

export default useAlarm;
