import get from 'lodash/get';

const CPS = {
  PFault: {
    32768: '15V of control board low fault',
    16384: 'Inverter open-loop self-test fault',
    8192: 'Internal hardware fault',
    4096: 'Power module fault',
    256: 'hardware over-current fault',
    64: 'DCI fault',
    16: 'Static GFCI',
    8: 'Grid relay fault',
    4: 'Bus imbalance fault',
    2: 'Bus(sum) low voltage',
    1: 'Bus(sum) over voltage fault',
  },
  WarningAlarm: {
    512: 'LCD Eeprom fault',
    32: 'Temperature sensor fault',
    8: 'DSP Eeprom fault',
    4: 'Internal communication failed',
    2: 'Internal fan error',
    1: 'External fan error',
  },
  FaultAlarm0: {
    32768: 'Sampling offset of output current error',
    16384: 'Over-temperature protection',
    8192: 'Grid relay error',
    4096: 'Loss of main',
    2048: 'Grid under frequency',
    1024: 'Grid Over frequency',
    512: 'Inverter over current',
    256: 'Grid phase voltage out of range',
    128: 'Grid line voltage out of range',
    64: 'PV1 over current',
    16: 'Inverter soft start timeout',
    8: 'Bus soft start timeout',
    4: 'Bus imbalance',
    2: 'Bus(sum) low voltage',
    1: 'Bus(sum) over voltage (firmware)',
  },
  FaultAlarm1: {
    32768: 'Leakage current sensor error',
    4096: 'Power module protection',
    2048: 'Inverter current imbalance',
    512: 'Grid voltage imbalance',
    256: 'Inverter hardware over current',
    128: 'MCU protection',
    32: 'Frequency detection fault',
    16: 'Dynamic leakage current high',
    8: 'Insulation resistance low',
    4: 'DCI high',
    2: 'DCI offset error',
    1: 'Inverter voltage offset error',
  },
  FaultAlarm2: {
    1024: 'PV1 over voltage',
    512: 'PV1 input reverse connection',
    128: 'Inverter open-loop self-test error',
    64: 'PV abnormal input',
    32: 'PV2 over voltage',
    16: 'PV2 over current',
    8: 'PV2 input reverse connection',
    4: 'Input and output power mismatch',
    2: 'Internal hardware error',
  },
  FaultAlarm3: { 32768: 'ARC Protection' },
  FaultAlarm4: {
    8: 'PV Module Configuration Error',
    4: 'Arc board failure',
    2: 'Static GFCI high',
    1: '15V of control board low',
  },
};

const CSI = {
  FaultAlarm0: {
    1: 'Grid Over Voltage',
    2: 'Grid Under Voltage',
    4: 'Grid Over Frequency',
    8: 'Grid Under Frequency',
    16: 'Grid Reverse Current',
    32: 'No Grid',
    64: 'Grid Unbalance',
    128: 'Grid Frequency Protection',
    256: 'Grid Over Current',
    512: 'Grid Current Tracking Fault',
    1024: 'Grid Abnormal Phase Angle',
  },
  FaultAlarm1: {
    1: 'DC Over Voltage',
    2: 'DC Bus Over Voltage',
    4: 'DC Bus Unbalance',
    8: 'DC Bus Under Voltage',
    16: 'DC Bus Unbalance 2',
    32: 'DC (Channel A) Over Current',
    64: 'DC (Channel B) Over Current',
    128: 'DC Interference',
    256: 'DC Wrong Polarity',
    512: 'PV Mid-Point Grounding',
    1024: 'Bus Voltage Not the Same',
  },
  FaultAlarm2: {
    1: 'Grid Interference Protection',
    2: 'DSP Initial Protection',
    4: 'Temperature Protection',
    8: 'Ground Fault',
    16: 'Leakage Current Protection',
    32: 'Relay Protection',
    64: 'DSP_B Protection',
    128: 'DC Injection Protection',
    256: '12V Under Voltage Faulty',
    512: 'Leakage Current Check Protection',
    1024: 'Under Temperature Protection',
  },
  FaultAlarm3: {
    1: 'AFCI Check Fault',
    2: 'AFCI Fault',
    32: 'DSP Self-Check Alarm',
    64: 'Grid INTF 02',
    128: 'Grid Current Sampling Error',
    256: 'IGBT Over Current',
  },
};

export const getFaultMessage = (faultState, value, manufacturer) => {
  const genericMessage = `Active: ${value}`;
  if (value === 0) {
    return '-';
  }

  if (manufacturer === 'Canadian Solar Inc') {
    return get(CSI, `${faultState}.${value}`, genericMessage);
  }

  return get(CPS, `${faultState}.${value}`, genericMessage);
};
