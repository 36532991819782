import React from 'react';
import PropTypes from 'prop-types';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

function SelectOptionalContent(props) {
  const { selectedContents, setSelectedContents, contentOptions, disabled } =
    props;

  const handleSelect = (e) => {
    e.preventDefault();
    setSelectedContents(e.target.value);
  };

  return (
    <FormControl
      variant='standard'
      sx={{
        '& .MuiInputBase-input.Mui-disabled': {
          WebkitTextFillColor: 'rgba(0, 0, 0, 0.87)',
        },
        width: '100%',
      }}>
      <InputLabel id='optional-content-label'>Optional Content</InputLabel>
      <Select
        fullWidth
        displayEmpty
        multiple
        disableUnderline={disabled}
        disabled={disabled}
        id='content-select'
        value={selectedContents}
        variant='standard'
        onChange={handleSelect}
        IconComponent={() => null}
        MenuProps={{ MenuListProps: { disablePadding: true } }}>
        {contentOptions?.map((content) => {
          return (
            <MenuItem key={content} value={content}>
              {content}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}

SelectOptionalContent.propTypes = {
  selectedContents: PropTypes.array,
  setSelectedContents: PropTypes.func,
  contents: PropTypes.array,
};

export default SelectOptionalContent;
