import React from 'react';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { useDispatch, useSelector } from 'react-redux';
import find from 'lodash/find';
import startCase from 'lodash/startCase';

import Link from '@mui/material/Link';

import { openDialog } from '../../../store/dialogs';

export const TriggerTypeProvider = (props) => {
  const { triggers } = useSelector((state) => state.alarms);

  const TriggerLink = (props) => {
    const { id } = props;
    const dispatch = useDispatch();
    const trigger = find(triggers, { trigger_id: id });

    const handleClick = () => {
      dispatch(
        openDialog({
          type: 'trigger',
          id: trigger.trigger_id,
        })
      );
    };

    return (
      <Link underline='hover' onClick={handleClick}>
        {startCase(trigger?.trigger_id)}
      </Link>
    );
  };

  return (
    <DataTypeProvider
      formatterComponent={({ value }) => <TriggerLink id={value} />}
      {...props}
    />
  );
};
