import React from 'react';
import { useSelector } from 'react-redux';

import { useTheme } from '@emotion/react';
import styled from '@mui/material/styles/styled';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import DrawerContent from './DrawerContent';

const MobileDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => !['drawerWidth'].includes(prop),
})(({ drawerWidth }) => {
  const style = {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  };
  return {
    ...style,
    '& .MuiDrawer-paper': { ...style },
  };
});

const drawerMixin = (theme, expanded) => ({
  width: expanded ? theme.drawer.width.expanded : theme.drawer.width.collapsed,
  overflowX: 'hidden',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: expanded
      ? theme.transitions.duration.enteringScreen
      : theme.transitions.duration.leavingScreen,
  }),
});

const StyledDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => !['expanded'].includes(prop),
})(({ theme, expanded }) => ({
  flexShrink: 0,
  whiteSpace: 'nowrap',
  ...drawerMixin(theme, expanded),
  '& .MuiDrawer-paper': {
    ...drawerMixin(theme, expanded),
    scrollbarWidth: 'thin',
  },
}));

function CustomDrawer(props) {
  const { mobileOpen, setMobileOpen } = props;
  const theme = useTheme();
  const { drawerExpanded } = useSelector((state) => state.app);

  return (
    <nav aria-label='drawer'>
      <Box sx={{ display: { xs: 'block', md: 'none' } }}>
        <MobileDrawer
          drawerWidth={theme.drawer.width.expanded}
          variant='temporary'
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={mobileOpen}
          onClose={() => setMobileOpen((currentOpen) => !currentOpen)}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}>
          <DrawerContent />
        </MobileDrawer>
      </Box>
      <Box sx={{ display: { xs: 'none', md: 'block' } }}>
        <StyledDrawer variant='permanent' expanded={drawerExpanded}>
          <DrawerContent />
        </StyledDrawer>
      </Box>
    </nav>
  );
}

export default CustomDrawer;
