import React from 'react';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import VeregyIcon from './VeregyIcon';

export default function HowItWorks() {
  return (
    <Card
      raised
      sx={(theme) => ({
        backgroundColor: theme.palette.auth.background,
        display: 'flex',
        flexDirection: 'row',
      })}>
      <VeregyIcon
        name='Instructions'
        sx={{ ml: 2, mr: 2, alignSelf: 'center' }}
      />
      <CardContent>
        <Typography
          variant='h5'
          align='left'
          color={(theme) => theme.palette.auth.text}
          sx={{ pb: '8px' }}
          fontWeight='bold'>
          How It Works
        </Typography>
        <Typography
          variant='body1'
          align='left'
          color={(theme) => theme.palette.auth.text}>
          LightLevel harnesses advanced technology to monitor every aspect of
          your solar array's performance. Our intuitive interface gathers data
          from weather patterns, equipment diagnostics, and production metrics
          to provide you with comprehensive insights into your solar assets.
        </Typography>
      </CardContent>
    </Card>
  );
}
