import React from 'react';
import { useSelector } from 'react-redux';

import Grid from '@mui/material/Grid';

import useInverter from '../../../../store/hooks/useInverter';
import useMeter from '../../../../store/hooks/useMeter';
import useSite from '../../../../store/hooks/useSite';
import InverterChart from '../../../../components/charts/analysis/InverterChart';

export default function Analysis() {
  const { id } = useSelector((state) => state.pages.inverter);
  const inverter = useInverter(id);
  const meter = useMeter(inverter.meter_id);
  const site = useSite(meter.site_id);

  return (
    <Grid
      container
      spacing={1}
      direction='row'
      justifyContent='center'
      alignItems='center'
      sx={{ mt: '4px', px: 2 }}>
      <InverterChart site={site} inverter={inverter} />
    </Grid>
  );
}
