const PORTFOLIO = {
  name: 'portfolio',
  icon: 'folder',
};
const ORGANIZATION = {
  name: 'organization',
  icon: 'buildings',
};
const SITE = {
  name: 'site',
  icon: 'building',
};
const LOGGER = {
  name: 'logger',
  icon: 'broadcast-tower',
};
const METER = {
  name: 'meter',
  icon: 'bolt',
};
const SENSOR = {
  name: 'sensor',
  icon: 'thermometer-half',
};
const INVERTER = {
  name: 'inverter',
  icon: 'exchange',
};

const RESOURCE_TYPES = [
  PORTFOLIO,
  ORGANIZATION,
  SITE,
  LOGGER,
  METER,
  SENSOR,
  INVERTER,
];

export { PORTFOLIO, ORGANIZATION, SITE, LOGGER, METER, SENSOR, INVERTER };
export default RESOURCE_TYPES;
