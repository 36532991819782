import React from 'react';

import styled from '@mui/material/styles/styled';
import MuiTooltip, { tooltipClasses } from '@mui/material/Tooltip';

const Tooltip = styled(({ className, ...props }) => (
  <MuiTooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    padding: 0,
  },
}));

export default Tooltip;
