import React from 'react';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';

export const ALARM_LEVELS = {
  3: 'critical',
  2: 'urgent',
  1: 'warning',
  0: 'info',
};

export const AlarmLevelTypeProvider = (props) => (
  <DataTypeProvider
    formatterComponent={({ value }) => {
      return (
        <Tooltip title={ALARM_LEVELS[value]} placement='top'>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'left',
              alignItems: 'center',
            }}>
            <FontAwesomeIcon
              icon={['fal', `signal-${value + 1}`]}
              style={{ height: 25, width: 25 }}
            />
          </Box>
        </Tooltip>
      );
    }}
    {...props}
  />
);
