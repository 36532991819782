import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import find from 'lodash/find';

const defaultState = {
  org_id: '',
  site_id: '',
  name: '',
  installer: '',
  install_date: '',
  startup_date: '',
  address: '',
  location: { lat: '', lng: '' },
  timezone: {
    name: '',
    zone: '',
    offset: '',
  },
  energy_rate: null,
};

const useSite = (siteId) => {
  const sites = useSelector((state) => state.sites.data);
  const [site, setSite] = useState({});

  useEffect(() => {
    if (siteId && siteId.startsWith('site') && sites.length) {
      setSite(find(sites, { site_id: siteId }));
    } else setSite(defaultState);
  }, [sites, siteId]);

  return site;
};

export { defaultState };
export default useSite;
