import React from 'react';
import { useDispatch } from 'react-redux';
import { hideLoading, showLoading } from 'react-redux-loading-bar';

import Link from '@mui/material/Link';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import WebAPIClient from '../../../api';

export const ReportFileDownloadTypeProvider = (props) => {
  const dispatch = useDispatch();
  const fileDownload = async (row) => {
    dispatch(showLoading());
    const presignedUrl = await new WebAPIClient().GET(
      `/resource/report_files/${row.resourceId}`,
      {
        period: row.period,
        filename: row.filename,
      }
    );
    window.open(presignedUrl);
    dispatch(hideLoading());
  };

  return (
    <DataTypeProvider
      formatterComponent={({ value, row }) => {
        return (
          <Link
            underline='hover'
            component='button'
            onClick={() => fileDownload(row)}>
            {value}
          </Link>
        );
      }}
      {...props}
    />
  );
};
