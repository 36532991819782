import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import sortBy from 'lodash/sortBy';

import { buildAsyncReducers } from '../thunkTemplate';
import { licenses as initialState } from '../initialState';
import WebAPIClient, { errorResponseToastr } from '../../api';

const getLicenses = createAsyncThunk(
  'licenses/getLicenses',
  async (_, { getState, requestId, dispatch }) => {
    try {
      const { currentRequestId, loading } = getState().licenses;

      if (loading !== true || requestId !== currentRequestId) {
        return;
      }

      dispatch(showLoading());
      let _licenses = await new WebAPIClient().GET('/resource/licenses');
      _licenses = sortBy(_licenses, ['org_id', 'name']);
      return { data: _licenses };
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

// NOTE: "Mutating" state is safe in redux toolkit because it uses Immer
const { reducer } = createSlice({
  name: 'licenses',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    buildAsyncReducers(builder, [getLicenses]);
  },
});

export { getLicenses };
export default reducer;
