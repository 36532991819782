import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import ROLES from '../../../constants/roles';
import useVerifyOrgRole from '../../../store/hooks/useVerifyOrgRole';
import { closeDialog } from '../../../store/dialogs';
import { putSite } from '../../../store/sites';
import useSite, { defaultState } from '../../../store/hooks/useSite';
import BaseDialog from '../BaseDialog';
import { TextFieldListItem } from '../../List/TextFieldListItem';
import AddressTimezoneListItems from '../../Forms/AddressTimezoneListItems';
import dayjs from 'dayjs';
import ClipboardField from '../../ClipboardField';

function SiteDialog() {
  const dispatch = useDispatch();
  const { id } = useSelector((state) => state.dialogs.site);
  const siteItem = useSite(id);

  const [site, setSite] = useState(defaultState);
  const isOrgEditor = useVerifyOrgRole(siteItem?.org_id, ROLES.EDITOR.value);

  useEffect(() => {
    setSite(siteItem);
  }, [siteItem]);

  const handleClose = () => {
    dispatch(closeDialog('site'));
    setSite(defaultState);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(putSite(site));
    handleClose();
  };

  const handleChange = (e) => {
    setSite((prevSite) => ({ ...prevSite, [e.target.id]: e.target.value }));
  };

  return (
    <BaseDialog
      id={id}
      title='Update Site'
      disabled={!isOrgEditor}
      handleSubmit={handleSubmit}
      handleClose={handleClose}>
      <form
        onSubmit={handleSubmit}
        onKeyDown={(event) => {
          if (event?.key === 'Enter') {
            handleSubmit(event);
          }
        }}>
        <List disablePadding>
          <ListItem disableGutters>
            <ClipboardField
              id='site-id-value'
              value={site.site_id}
              label='Site ID'
            />
          </ListItem>
          <TextFieldListItem
            id='name'
            label='Site Name'
            value={site.name}
            onChange={handleChange}
          />
          <AddressTimezoneListItems resource={site} setResource={setSite} />
          <TextFieldListItem
            id='installer'
            label='Installer'
            value={site.installer}
            onChange={handleChange}
          />
          <ListItem disableGutters>
            <DatePicker
              label='Install Date'
              value={dayjs(site.install_date)}
              onChange={(value) => {
                handleChange({ target: { id: 'install_date', value } });
              }}
              slotProps={{
                textField: {
                  fullWidth: true,
                  variant: 'standard',
                  inputProps: { readOnly: true },
                },
              }}
            />
          </ListItem>
          <ListItem disableGutters>
            <DatePicker
              label='Startup Date'
              value={dayjs(site.startup_date)}
              onChange={(value) => {
                handleChange({ target: { id: 'startup_date', value } });
              }}
              slotProps={{
                textField: {
                  fullWidth: true,
                  variant: 'standard',
                  inputProps: { readOnly: true },
                },
              }}
            />
          </ListItem>
        </List>
      </form>
    </BaseDialog>
  );
}

export default SiteDialog;
