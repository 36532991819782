import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import find from 'lodash/find';
import isUndefined from 'lodash/isUndefined';

import Grid from '@mui/material/Grid';

import { roundNumber } from '../../../../helpers';
import useSensor from '../../../../store/hooks/useSensor';
import RegisterDataGrid from '../../../../components/RegisterDataGrid';
import ValuesList from '../../../../components/RegisterDataGrid/ValuesList';

export const REGISTER_OBJECTS = {
  analogValue: {
    401: { objectName: 'DevType' },
    402: { objectName: 'DataModel' },
    403: { objectName: 'Radiation' },
    404: { objectName: 'PanelTemp' },
    405: { objectName: 'Vsupply' },
    406: { objectName: 'ErrorCode' },
    407: { objectName: 'CommError' },
  },
  multiStateValue: {
    201: { objectName: 'OperMode', stateText: ['Inactive', 'Active'] },
    202: { objectName: 'Status', stateText: ['Inactive', 'Active'] },
  },
};

function LatestData() {
  const { id } = useSelector((state) => state.pages.sensor);
  const sensor = useSensor(id);

  const [data, setData] = useState([]);
  const [analogValues, setAnalogValues] = useState([]);
  const [multiStateValues, setMultiStateValues] = useState([]);

  useEffect(() => {
    let values = Object.keys(REGISTER_OBJECTS.analogValue).map((key) => {
      const valueObj = REGISTER_OBJECTS.analogValue[key];
      const datapoint = find(data, (item) =>
        item.measure_name.startsWith(valueObj.objectName)
      );
      return {
        measure_name: valueObj.objectName,
        value: datapoint?.value ? roundNumber.format(datapoint?.value) : '-',
      };
    });
    setAnalogValues(values);
  }, [data]);

  useEffect(() => {
    let values = Object.keys(REGISTER_OBJECTS.multiStateValue).map((key) => {
      const valueObj = REGISTER_OBJECTS.multiStateValue[key];
      const datapoint = find(data, (item) =>
        item.measure_name.startsWith(valueObj.objectName)
      );
      let value = '-';
      if (!isUndefined(datapoint?.value)) {
        value = valueObj.stateText[datapoint.value]
          ? valueObj.stateText[datapoint.value]
          : datapoint.value;
      }

      return {
        measure_name: valueObj.objectName,
        value,
      };
    });
    setMultiStateValues(values);
  }, [data]);

  return (
    <RegisterDataGrid
      registerObjects={REGISTER_OBJECTS}
      orgId={sensor.org_id}
      deviceId={sensor.sensor_id}
      setData={setData}>
      <Grid item xs={12} md={6}>
        <ValuesList title='Analog Values' values={analogValues} />
      </Grid>
      <Grid item xs={12} md={6}>
        <ValuesList title='Multi State Values' values={multiStateValues} />
      </Grid>
    </RegisterDataGrid>
  );
}

export default LatestData;
