import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import reduce from 'lodash/reduce';
import every from 'lodash/every';

import Card from '@mui/material/Card';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

function SelectAll(props) {
  const {
    label,
    selectLabel,
    deselectLabel,
    attribute,
    items,
    handleSelectAll,
    handleDeselectAll,
  } = props;

  const [checked, setChecked] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    let _checked = false;
    const selectedCount = reduce(
      items,
      (sum, item) => {
        return sum + (item[attribute] ? 1 : 0);
      },
      0
    );

    if (items.length === 0) {
      _checked = false;
    } else if (selectedCount === items.length) {
      _checked = true;
    } else if (selectedCount > 0) {
      _checked = null;
    } else {
      _checked = false;
    }
    setChecked(_checked);
  }, [items, attribute]);

  const handleOpen = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const allSelected = every(items, (item) => item[attribute]);
  const allDeselected = every(items, (item) => !item[attribute]);

  return (
    <Card
      sx={{
        width: '100%',
        maxWidth: 165,
        display: 'flex',
        alignItems: 'center',
        height: '48px',
        px: 1,
      }}>
      <FormControlLabel
        control={
          <Checkbox
            id={attribute}
            checked={checked === true || checked === null}
            indeterminate={checked === null}
            onChange={handleOpen}
          />
        }
        label={label}
      />
      <Menu
        anchorEl={anchorEl}
        id={'popover'}
        open={open}
        onClose={handleClose}
        MenuListProps={{ disablePadding: true }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}>
        {!allSelected && (
          <MenuItem
            onClick={() => {
              handleSelectAll();
              handleClose();
            }}>
            {selectLabel} ({items.length})
          </MenuItem>
        )}
        {!allDeselected && (
          <MenuItem
            onClick={() => {
              handleDeselectAll();
              handleClose();
            }}>
            {deselectLabel}
          </MenuItem>
        )}
      </Menu>
    </Card>
  );
}

SelectAll.propTypes = {
  label: PropTypes.string,
  selectLabel: PropTypes.string,
  deselectLabel: PropTypes.string,
  attribute: PropTypes.string,
  items: PropTypes.array,
  handleSelectAll: PropTypes.func,
  handleDeselectAll: PropTypes.func,
};

export default SelectAll;
