import each from 'lodash/each';
import get from 'lodash/get';

export const generateCsvString = (headers, timeseries) => {
  let csv = '';

  // generate headers
  each(headers, (header) => {
    csv += header + ',';
  });
  csv += '\r\n';

  // generate records
  each(timeseries, (record) => {
    each(headers, (header) => {
      csv += get(record, header) + ',';
    });
    csv += '\r\n';
  });

  return csv;
};
