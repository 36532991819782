import React from 'react';

import Card from '@mui/material/Card';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

const ValuesList = (props) => {
  const { title, values, mt = 0 } = props;
  return (
    <Card raised sx={{ mt: mt }}>
      <List>
        <ListItemText
          primary={title}
          primaryTypographyProps={{ align: 'center', fontWeight: 'bold' }}
        />
        {values.map((item) => {
          return (
            <ListItem key={item.measure_name}>
              <ListItemText secondary={item.measure_name} />
              <ListItemText
                primary={item.value}
                primaryTypographyProps={{ align: 'right' }}
              />
            </ListItem>
          );
        })}
      </List>
    </Card>
  );
};

export default ValuesList;
