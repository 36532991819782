import dayjs from 'dayjs';
import cloneDeep from 'lodash/cloneDeep';
import each from 'lodash/each';
import find from 'lodash/find';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import { default as _range } from 'lodash/range';
import reduce from 'lodash/reduce';
import remove from 'lodash/remove';

import { adjustTimeseriesUnits } from '../../../../helpers/chart-data';
import { generateCsvString } from '../../../../helpers/csv-data';

const sumByType = (chartData, type, unit) =>
  reduce(
    chartData,
    (acc, point) =>
      acc +
      reduce(
        point,
        (acc, val, key) => {
          if (key.startsWith(type)) {
            return acc + val * (unit.endsWith('h') ? 1 : 0.25);
          }
          return acc;
        },
        0
      ),
    0
  );

const calculateTotals = (chartData, unit) => {
  return {
    meter: sumByType(chartData, 'meter', unit),
    inverter: sumByType(chartData, 'inverter', unit),
  };
};

const prepareCsvData = (data, meters, inverters, unit) => {
  let headers = ['timestamp'];
  let chartData = cloneDeep(data);

  let timeseries = map(chartData, (dataPoint) => {
    let values = reduce(
      Object.entries(dataPoint),
      (acc, entry) => {
        const deviceId = entry[0];
        const value = entry[1];
        if (deviceId !== 'timestamp') {
          let column;
          if (deviceId.startsWith('meter:')) {
            let meter = find(meters, { meter_id: deviceId });
            column = `${get(meter, 'name')}-${unit}-production`;
          } else if (deviceId.startsWith('inverter:')) {
            let inverter = find(inverters, { inverter_id: deviceId });
            column = `inverter-${get(
              inverter,
              'parent_index'
            )}-active_power_${unit}`;
          }
          if (!headers.includes(column)) {
            headers.push(column);
          }
          return { ...acc, [column]: value };
        }
        return acc;
      },
      {}
    );

    return {
      ...values,
      timestamp: dayjs(dataPoint.timestamp * 1000).format(),
    };
  });

  remove(timeseries, (datapoint) => {
    return Object.keys(datapoint).length <= 1;
  });

  return generateCsvString(headers, timeseries);
};

const compileChartData = (raw, range, unit, interval) => {
  let intervalSec = interval.minutes * 60;
  let rawData = cloneDeep(raw);
  let timeseries = _range(
    range.start.unix(),
    range.end.unix() + 1,
    intervalSec
  );

  timeseries = map(timeseries, (timestamp) => {
    let pointData = { timestamp };
    each(rawData, (deviceData) => {
      let deviceDataPoints = remove(
        get(deviceData, 'records', []),
        (record) => {
          if (
            timestamp <= record.timestamp &&
            record.timestamp < timestamp + intervalSec
          ) {
            return record;
          }
        }
      );

      if (!isEmpty(deviceDataPoints)) {
        pointData[get(deviceData, 'device_id')] = reduce(
          deviceDataPoints,
          (acc, dataPoint) => {
            return acc + get(dataPoint, 'value', 0);
          },
          0
        );
      }
    });
    return pointData;
  });
  return adjustTimeseriesUnits(timeseries, unit);
};

export { sumByType, calculateTotals, prepareCsvData, compileChartData };
