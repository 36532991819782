import React from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import ROLES from '../../constants/roles';

function SelectRole(props) {
  const { email, role, setRole } = props;

  const handleChange = (event) => {
    setRole(event.target.value);
  };

  return (
    <FormControl fullWidth variant='standard'>
      <InputLabel id='role-label'>Role</InputLabel>
      <Select
        labelId='role-label'
        id='role'
        disabled={!email || email === ''}
        value={role}
        onChange={handleChange}
        MenuProps={{
          MenuListProps: { disablePadding: true },
          slotProps: {
            paper: { sx: { minWidth: 'unset !important' } },
          },
        }}>
        {Object.keys(ROLES).map((name) => {
          const role = ROLES[name];
          return (
            <MenuItem
              key={name}
              id={`${name}-menu-item`}
              value={Number(role.value)}>
              <ListItemIcon>
                <FontAwesomeIcon icon={['fal', role.icon]} />
              </ListItemIcon>
              {name.toLowerCase()}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}

SelectRole.propTypes = {
  email: PropTypes.string.isRequired,
  role: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setRole: PropTypes.func.isRequired,
};

export default SelectRole;
