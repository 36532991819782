import React from 'react';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconButton from '@mui/material/IconButton';

export const ViewButtonTypeProvider = (props) => {
  const { handleClick = () => {} } = props;
  return (
    <DataTypeProvider
      formatterComponent={(props) => {
        const { row } = props;

        return (
          <IconButton
            size='small'
            onClick={() => handleClick(row)}
            disabled={!row?.active}>
            <FontAwesomeIcon
              size='xs'
              style={{ width: 20, height: 20 }}
              icon={['fal', 'list']}
            />
          </IconButton>
        );
      }}
      {...props}
    />
  );
};
