import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import startCase from 'lodash/startCase';

import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { closeDialog } from '../../store/dialogs';
import DialogTitleOptions from './DialogTitleOptions';

function InfoDialog() {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const { title, content } = useSelector((state) => state.dialogs.info);

  const handleClose = () => {
    dispatch(closeDialog('info'));
  };

  return (
    <Dialog open={title !== ''} onClose={handleClose} fullScreen={isMobile}>
      <DialogTitle>
        {startCase(title)}
        <DialogTitleOptions handleClose={handleClose} />
      </DialogTitle>
      <DialogContent sx={isMobile ? {} : { width: '100%', minWidth: 450 }}>
        {content}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
}

export default InfoDialog;
