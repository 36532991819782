import React from 'react';
import { styled } from '@mui/material/styles';

const hexCodeRegex = /^#([0-9A-F]{3}){1,2}$/i;

const validateHexColor = (value) => (hexCodeRegex.test(value) ? value : '');

const Div = styled('div')(({ backgroundColor }) => ({
  backgroundColor,
  width: '1rem',
  height: '1rem',
  borderRadius: '3px',
  border: '1px solid #ccc',
}));

function ColorPreview({ color, ...props }) {
  const backgroundColor = validateHexColor(color) || 'inherit';
  return <Div backgroundColor={backgroundColor} {...props} />;
}

export default ColorPreview;
