import React from 'react';
import { useSelector } from 'react-redux';

import useOrganizationDevices from '../../../../store/hooks/useOrganizationDevices';
import AnalysisPage from '../../Analysis';
import useOrganization from '../../../../store/hooks/useOrganization';

export default function Analysis() {
  const { view, id } = useSelector((state) => state.pages.organization);
  const { organization } = useOrganization(id);
  const { sites, meters, inverters, sensors } = useOrganizationDevices(id);

  return (
    <AnalysisPage
      resource={organization}
      timezone={organization.timezone}
      view={view}
      sites={sites}
      meters={meters}
      inverters={inverters}
      sensors={sensors}
    />
  );
}
