import React from 'react';
import startCase from 'lodash/startCase';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTheme } from '@emotion/react';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import { TextFieldListItem } from '../../List/TextFieldListItem';

function SlideContainer(props) {
  const { slide, idx, updateIndex, updateSlide } = props;
  const theme = useTheme();

  const handleMoveForward = () => {
    updateIndex(slide, idx + 1);
  };

  const handleMoveBack = () => {
    updateIndex(slide, idx - 1);
  };

  const handleChange = (e) => {
    updateSlide(idx, { ...slide, [e.target.id]: e.target.value });
  };

  const handleHideShow = () => {
    updateSlide(idx, { ...slide, hidden: !slide.hidden });
  };

  return (
    <List
      dense
      disablePadding
      sx={{
        border: `1px solid ${theme.palette.skeleton}`,
        borderRadius: 1,
        pl: 1,
        pr: 1,
      }}>
      <ListItem disablePadding disableGutters>
        <ListItemIcon
          sx={{
            width: '10%',
            minWidth: 'auto',
            display: 'flex',
            justifyContent: 'flex-start',
          }}>
          <IconButton
            disabled={idx === 0}
            onClick={handleMoveBack}
            sx={{ height: 30, width: 30 }}>
            <FontAwesomeIcon icon={['fal', 'chevron-left']} />
          </IconButton>
        </ListItemIcon>
        <ListItemText primaryTypographyProps={{ align: 'center' }}>
          {startCase(slide.title)}
        </ListItemText>
        <ListItemIcon
          sx={{
            width: '10%',
            minWidth: 'auto',
            display: 'flex',
            justifyContent: 'flex-end',
          }}>
          <IconButton
            disabled={idx === 4}
            onClick={handleMoveForward}
            sx={{ height: 30, width: 30 }}>
            <FontAwesomeIcon icon={['fal', 'chevron-right']} />
          </IconButton>
        </ListItemIcon>
      </ListItem>
      <TextFieldListItem
        id='interval_seconds'
        label='Interval (seconds)'
        value={slide.interval_seconds}
        onChange={handleChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <FontAwesomeIcon icon={['fal', 'clock']} />
            </InputAdornment>
          ),
        }}
      />
      <ListItem disablePadding disableGutters>
        <ListItemIcon>
          <IconButton onClick={handleHideShow}>
            <FontAwesomeIcon
              icon={['fal', slide.hidden ? 'eye-slash' : 'eye']}
              size='sm'
            />
          </IconButton>
        </ListItemIcon>
        <ListItemText primaryTypographyProps={{ align: 'center' }}>
          {slide.hidden ? 'Hidden' : 'Visible'}
        </ListItemText>
      </ListItem>
    </List>
  );
}

export default SlideContainer;
