import React from 'react';
import { ErrorBoundary as SentryErrorBoundary } from '@sentry/react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from '@mui/material/styles/styled';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardHeader from '@mui/material/CardHeader';
import Icon from '@mui/material/Icon';
import Stack from '@mui/material/Stack';

import { background } from '../routes/Authentication';

const Root = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  background: `url(${background})`,
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
});

function FallBackComponent() {
  return (
    <Root>
      <Card sx={{ maxWidth: 750 }} raised>
        <CardHeader
          avatar={
            <Icon
              fontSize='large'
              sx={(theme) => ({
                color: theme.palette.error.main,
                width: '100%',
                height: '100%',
              })}>
              <FontAwesomeIcon icon={['fal', 'do-not-enter']} size='lg' />
            </Icon>
          }
          title='Whoops! Something went wrong...'
          titleTypographyProps={{ variant: 'h4' }}
        />
        <CardActions
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}>
          <Stack direction='row' spacing={2}>
            <Button
              sx={{ width: 100 }}
              href='/app/portfolio'
              variant='contained'>
              Dashboard
            </Button>
            <Button sx={{ width: 100 }} href='/auth' variant='contained'>
              Log In
            </Button>
          </Stack>
        </CardActions>
      </Card>
    </Root>
  );
}

class ErrorBoundary extends React.Component {
  render() {
    return (
      <SentryErrorBoundary fallback={<FallBackComponent />}>
        {this.props.children}
      </SentryErrorBoundary>
    );
  }
}

export default ErrorBoundary;
