import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import dayjs from 'dayjs';
import concat from 'lodash/concat';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';

import WebAPIClient, { errorResponseToastr } from '../../api';

function RegisterDataGrid(props) {
  const { registerObjects, orgId, deviceId, setData, children } = props;
  const dispatch = useDispatch();

  const [timestamp, setTimestamp] = useState(null);

  const handleFetchLatest = async () => {
    const end = dayjs();
    const start = end.subtract(30, 'minute');

    const analogObjectNames = Object.values(registerObjects.analogValue).map(
      (obj) => obj.objectName
    );
    const binaryObjectNames = Object.values(
      registerObjects?.binaryValue || {}
    ).map((obj) => obj.objectName);
    const multiStateObjectNames = Object.values(
      registerObjects.multiStateValue
    ).map((obj) => obj.objectName);

    const attributes = concat(
      analogObjectNames,
      binaryObjectNames,
      multiStateObjectNames
    );

    dispatch(showLoading());

    try {
      const payload = await new WebAPIClient().GET(
        `/resource/timestream-device/${orgId}/${start.unix() * 1000}/${
          end.unix() * 1000
        }`,
        { attributes: JSON.stringify(attributes), deviceId }
      );
      if (payload.length > 0) {
        setData(payload[payload.length - 1].values);
        setTimestamp(payload[payload.length - 1].timestamp);
      } else {
        setData([]);
        setTimestamp(null);
      }
    } catch (error) {
      errorResponseToastr(error);
    } finally {
      dispatch(hideLoading());
    }
  };

  return (
    <>
      <Grid
        item
        xs={12}
        display='flex'
        justifyContent='flex-start'
        alignItems='center'>
        <Box sx={{ maxWidth: 500, width: '100%', py: 1 }}>
          <Stack direction='row' spacing={2} alignItems='center' sx={{ ml: 2 }}>
            <Tooltip
              title='fetch latest'
              placement='top'
              onClick={handleFetchLatest}>
              <IconButton sx={{ height: 30, width: 30 }}>
                <FontAwesomeIcon size='sm' icon={['fal', 'cloud-download']} />
              </IconButton>
            </Tooltip>
            <Typography
              sx={{ minWidth: 100 }}
              variant='body1'
              color='text.secondary'>
              {timestamp
                ? dayjs(timestamp * 1000).format('MMMM Do HH:mm')
                : 'Fetch latest device data'}
            </Typography>
          </Stack>
        </Box>
      </Grid>
      {children}
    </>
  );
}

export default RegisterDataGrid;
