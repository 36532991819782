import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Provider as ReduxProvider, useSelector } from 'react-redux';
import { HistoryRouter } from 'redux-first-history/rr6';
import ReduxToastr from 'react-redux-toastr';
import get from 'lodash/get';

import createTheme from '@mui/material/styles/createTheme';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { generateTheme } from './styles/muiTheme';
import AppRoutes from './routes';
import { history, store } from './store/createStore';
import Backdrop from './components/Loaders/Backdrop';
import { ReduxLoader } from './components/Loaders/Redux';
import ErrorBoundary from './components/ErrorBoundary';

import './styles/index.css';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';

function App() {
  const user = useSelector((state) => state.user.item);
  const resource = useSelector((state) => state.kiosk.resource);

  const mode = get(user, 'theme_mode', 'light');
  const theme = React.useMemo(() => {
    const primary = get(resource, 'theme.primary');
    const secondary = get(resource, 'theme.secondary');
    return createTheme(generateTheme(mode, primary, secondary));
  }, [mode, resource]);

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ErrorBoundary>
          <HelmetProvider>
            <ReduxLoader />
            <Helmet>
              <title>LightLevel</title>
              <meta
                name='viewport'
                content='width=device-width, initial-scale=1'
              />
            </Helmet>
            <ReduxToastr
              timeOut={10000}
              position='bottom-right'
              transitionIn='fadeIn'
              transitionOut='fadeOut'
              progressBar
              preventDuplicates
              closeOnToastrClick
            />
            <Backdrop />
            <AppRoutes />
          </HelmetProvider>
        </ErrorBoundary>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

function AppContainer() {
  return (
    <React.StrictMode>
      <ReduxProvider store={store}>
        <HistoryRouter history={history}>
          <App />
        </HistoryRouter>
      </ReduxProvider>
    </React.StrictMode>
  );
}

export default AppContainer;
