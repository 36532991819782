import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import find from 'lodash/find';

import { displaykW } from '../../../../helpers/display-energy';
import BaseTooltip from '../../tooltips/BaseTooltip';
import useLogger from '../../../../store/hooks/useLogger';

function Tooltip({
  active,
  payload,
  label,
  timezone,
  loggerId,
  meters,
  inverters,
}) {
  const logger = useLogger(loggerId);

  const resourceName = (resource) => {
    if (resource.dataKey.startsWith('logger')) {
      return logger.name;
    } else if (resource.dataKey.startsWith('meter')) {
      const meter = find(meters, {
        meter_id: resource.dataKey.substring(0, 42),
      });
      return meter.name;
    } else if (resource.dataKey.startsWith('inverter')) {
      const inverter = find(inverters, { inverter_id: resource.dataKey });
      return inverter.name;
    }
  };

  return (
    <BaseTooltip
      active={active}
      payload={payload}
      label={dayjs
        .unix(Number(label))
        .tz(timezone.zone)
        .format('MMM Do h:mm A')}
      getName={resourceName}
      getValue={(resource) => displaykW(resource?.value)}
    />
  );
}

Tooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  label: PropTypes.number,
  timezone: PropTypes.object,
  meter: PropTypes.object,
  inverters: PropTypes.array,
};

export default Tooltip;
