import { createSlice } from '@reduxjs/toolkit';
import { buildAsyncReducers } from '../thunkTemplate';
import { inverters as initialState } from '../initialState';

import { getInverters, putInverter } from './_inverters';

// NOTE: "Mutating" state is safe in redux toolkit because it uses Immer
const { reducer } = createSlice({
  name: 'inverters',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    buildAsyncReducers(builder, [getInverters, putInverter]);
  },
});

// Export the reducer, either as a default or named export
export { getInverters, putInverter };
export default reducer;
