import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import startCase from 'lodash/startCase';

import {
  FilteringState,
  IntegratedPaging,
  PagingState,
  SortingState,
} from '@devexpress/dx-react-grid';
import useTheme from '@mui/material/styles/useTheme';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

import { closeDialog, openDialog } from '../../store/dialogs';
import { getSubscribedUsers } from '../../store/admin';
import useDevice from '../../store/hooks/useDevice';
import useTrigger from '../../store/hooks/useTrigger';
import BaseTable from '../table/tables/BaseTable';
import ComponentLoader from '../Loaders/ComponentLoader';
import { TextFieldListItem } from '../List/TextFieldListItem';
import BaseDialog from './BaseDialog';

const defaultState = { trigger_id: '', device_id: '' };

function SubscribedUsersDialog() {
  const theme = useTheme();
  const dispatch = useDispatch();

  const { triggerDevice } = useSelector((state) => state.dialogs.subscribers);
  const subscribedUsers = useSelector((state) => state.admin.subscribedUsers);

  const device = useDevice(triggerDevice.device_id);
  const trigger = useTrigger(triggerDevice.trigger_id);

  const [loading, setLoading] = useState(false);
  const [relation, setRelation] = useState(defaultState);

  const [columns] = useState([
    { title: 'Name', name: 'name', width: 0.45 },
    { title: 'Email', name: 'email', width: 0.45 },
  ]);

  useEffect(() => {
    setRelation(triggerDevice);
  }, [triggerDevice]);

  useEffect(() => {
    if (relation?.device_id && relation.trigger_id) {
      setLoading(true);
      dispatch(getSubscribedUsers(relation)).finally(() => setLoading(false));
    }
  }, [relation, dispatch]);

  const handleClose = () => {
    dispatch(closeDialog('subscribers'));
  };

  return (
    <BaseDialog
      id={get(relation, 'trigger_id', '')}
      title='Subscribed Users'
      handleClose={handleClose}>
      <List disablePadding>
        <ListItem disableGutters>
          <Link
            underline='hover'
            sx={{ color: theme.palette.veregy_colors.blue }}
            onClick={() => {
              handleClose();
              dispatch(
                openDialog({
                  type: 'trigger',
                  id: trigger.trigger_id || '',
                })
              );
            }}>
            {startCase(get(trigger, 'trigger_id')) + ' Trigger'}
          </Link>
        </ListItem>
        <TextFieldListItem
          label='Device'
          value={device.name || ''}
          InputProps={{
            readOnly: true,
            disableUnderline: true,
            fullWidth: true,
          }}
        />
      </List>
      {loading ? (
        <div
          style={{
            height: '50px',
          }}>
          <ComponentLoader height='35px' width='35px' />
        </div>
      ) : (
        <BaseTable rows={subscribedUsers} columns={columns}>
          <FilteringState />
          <SortingState
            defaultSorting={[{ columnName: 'name', direction: 'asc' }]}
          />
          <PagingState defaultCurrentPage={0} pageSize={10} />
          <IntegratedPaging />
        </BaseTable>
      )}
    </BaseDialog>
  );
}

export default SubscribedUsersDialog;
