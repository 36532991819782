import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import has from 'lodash/has';
import toUpper from 'lodash/toUpper';

import Grid from '@mui/material/Grid';

import ROLES from '../../../../constants/roles';
import useOrganization from '../../../../store/hooks/useOrganization';
import useVerifyOrgRole from '../../../../store/hooks/useVerifyOrgRole';
import ViewStack from '../../../../components/ViewStack';
import Kiosk from '../../../../components/Configuration/Kiosk';
import MembershipsTable from '../../../../components/table/tables/MembershipsTable';
import AlarmsConfigurationTable from '../../../../components/table/tables/AlarmsConfigurationTable';
import LicensesTable from './LicensesTable';

const MEMBERSHIPS = 'memberships';
const ALARMS = 'alarms';
const KIOSK = 'kiosk';
const LICENSES = 'licenses';

export default function Configuration() {
  const [views, setViews] = useState([]);
  const [selectedView, setSelectedView] = useState(MEMBERSHIPS);
  const { id, view } = useSelector((state) => state.pages.organization);
  const { organization } = useOrganization(id);

  const isOrgAdmin = useVerifyOrgRole(id, ROLES.ADMIN.value);
  const isOrgEditor = useVerifyOrgRole(id, ROLES.EDITOR.value);

  useEffect(() => {
    if (isOrgAdmin) {
      setViews([MEMBERSHIPS, ALARMS, LICENSES, KIOSK]);
    } else {
      setViews([MEMBERSHIPS, ALARMS, KIOSK]);
    }
  }, [isOrgAdmin, isOrgEditor]);

  useEffect(() => {
    if (has(views, toUpper(view))) {
      setSelectedView(view);
    } else if (view === '') {
      setSelectedView(MEMBERSHIPS);
    }
  }, [views, view]);

  return (
    <Grid
      container
      direction='row'
      justifyContent='center'
      alignItems='flex-start'
      spacing={1}
      mt='0 !important'
      px={1}
      mb={6}
      sx={{ maxWidth: '100%' }}>
      <Grid item xs={12}>
        <ViewStack
          selectedView={selectedView}
          setSelectedView={setSelectedView}
          views={views}
        />
      </Grid>
      {selectedView === MEMBERSHIPS && (
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            maxWidth: '96vw',
          }}>
          <MembershipsTable orgId={organization?.org_id} />
        </Grid>
      )}
      {selectedView === ALARMS && (
        <AlarmsConfigurationTable
          resource={organization}
          typeSelect={true}
          isOrgEditor={isOrgEditor}
        />
      )}
      {selectedView === KIOSK && (
        <Kiosk resourceId={organization?.org_id} orgId={organization?.org_id} />
      )}
      {selectedView === LICENSES && (
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            maxWidth: '96vw',
          }}>
          <LicensesTable orgId={organization?.org_id} />
        </Grid>
      )}
    </Grid>
  );
}
