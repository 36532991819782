import React from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';

import Grid from '@mui/material/Grid';

import useLoggerDevices from '../../../../store/hooks/useLoggerDevices';
import MeterCard from './MeterCard';
import SensorProfileChart from '../../../../components/charts/preview/SensorProfileChart';
import InvertersProfileCard from './InvertersCard';

function DevicesGrid(props) {
  const { logger } = props;
  const { meters, sensors } = useLoggerDevices(logger?.logger_id);

  if (!logger?.active) return null;
  return (
    <>
      {map(sortBy(meters, 'parent_index'), (meter) => {
        return (
          <Grid key={meter.meter_id} item xs={12}>
            <MeterCard meterId={meter.meter_id} />
          </Grid>
        );
      })}
      <Grid key={logger.logger_id + '-inverters'} item xs={12}>
        <InvertersProfileCard logger={logger} />
      </Grid>
      {map(sortBy(sensors, 'name'), (sensor) => {
        return (
          <Grid key={sensor.sensor_id} item xs={12}>
            <SensorProfileChart sensorId={sensor.sensor_id} />
          </Grid>
        );
      })}
      <Grid item xs={12} sx={{ height: 20 }} />
    </>
  );
}

DevicesGrid.propTypes = {
  site: PropTypes.object,
  logger: PropTypes.object,
};

export default DevicesGrid;
