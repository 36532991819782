import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

import ROLES from '../../../constants/roles';
import useOrganization, {
  defaultState,
} from '../../../store/hooks/useOrganization';
import { closeDialog } from '../../../store/dialogs';
import useVerifyOrgRole from '../../../store/hooks/useVerifyOrgRole';
import { putOrganization } from '../../../store/organizations';
import BaseDialog from '../BaseDialog';
import { TextFieldListItem } from '../../List/TextFieldListItem';
import AddressTimezoneListItems from '../../Forms/AddressTimezoneListItems';
import ClipboardField from '../../ClipboardField';

function OrganizationDialog() {
  const dispatch = useDispatch();

  const [organization, setOrganization] = useState(defaultState);

  const { id } = useSelector((state) => state.dialogs.organization);
  const { organization: organizationItem } = useOrganization(id);
  const isOrgAdmin = useVerifyOrgRole(id, ROLES.ADMIN.value);

  useEffect(() => {
    setOrganization(organizationItem);
  }, [organizationItem]);

  const handleClose = () => {
    dispatch(closeDialog('organization'));
    setOrganization(defaultState);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(putOrganization(organization));
    handleClose();
  };

  const handleChange = (e) => {
    let value = e.target.value;
    setOrganization((prevOrg) => ({ ...prevOrg, [e.target.id]: value }));
  };

  return (
    <BaseDialog
      id={id}
      title='Update Organization'
      disabled={!isOrgAdmin}
      handleSubmit={handleSubmit}
      handleClose={handleClose}>
      <form
        onSubmit={handleSubmit}
        onKeyDown={(event) => {
          if (event?.key === 'Enter') {
            handleSubmit(event);
          }
        }}>
        <List disablePadding>
          <ListItem disableGutters>
            <ClipboardField
              id='org-id-value'
              value={organization.org_id}
              label='Organization ID'
            />
          </ListItem>
          <TextFieldListItem
            id='name'
            label={
              organization?.is_portfolio
                ? 'Portfolio Name'
                : 'Organization Name'
            }
            value={organization.name}
            onChange={handleChange}
          />
          <AddressTimezoneListItems
            resource={organization}
            setResource={setOrganization}
          />
        </List>
      </form>
    </BaseDialog>
  );
}

export default OrganizationDialog;
