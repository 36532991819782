import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import filter from 'lodash/filter';

const useMeterDevices = (meterId) => {
  const allInverters = useSelector((state) => state.inverters.data);
  const allSensors = useSelector((state) => state.sensors.data);

  const [inverters, setInverters] = useState([]);
  const [sensors, setSensors] = useState([]);

  useEffect(() => {
    setInverters(
      filter(allInverters, (inverter) => inverter.meter_id === meterId)
    );
  }, [allInverters, meterId]);

  useEffect(() => {
    setSensors(filter(allSensors, (sensor) => sensor.meter_id === meterId));
  }, [allSensors, meterId]);

  return { inverters, sensors };
};

export default useMeterDevices;
