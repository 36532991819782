import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import concat from 'lodash/concat';
import find from 'lodash/find';
import findKey from 'lodash/findKey';
import get from 'lodash/get';
import lowerCase from 'lodash/lowerCase';
import reduce from 'lodash/reduce';
import size from 'lodash/size';
import startCase from 'lodash/startCase';
import useMediaQuery from '@mui/material/useMediaQuery';

import ROLES from '../../../../constants/roles';
import ROUTES from '../../../../constants/routes';
import DesktopBreadcrumbs from './DesktopBreadcrumbs';
import MobileBreadcrumbs from './MobileBreadcrumbs';

const generateHref = (resource) => {
  switch (get(resource, 'type_')) {
    case 'organization':
      if (get(resource, 'is_portfolio')) {
        return ROUTES.AUTH.PORTFOLIO;
      } else return ROUTES.AUTH.ORGANIZATION;

    case 'site':
      return ROUTES.AUTH.SITE;

    case 'logger':
      return ROUTES.AUTH.LOGGER;

    case 'meter':
      return ROUTES.AUTH.METER;

    case 'inverter':
      return ROUTES.AUTH.INVERTER;

    case 'sensor':
      return ROUTES.AUTH.SENSOR;

    default:
      return null;
  }
};

const generateLink = (resource) => {
  const type = get(resource, 'type_');
  const id = get(resource, type === 'organization' ? 'org_id' : `${type}_id`);
  return {
    name: get(resource, 'name'),
    id,
    type,
    href: generateHref(resource),
  };
};

function Breadcrumbs() {
  const location = useLocation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const pages = useSelector((state) => state.pages);
  const { item: user, memberships } = useSelector((state) => state.user);
  const organizations = useSelector((state) => state.organizations.data);
  const sites = useSelector((state) => state.sites.data);
  const loggers = useSelector((state) => state.loggers.data);
  const meters = useSelector((state) => state.meters.data);
  const inverters = useSelector((state) => state.inverters.data);
  const sensors = useSelector((state) => state.sensors.data);

  const [links, setLinks] = useState([]);
  const [isOrgEditor, setIsOrgEditor] = useState(false);

  useEffect(() => {
    const defaultOrganization = find(organizations, {
      org_id: get(user, 'default_organization'),
    });
    let portfolio = null;
    let organization = null;
    let logger = null;
    let site = null;
    let meter = null;
    let _meter = null;
    let inverter = null;
    let sensor = null;

    switch (location.pathname) {
      case ROUTES.AUTH.PORTFOLIO:
        let portfolio_id = pages.portfolio.id
          ? pages.portfolio.id
          : defaultOrganization?.is_portfolio;
        portfolio = find(organizations, { org_id: portfolio_id });
        break;

      case ROUTES.AUTH.ORGANIZATION:
        organization = find(organizations, {
          org_id: pages.organization.id,
        });
        break;

      case ROUTES.AUTH.SITE:
        site = find(sites, {
          site_id: pages.site.id,
        });
        organization = find(organizations, { org_id: get(site, 'org_id') });
        break;

      case ROUTES.AUTH.LOGGER:
        logger = find(loggers, {
          logger_id: pages.logger.id,
        });
        organization = find(organizations, {
          org_id: get(logger, 'org_id'),
        });
        site = find(sites, { site_id: get(logger, 'site_id') });
        break;

      case ROUTES.AUTH.METER:
        meter = find(meters, {
          meter_id: pages.meter.id,
        });
        organization = find(organizations, {
          org_id: get(meter, 'org_id'),
        });
        site = find(sites, { site_id: get(meter, 'site_id') });
        logger = find(loggers, { logger_id: get(meter, 'logger_id') });
        break;

      case ROUTES.AUTH.INVERTER:
        inverter = find(inverters, {
          inverter_id: pages.inverter.id,
        });
        _meter = find(meters, {
          meter_id: get(inverter, 'meter_id'),
        });
        organization = find(organizations, {
          org_id: get(inverter, 'org_id'),
        });
        logger = find(loggers, {
          logger_id: get(_meter, 'logger_id'),
        });
        site = find(sites, { site_id: get(_meter, 'site_id') });
        break;

      case ROUTES.AUTH.SENSOR:
        sensor = find(sensors, {
          sensor_id: pages.sensor.id,
        });
        _meter = find(meters, {
          meter_id: get(sensor, 'meter_id'),
        });
        organization = find(organizations, {
          org_id: get(sensor, 'org_id'),
        });
        logger = find(loggers, {
          logger_id: get(_meter, 'logger_id'),
        });
        site = find(sites, { site_id: get(_meter, 'site_id') });
        break;

      default:
        break;
    }

    let _links = reduce(
      [portfolio, organization, site, logger, meter, inverter, sensor],
      (acc, resource) => {
        if (resource) return concat(acc, generateLink(resource));
        return acc;
      },
      []
    );

    if (size(_links) === 0) {
      if (location.pathname === ROUTES.AUTH.KPIS) {
        _links = [
          {
            name: 'Key Performance Indicators',
            id: 'kpis-link-key',
          },
        ];
      } else if (location.pathname === ROUTES.AUTH.INDEX) {
        _links = [
          {
            name: '',
            id: 'app-link-key',
          },
        ];
      } else {
        const page = startCase(
          lowerCase(
            findKey(ROUTES.AUTH, (route) => {
              return route === location.pathname;
            })
          )
        );
        _links = [
          {
            name: page,
            id: `${page}-link-key`,
          },
        ];
      }
    }

    setLinks(_links);

    const orgId = portfolio?.org_id || organization?.org_id;
    const membership = find(memberships, {
      org_id: orgId,
      user_id: user?.user_id,
    });
    if (membership?.role <= ROLES.EDITOR.value) {
      setIsOrgEditor(true);
    } else {
      setIsOrgEditor(false);
    }
  }, [
    pages,
    location.pathname,
    organizations,
    sites,
    loggers,
    meters,
    inverters,
    sensors,
    memberships,
    user,
  ]);

  if (isMobile && links.length > 0) {
    return <MobileBreadcrumbs links={links} isOrgEditor={isOrgEditor} />;
  }

  return <DesktopBreadcrumbs links={links} isOrgEditor={isOrgEditor} />;
}

export default Breadcrumbs;
