import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import Badge from '@mui/material/Badge';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import Link from '@mui/material/Link';

import { MaxWidthTooltip } from './MaxWidthTooltip';

const DeviceLink = forwardRef((props, ref) => {
  const { numAlarms, deviceName, handleClick, children, ...restProps } = props;
  return (
    <Badge
      ref={ref}
      {...restProps}
      badgeContent={numAlarms > 0 ? numAlarms : null}
      color='error'
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}>
      {children ? (
        children
      ) : (
        <Link
          underline='hover'
          variant='h6'
          mb={1}
          ml={2}
          align='center'
          onClick={handleClick}>
          {deviceName}
        </Link>
      )}
    </Badge>
  );
});

function AlarmPreviewLink(props) {
  const { alarms, deviceName, handleClick = () => {}, children } = props;
  if (alarms.length > 0) {
    return (
      <MaxWidthTooltip
        placement='right'
        title={
          <List disablePadding>
            {alarms.map((alarm) => (
              <ListItemText
                key={alarm.alarm_id}
                primaryTypographyProps={{ variant: 'caption' }}
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitLineClamp: '1',
                  WebkitBoxOrient: 'vertical',
                  maxWidth: 250,
                }}>
                {alarm.message}
              </ListItemText>
            ))}
          </List>
        }>
        <DeviceLink
          numAlarms={alarms.length}
          deviceName={deviceName}
          handleClick={handleClick}>
          {children}
        </DeviceLink>
      </MaxWidthTooltip>
    );
  }
  return (
    <DeviceLink
      numAlarms={alarms.length}
      deviceName={deviceName}
      handleClick={handleClick}>
      {children}
    </DeviceLink>
  );
}

AlarmPreviewLink.propTypes = {
  alarms: PropTypes.array.isRequired,
  deviceName: PropTypes.string,
  handleClick: PropTypes.func,
};

export default AlarmPreviewLink;
