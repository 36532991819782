import React from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import dayjs from 'dayjs';
import sortBy from 'lodash/sortBy';

import { displaykW } from '../../../../helpers/display-energy';
import BaseTooltip from '../../tooltips/BaseTooltip';

const renderValue = (resource) => {
  if (resource.dataKey === 'radiation') {
    return (
      numeral(resource.value.toPrecision(4)).format('0,0.[000]') + ' W/m^2'
    );
  }
  if (resource.dataKey === 'temp') {
    return numeral(resource.value.toPrecision(4)).format('0,0.[0]') + ' °C';
  }
  return displaykW(resource.value);
};

function Tooltip({ active, payload, label, timezone }) {
  const sortedPayload = sortBy(payload, (resource) => {
    if (resource.name === 'Production (actual)') return 1;
    if (resource.name === 'Production (estimated)') return 2;
    if (resource.name === 'Irradiance') return 3;
    if (resource.name === 'PanelTemp') return 4;
  });

  return (
    <BaseTooltip
      active={active}
      payload={sortedPayload}
      label={dayjs
        .unix(Number(label))
        .tz(timezone.zone)
        .format('MMM Do h:mm A')}
      getName={(resource) => resource?.name}
      getValue={renderValue}
    />
  );
}

Tooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  label: PropTypes.number,
  timezone: PropTypes.object,
  meters: PropTypes.array,
};

export default Tooltip;
