import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import filter from 'lodash/filter';
import find from 'lodash/find';
import get from 'lodash/get';
import map from 'lodash/map';

import Card from '@mui/material/Card';

import {
  EditingState,
  PagingState,
  SortingState,
  IntegratedPaging,
  IntegratedSorting,
} from '@devexpress/dx-react-grid';
import { TableEditColumn } from '@devexpress/dx-react-grid-material-ui';

import { openDialog } from '../../../store/dialogs';
import BaseTable from './BaseTable';
import { CheckBoxTypeProvider, ViewButtonTypeProvider } from '../providers';
import { EditCell } from '../cells';
import ReportFilesDialog from '../../Dialogs/ReportFilesDialog';

function ReportsTable(props) {
  const { resource } = props;
  const dispatch = useDispatch();

  const { data: reports, templates } = useSelector((state) => state.reports);

  const [reportId, setReportId] = useState('');
  const [availableReports, setAvailableReports] = useState([]);
  const [columns] = useState([
    { title: 'Active', name: 'active', width: 0.1 },
    { title: 'Report', name: 'title', width: 0.3 },
    { title: 'Period', name: 'period', width: 0.2 },
    { title: 'Day', name: 'day', width: 0.1 },
    { title: 'View', name: 'report_id', width: 0.1 },
  ]);

  useEffect(() => {
    let _availableReports = map(
      filter(templates, { device_type: resource.type_ }),
      (template) => {
        let _device_id =
          resource.type_ === 'organization'
            ? resource.org_id
            : get(resource, `${resource.type_}_id`);
        let _report = find(reports, {
          name: template.title,
          device_id: _device_id,
        });
        return {
          ...template,
          active: !!_report,
          report_id: _report ? _report.report_id : null,
          period: _report ? _report.period : null,
          day: _report ? _report.day : null,
        };
      }
    );
    setAvailableReports(_availableReports);
  }, [resource, templates, reports]);

  const handleClick = (report) => {
    let template = find(templates, { title: report.title });
    if (report.report_id) {
      dispatch(
        openDialog({
          type: 'report',
          id: report.report_id,
          mode: 'edit',
        })
      );
    } else {
      dispatch(
        openDialog({
          template,
          resource,
          type: 'report',
          mode: 'create',
        })
      );
    }
  };

  const handleViewClick = (report) => {
    setReportId(report.report_id);
  };

  return (
    <Card raised sx={{ maxWidth: '96vw', width: '100%', px: 1, pb: 0.5 }}>
      <BaseTable
        minWidth={700}
        rows={availableReports}
        columns={columns}
        editColumn={
          <TableEditColumn
            width={42}
            showEditCommand
            cellComponent={(props) => (
              <EditCell
                disabled={false}
                tooltipText='Edit Report'
                icon={['fal', 'edit']}
                handleClick={() => handleClick(props.row)}
                {...props}
              />
            )}
          />
        }>
        <CheckBoxTypeProvider for={['active']} handleClick={handleClick} />
        <ViewButtonTypeProvider
          for={['report_id']}
          handleClick={handleViewClick}
        />

        <EditingState />
        <SortingState />
        <PagingState defaultCurrentPage={0} pageSize={10} />

        <IntegratedSorting />
        <IntegratedPaging />
      </BaseTable>
      <ReportFilesDialog reportId={reportId} setReportId={setReportId} />
    </Card>
  );
}

ReportsTable.propTypes = {
  resource: PropTypes.object,
};

export default ReportsTable;
